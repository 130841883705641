/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  Spinner,
  Dropdown,
} from "react-bootstrap";
import SortBy from "../../../components/SortBy";
import { useFormik, Formik } from "formik";
import * as yup from "yup";
import { Toaster, toast } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DashboardHeading from "../../../components/DashboardHeading";
import ChangeView from "../../../components/ChangeView";
import { viewerService } from "../../../_services/viewer";
import { useSelector } from "react-redux";
import { getOwnerAuth } from "../../../store/slices/ownerSlice";
import SkeletonLoader from "../../../components/SkeletonLoader";
import Loader from "../../../components/Loader";
import ViewerTable from "../../../components/ViewerTable";
import { useAuth } from "../../../_hooks/useAuth";
import { ownerSettingsService } from "../../../_services";
const ExecutorLovedPage = () => {
  const [isGridViewActive, setIsGridViewActive] = useState(true);
  const [viewerListData, setViewerListData] = useState([]);
  const [viewerListDataBkp, setViewerListDataBkp] = useState([]);
  const [isLoadingForList, setIsLoadingForList] = useState(false);
  const [removedViewer, setRemovedViewer] = useState();
  const loggedInOwnerData = useAuth("user");
  const [nonImageAlbums, setNonImageAlbums] = useState([]);
  const [updateFileUrl, setUpdateFileUrl] = useState("");
  useEffect(() => {
    getViewerListData();
  }, [removedViewer]);

  const getViewerListData = () => {
    setIsLoadingForList(true);

    if (loggedInOwnerData?.ownerId) {
      viewerService
        .getViewerList(loggedInOwnerData?.ownerId)
        .then((res) => {
          setViewerListData(res?.data?.data);
          setViewerListDataBkp(res?.data?.data);
          setIsLoadingForList(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoadingForList(false);
        });
    }
  };

  const handleEditClick = (viewer) => {};
  // avatar img expiration
  useEffect(() => {
    const checkThumbnailUrl = async (album) => {
      if (album?.avatar) {
        try {
          await loadImage(album.avatar);
          console.log("Thumbnail URL is an image:>", album.avatar);
        } catch (error) {
          console.log("Thumbnail URL is not an image:>", album.avatar);
          if (
            !nonImageAlbums.find(
              (nonImageAlbum) => nonImageAlbum._id === album._id
            )
          ) {
            setNonImageAlbums((prevAlbums) => [...prevAlbums, album]);
          }
        }
      }
    };

    viewerListData.forEach((album) => checkThumbnailUrl(album));
  }, [viewerListData]);

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => reject();
      img.src = url;
    });
  };

  useEffect(() => {
    if (nonImageAlbums?.length > 0) {
      handleUpdateAlbumThumbnailUrls(nonImageAlbums);
    }
  }, [nonImageAlbums]);

  const handleUpdateAlbumThumbnailUrls = (fileDataArray) => {
    const updatePromises = fileDataArray?.map((album) => {
      const ownerAlbumUrlData = {
        role: album?.role,
        fileName: album?.fileName,
      };
      // Return the promise for updating the thumbnail URL

      return ownerSettingsService.updateProfileImageURL(
        album?._id,
        ownerAlbumUrlData
      );
    });

    // Use Promise.all to wait for all update requests to complete
    Promise.all(updatePromises)
      .then((responses) => {
        setUpdateFileUrl(Math.round());
        responses.forEach((response) => {
          if (response?.data?.status === true) {
            console.log("File URL updated successfully");
            getViewerListData();
          }
        });
      })
      .catch((error) => {
        console.log("Network ERROR", error);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pb-3 border-bottom mb-3">
        <DashboardHeading heading="Loved ones" />
        <ChangeView
          isGridViewActive={isGridViewActive}
          setIsGridViewActive={setIsGridViewActive}
        />
      </div>
      <div className="filter-section d-flex justify-content-end align-items-center">
        <SortBy
          album={viewerListDataBkp}
          setAlbums={setViewerListData}
          isViewer={true}
        />
      </div>
      {isLoadingForList ? (
        <>
          <SkeletonLoader />
        </>
      ) : (
        <>
          <ViewerTable
            viewerListData={viewerListData}
            handleEditClick={handleEditClick}
            setIsLoadingForList={setIsLoadingForList}
            setRemovedViewer={setRemovedViewer}
            isGridViewActive={isGridViewActive}
            isExector={true}
          />
        </>
      )}
      <Toaster position="top-right" />
    </>
  );
};

export default ExecutorLovedPage;
