import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useAuth } from "../../_hooks/useAuth";
import { Link } from "react-router-dom";

const EmailVerifed = () => {
  const user = useAuth("user");

  return (
    <div>
      {" "}
      <Container className="py-xl-5 py-2">
        <Row className="justify-content-center text-center mb-5">
          <Col xl={8} lg={8} md={8}>
            <img alt="" className="mb-2" width="150" src="logo.png" />
            <h4>Account Holder Verified</h4>
          </Col>
        </Row>
        <Row className="justify-content-center text-center mt-5">
          <Col xl={8} lg={8} md={8}>
            <h5>
              <i>
                Thank you {user?.userName},your email is been verified
                successfully!
              </i>
            </h5>
            <br />
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <h5>
              <Link to="/owner/dashboard">
                <i>Continue to Dashboard</i>
              </Link>
            </h5>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EmailVerifed;
