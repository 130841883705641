import executerSlice from "./executerSlice";
import folderSlice from "./folderSlice";
import ownerSlice from "./ownerSlice";
import viewerSlice from "./viewerSlice";
import recordingFormSlice from "./recordingFormSlice";
import { combineReducers } from "@reduxjs/toolkit";
import dashboardSlice from "./dashboardSlice";
import payAsYouGoSlice from "./payAsYouGoSlice";
import executorFolderSlice from "./executorFolderSlice";
import viewerFolderSlice from "./viewerFolderSlice";
import planSlice from "./planSlice";

const rootReducer = combineReducers({
  viewerAuth: viewerSlice,
  ownerAuth: ownerSlice,
  folderData: folderSlice,
  executorAuth: executerSlice,
  recordingForm: recordingFormSlice,
  dashboard: dashboardSlice,
  payAsYouGo: payAsYouGoSlice,
  executorFolder: executorFolderSlice,
  viewerFolder: viewerFolderSlice,
plans: planSlice
  
});

export default rootReducer;
