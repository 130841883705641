import React, { useState, useEffect } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { viewerService, executorService, folderService } from "../../_services";
import { useAuth } from "../../_hooks/useAuth";
import Skeleton from "react-loading-skeleton";
import { albumPrivacyService } from "../../_services/albumprivacy";

const AlbumVisibilityDropdown = ({ viewer, folderId, albumsWizard }) => {
  const user = useAuth("user");
  const [albumList, setAlbumsList] = useState([]);
  const [viewerListData, setViewerListData] = useState([]);
  const [executorListData, setExecutorListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingFilteredAlbums, setLoadingFilteredAlbums] = useState(false);

  useEffect(() => {
    if (user?._id) {
      getViewerListData(user._id);
    }
  }, [user]);

  const getViewerListData = (ownerId) => {
    setLoading(true);
    if (user?.isProfileCompleted) {
      Promise.all([
        viewerService.getViewerList(ownerId),
        executorService.getExecutorListByOwner(ownerId),
      ])
        .then(([viewerListRes, executorListRes]) => {
          const verifiedViewerList = viewerListRes?.data?.data.filter(
            (user) => user.status
          );
          setViewerListData(verifiedViewerList);

          const verifiedExecutorList = executorListRes?.data?.data.filter(
            (user) => user.status
          );
          setExecutorListData(verifiedExecutorList);
        })
        .catch((error) => {
          console.error(error);
          toast.error("Failed to fetch viewer and executor data");
        })
        .finally(() => setLoading(false));
    } else {
      toast.error(
        "Upgrade Required: Unlock this feature by completing your payment. Questions? Contact support."
      );
    }
  };

  const getAlbumData = (viewer) => {
    setLoadingFilteredAlbums(true);
    folderService
      .getAlbumList(user?._id)
      .then((res) => {
        const folders = res?.data?.data || [];
        const albumsWithViewableTo = folders.map((folder) => ({
          ...folder,
          isChecked: folder.viewableTo.some(
            (item) => item.userId === viewer._id
          ),
        }));

        setAlbumsList(albumsWithViewableTo);
      })
      .catch((err) => {
        console.error("Get Album Data Error:", err);
      })
      .finally(() => setLoadingFilteredAlbums(false));
  };

  const handleVisibilityChange = (viewerId, albumId, isChecked) => {
    const updatedAlbumList = albumList.map((album) =>
      album._id === albumId
        ? {
            ...album,
            isChecked: !isChecked,
          }
        : album
    );
    setAlbumsList(updatedAlbumList);

    if (isChecked) {
      RemoveInvite(viewerId, albumId, !isChecked);
    } else {
      const payload = {
        folderId: [{ folderId: albumId }],
      };

      viewerService
        .manageViewerAlbumVisibiltiy(payload, viewerId, user?.token)
        .then((response) => {
          if (response?.data?.status === true) {
            const updatedAlbumList = albumList.map((album) =>
              album._id === albumId
                ? {
                    ...album,
                    isChecked: !isChecked,
                  }
                : album
            );
            setAlbumsList(updatedAlbumList);
            toast.success(
              "The selected album will now be visible to the designated viewer.",
              { position: "top-right" }
            );
          } else {
            setAlbumsList(albumList); 
            toast.error("Failed to update visibility.");
          }
        })
        .catch((error) => {
          setAlbumsList(albumList);
          console.error("Network ERROR", error);
          toast.error("Unable to update viewer", { position: "top-right" });
        });
    }
  };

  const RemoveInvite = (viewerId, albumId, previousCheckedState) => {
    const payloadData = {
      userId: [
        {
          userId: viewerId,
          date: "",
        },
      ],
    };

    setLoading(true);

    albumPrivacyService
      .updateUserPrivacySettings(albumId, payloadData, user?.token)
      .then((response) => {
        if (response.data.status === true) {
          toast.success("Success", { position: "top-right" });
        } else {
          setAlbumsList(
            albumList.map((album) =>
              album._id === albumId
                ? {
                    ...album,
                    isChecked: previousCheckedState,
                  }
                : album
            )
          );
          toast.error(
            "The selected album will not be accessible to the designated viewer."
          );
        }
      })
      .catch((error) => {
        setAlbumsList(
          albumList.map((album) =>
            album._id === albumId
              ? {
                  ...album,
                  isChecked: previousCheckedState,
                }
              : album
          )
        );
        console.error(error);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div onClick={() => getAlbumData(viewer)}>
      <Dropdown className="invite_access-dropdown">
        <Dropdown.Toggle>
          <span>Album Visibility</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <ul className="user_list">
            {!loadingFilteredAlbums ? (
              albumList?.length ? (
                albumList.map((album, index) => (
                  <li key={index}>
                    <div className="user-data text-capitalize">
                      <p>{album.folderName}</p>
                    </div>
                    <Form.Check
                      type="checkbox"
                      onChange={() =>
                        handleVisibilityChange(
                          viewer?._id,
                          album._id,
                          album.isChecked
                        )
                      }
                      checked={album.isChecked}
                    />
                  </li>
                ))
              ) : (
                <>
                  <Skeleton height={20} />
                  <Skeleton height={20} />
                  <Skeleton height={20} />
                </>
              )
            ) : (
              <>
                <Skeleton height={20} />
                <Skeleton height={20} />
                <Skeleton height={20} />
              </>
            )}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AlbumVisibilityDropdown;
