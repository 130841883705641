import { useEffect, useState } from "react";
import { useAuth } from "../../../_hooks/useAuth";
import DashboardHeading from "../../../components/DashboardHeading";
import { Button, Row, Col, Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { useDispatch } from "react-redux";
import {
  ownerLogoutAuth,
  ownerPasswordUpdatedtime,
  ownerUserEmail,
  ownerUserName,
  ownerContactDetails,
} from "../../../store/slices/ownerSlice";
import UploadProfilePic from "./uploadProfilePic";
import { ownerSettingsService } from "../../../_services/ownersettings";
import moment from "moment";
import { authService } from "../../../_services";
import BackButton from "../../../components/BackButton";
import Loader from "../../../components/Loader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DeleteOwnerConfirmationPopup from "./deleteOwnerPopup";
import Cookies from "js-cookie";
import { removePlanData } from "../../../store/slices/planSlice";
import { removePayAsYouGoData } from "../../../store/slices/payAsYouGoSlice";
const MyOwnerAccount = function () {
  const user = useAuth("user");
  const role = useAuth("role");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editingName, setEditingName] = useState(false);
  const [editingContactNo, setEditingContactNo] = useState(false);

  const [editingEmail, setEditingEmail] = useState(false);
  const [editingPassword, setEditingPassword] = useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(false);

  const [name, setName] = useState(user.userName);
  const [email, setEmail] = useState(user.email);
  const [contactNo, setContactNo] = useState({
    countryCode: user?.countryCode,
    mobileNumber: user?.mobileNumber,
  });
  const [updatedContactNo, setUpdatedContactNo] = useState({
    countryCode: "",
    mobileNumber: "",
  });
  const [password, setPassword] = useState();
  const [suggestedUserName, setSuggestedUserName] = useState("");

  const [displayUpdatedName, setDisplayUpdatedName] = useState();
  const [displayUpdatedEmail, setDisplayUpdatedEmail] = useState();
  const [displayUpdatedPasswordTime, setDisplayUpdatedPasswordTime] =
    useState();

  const handleNameEdit = (user) => {
    setEditingName(true);
  };
  const handleEditContactDetails = (contactNo) => {
    setEditingContactNo(true);
  };
  const handleEmailEdit = () => {
    setEditingEmail(true);
  };
  const handlePasswordEdit = () => {
    setEditingPassword(true);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleSaveChanges = () => {
    if (editingName) {
      setEditingName(false);
      verifyUserName(name);
    }

    if (editingEmail) {
      setEditingEmail(false);
      verifyEmail(email);
    }
    if (editingPassword) {
      setEditingPassword(false);
      // setEditingName(false);

      if (password) {
        const passwordRegex =
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()-_+=|{}[\]:;<>,.?/~]).{8,}$/;
        if (!passwordRegex.test(password)) {
          toast.error(
            "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character."
          );
          return;
        }
        // Proceed with password update

        setIsActiveLoading(true);
        const owenerData = {
          type: "password",
          password: password,
        };
        ownerSettingsService
          .updateOwnerDetails(owenerData, user?._id)
          .then((response) => {
            if (response?.data?.status === true) {
              setDisplayUpdatedPasswordTime(
                moment(response.data.data.updatedOwner.updatedAt).format(
                  "DD-MMM-YYYY"
                )
              );
              toast.success("Owner Password is been changed successfully", {
                position: "top-right",
              });
              setIsActiveLoading(false);

              dispatch(
                ownerPasswordUpdatedtime({
                  updatedPasswordTimeStamp: moment(
                    response.data.data.updatedOwner.updatedAt
                  ).format("DD-MMM-YYYY"),
                })
              );
            }
          })
          .catch((error) => {
            console.log("ERROR", error);
            setIsActiveLoading(false);
          });
      }
    }
    if (editingContactNo) {
      setEditingContactNo(false);
      console.log(contactNo, "contactNocontactNo");
      if (contactNo != "") {
        const owenerData = {
          countryCode: `+${contactNo.countryCode}`,
          mobileNumber: contactNo.mobileNumber,
        };
        setIsActiveLoading(true);

        ownerSettingsService
          .updateOwnerContactNo(owenerData, user?.token)
          .then((response) => {
            if (response?.data?.status === true) {
              console.log(response?.data, "response?.data");
              toast.success("Owner Contact No is been updated successfully", {
                position: "top-right",
              });
              setIsActiveLoading(false);

              dispatch(
                ownerContactDetails({
                  countryCode: response.data.data.countryCode,
                  mobileNumber: response.data.data.mobileNumber,
                })
              );
              setUpdatedContactNo({
                countryCode: response.data.data.countryCode,
                mobileNumber: response.data.data.mobileNumber,
              });
            }
          })
          .catch((error) => {
            console.log("ERROR", error);
            setIsActiveLoading(false);
          });
      }
    }
  };
  // VERIFY USERNAME
  const verifyUserName = (name) => {
    if (name) {
      setIsActiveLoading(true);

      const data = {
        userName: name,
      };
      authService
        .verifyUsername(data)
        .then((response) => {
          if (response.data.status == false) {
            setSuggestedUserName(response?.data?.data?.suggestedUsernames);
            toast.error(
              "Owner Name Already Exists, Please Try Again With Provided List"
            );
            setEditingName(true);
            setIsActiveLoading(false);
          } else {
            const owenerData = {
              type: "userName",
              userName: name,
            };
            ownerSettingsService
              .updateOwnerDetails(owenerData, user?._id)
              .then((response) => {
                if (response?.data?.status === true) {
                  setDisplayUpdatedName(
                    response.data.data.updatedOwner.userName
                  );
                  toast.success("Owner Name is updated successfully", {
                    position: "top-right",
                  });
                  setIsActiveLoading(false);

                  dispatch(
                    ownerUserName({
                      userName: response.data.data.updatedOwner.userName,
                    })
                  );
                }
              });
          }
        })

        .catch((error) => {
          console.log("ERROR", error);
          setIsActiveLoading(false);
        });
    }
  };
  // VERIFY EMAIL
  const verifyEmail = (email) => {
    if (email) {
      setIsActiveLoading(true);
      const data = {
        email: email,
      };
      authService
        .verifyEmailAddress(data)
        .then((response) => {
          if (response.data.status == true) {
            const owenerData = {
              type: "email",
              email: email,
            };
            ownerSettingsService
              .updateOwnerDetails(owenerData, user?._id)
              .then((response) => {
                if (response?.data?.status === true) {
                  setDisplayUpdatedEmail(response.data.data.updatedOwner.email);
                  toast.success(
                    "Owner Email is updated successfully, Kindly Verify Your Account & Re-Login",
                    {
                      position: "top-right",
                    }
                  );
                  setIsActiveLoading(false);

                  dispatch(
                    ownerUserEmail({
                      email: response.data.data.updatedOwner.email,
                    })
                  );
                  setTimeout(() => {
                    dispatch(ownerLogoutAuth());
                    navigate("/");
                  }, 2000);
                }
              });
          }
        })
        .catch((error) => {
          console.log("ERROR", error);
          if (error?.response?.data?.error == true) {
            setIsActiveLoading(false);

            toast.error("Email Already Exist!");
          }
        });
    }
  };

  // DELETE OWNER ACCOUNT
  const [showDeleteAcPopUp, setShowDeleteAcPopUp] = useState(false);
  const handleCloseDeleteAcPopUp = () => setShowDeleteAcPopUp(false);
  const handleShowDeleteAcPopUp = () => setShowDeleteAcPopUp(true);
  const handleDeleteAc = () => {
    setIsActiveLoading(true);
    ownerSettingsService
      .deleteOwnerAccount(user?._id)
      .then((response) => {
        if (response.data.status == true) {
          toast.success("Your account has been deleted");
          setIsActiveLoading(false);
          Cookies.remove("username");
          Cookies.remove("password");
          localStorage.removeItem("rememberMe");

          // Cookies.remove("username");
          // Cookies.remove("password");
          setTimeout(() => {
            dispatch(ownerLogoutAuth());
            dispatch(removePlanData());
            dispatch(removePayAsYouGoData());
            // Redirect to the pricing page on a different domain
            window.location.href = "https://www.forevermems.com/pricing/";
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
        setIsActiveLoading(false);
      });
  };
  // SIGNOUT  OWNER ACCOUNT
  const handleSignOutFromAllDevices = () => {
    setIsActiveLoading(true);

    ownerSettingsService
      .signoutFromAllDevices(user?._id)
      .then((response) => {
        if (response.data.status == true) {
          toast.success("Your account has been signed out from all devices");
          setIsActiveLoading(false);
          dispatch(removePayAsYouGoData());
          dispatch(removePlanData());

          setTimeout(() => {
            dispatch(ownerLogoutAuth());
            navigate("/");
          }, 2000);
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
        setIsActiveLoading(false);
      });
  };

  return (
    <>
      {isActiveLoading && <Loader />}

      <div className="d-flex align-items-center pb-3 border-bottom mb-3 ">
        <div className="d-flex my-account">
          <BackButton />
          <DashboardHeading heading="My Account" />
        </div>
      </div>
      <div className="account-container px-4">
        <Row>
          <Col xl={10} lg={10} md={12} xs={12}>
            <UploadProfilePic />
            <div className="py-4 border-bottom d-flex justify-content-between align-items-center ps-xl-5">
              <div className="d-flex align-items-center gap-3 ps-xl-5">
                <div className="user-info">
                  <h3 className="user-label">Name</h3>

                  {editingName ? (
                    <>
                      <Form.Control
                        type="text"
                        value={name}
                        onChange={handleNameChange}
                      />
                      <p className="d-flex">
                        {suggestedUserName?.length > 0 &&
                          suggestedUserName.map((item, index) => (
                            <div key={index}>
                              <span className="text-danger">{item}</span>
                              {index < suggestedUserName.length - 1 && ", "}
                            </div>
                          ))}
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="user-txt">
                        {displayUpdatedName || user.userName}
                      </p>
                    </>
                  )}
                </div>
              </div>
              {editingName ? (
                <Button
                  className="outline-btn"
                  onClick={() => handleSaveChanges()}
                >
                  Save Changes
                </Button>
              ) : (
                <Button className="outline-btn" onClick={handleNameEdit}>
                  Edit
                </Button>
              )}
            </div>
            <div className="py-4 border-bottom d-flex justify-content-between align-items-center ps-xl-5">
              <div className="d-flex align-items-center gap-3 ps-xl-5">
                <div className="user-info">
                  <h3 className="user-label">Contact Details</h3>

                  {editingContactNo ? (
                    <>
                      <PhoneInput
                        country={contactNo?.countryCode}
                        value={`${contactNo?.countryCode} ${contactNo?.mobileNumber}`}
                        onChange={(value, country) => {
                          setContactNo({
                            countryCode: country.dialCode,
                            mobileNumber: value?.slice(
                              country?.dialCode?.length
                            ),
                          });
                        }}
                      />

                      {contactNo == undefined ||
                        (contactNo == "" && (
                          <p className="text-danger">
                            Enter the contact number
                          </p>
                        ))}
                    </>
                  ) : (
                    <>
                      <p className="user-txt">
                        {user.countryCode && user.mobileNumber
                          ? `${user.countryCode} ${user.mobileNumber}`
                          : updatedContactNo.countryCode &&
                            updatedContactNo.mobileNumber
                          ? `${updatedContactNo.countryCode} ${updatedContactNo.mobileNumber}`
                          : "Please Update your contact details"}
                      </p>
                    </>
                  )}
                </div>
              </div>
              {editingContactNo ? (
                <Button
                  className="outline-btn"
                  onClick={() => handleSaveChanges()}
                >
                  Save Changes
                </Button>
              ) : (
                <Button
                  className="outline-btn"
                  onClick={handleEditContactDetails}
                >
                  Edit
                </Button>
              )}
            </div>
            <div className="py-4 border-bottom d-flex justify-content-between align-items-center ps-xl-5">
              <div className="d-flex align-items-center gap-3 ps-xl-5">
                <div className="user-info">
                  <h3 className="user-label">Email</h3>
                  {editingEmail ? (
                    <Form.Control
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                    />
                  ) : (
                    <p className="user-txt">
                      {displayUpdatedEmail || user.email}
                    </p>
                  )}
                </div>
              </div>
              {editingEmail ? (
                <Button className="outline-btn" onClick={handleSaveChanges}>
                  Save Changes
                </Button>
              ) : (
                <Button className="outline-btn" onClick={handleEmailEdit}>
                  Edit
                </Button>
              )}{" "}
            </div>
            <div className="py-4 border-bottom d-flex justify-content-between align-items-center ps-xl-5">
              <div className="d-flex align-items-center gap-3 ps-xl-5">
                <div className="user-info">
                  <h3 className="user-label">Password</h3>
                  {editingPassword ? (
                    <>
                      <Form.Control
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                      />
                      {password == undefined && (
                        <p className="text-danger">
                          Enter the password to be changed
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      <p className="user-txt">
                        Last Updated On :{" "}
                        {displayUpdatedPasswordTime ||
                          user.updatedPasswordTimeStamp ||
                          "N/A"}
                      </p>
                    </>
                  )}
                </div>
              </div>
              {editingPassword ? (
                <Button className="outline-btn" onClick={handleSaveChanges}>
                  Save Changes
                </Button>
              ) : (
                <Button className="outline-btn" onClick={handlePasswordEdit}>
                  Edit
                </Button>
              )}{" "}
            </div>
            <div className="py-4 border-bottom d-flex justify-content-between align-items-center ps-xl-5">
              <div className="d-flex align-items-center gap-3 ps-xl-5">
                <div className="user-info">
                  <h3 className="user-label">Upgrade Your Plan</h3>
                  <p className="user-txt">
                    By upgrading your account, you’ll be able to access more
                    features.
                  </p>
                </div>
              </div>
              <Button
                className="outline-btn"
                onClick={() => navigate(`/${role}/plans`)}
              >
                Upgrade Plan
              </Button>
            </div>
            <div className="py-4 border-bottom d-flex justify-content-between align-items-center ps-xl-5">
              <div className="d-flex align-items-center gap-3 ps-xl-5">
                <div className="user-info">
                  <h3 className="user-label">Sign out from all devices</h3>
                  <p className="user-txt">
                    Logged in on a shared device but forgot to sign out? End all
                    sessions by signing out from all devices.
                  </p>
                </div>
              </div>
              <Button
                className="outline-btn"
                onClick={handleSignOutFromAllDevices}
              >
                Sign out from all devices
              </Button>
            </div>
            <div className="py-4 border-bottom d-flex justify-content-between align-items-center ps-xl-5">
              <div className="d-flex align-items-center gap-3 ps-xl-5">
                <div className="user-info">
                  <h3 className="user-label">Delete your account</h3>
                  <p className="user-txt">
                    By deleting your account, you’ll no longer be able to access
                    any of your albums on Forevermems.
                  </p>
                </div>
              </div>
              <Button
                className="outline-btn"
                onClick={() => {
                  // handleDeleteAc();
                  handleShowDeleteAcPopUp();
                }}
              >
                Delete account
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      {/* DELETE OWNER ACCOUNT CONFIRMATION POP UP */}
      <>
        {showDeleteAcPopUp && (
          <DeleteOwnerConfirmationPopup
            showDeleteAcPopUp={showDeleteAcPopUp}
            setShowDeleteAcPopUp={setShowDeleteAcPopUp}
            handleCloseDeleteAcPopUp={handleCloseDeleteAcPopUp}
            handleShowDeleteAcPopUp={handleShowDeleteAcPopUp}
            handleDeleteAc={handleDeleteAc}
            isActiveLoading={isActiveLoading}
          />
        )}
      </>
    </>
  );
};

export default MyOwnerAccount;
