import React, { useEffect, useState } from "react";
import { viewerService } from "../../_services/viewer";
import { toast } from "react-hot-toast";
import { Spinner, Dropdown, Row, Col, Card } from "react-bootstrap";
import Loader from "../Loader";
import Swal from "sweetalert2";
import NoContent from "../NoContent";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getOwnerAuth } from "../../store/slices/ownerSlice";
import { folderService, ownerSettingsService } from "../../_services";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeviceScreen } from "../DeviceScreen";
import AlbumVisibilityDropdown from "../AlbumVisibility";
import Form from 'react-bootstrap/Form';

function ViewerTable({
  viewerListData,
  handleEditClick,
  setIsLoadingForList,
  setRemovedViewer,
  isGridViewActive,
  isExector = false,
  user,
}) {
  const { isMobile } = useDeviceScreen();

  const location = useLocation();
  const { pathname, search, state } = location;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [index, setIndex] = useState();
  const [albumList, setAlbumsList] = useState();
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [loadingFilteredAlbums, setLoadingFilteredAlbums] = useState(false);
  const loggedInOwnerData = useSelector(getOwnerAuth);
  const [nonImageAlbums, setNonImageAlbums] = useState([]);
  const [updateFileUrl, setUpdateFileUrl] = useState("");
  const handleSentinvitation = (viewer) => {
    setIsLoading(true);
    const data = {
      email: viewer?.email,
    };
    viewerService
      .sendViewerInvitation(data, viewer?._id, user?.token)
      .then((response) => {
        toast.success("Invitation has been sent to the Viewer!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const confirmRemove = (executer) => {
    Swal.fire({
      title: "Are You Sure Want To remove this viewer !",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleRemoveViewer(executer);
      }
    });
  };
  const handleRemoveViewer = (viewer) => {
    setIsLoadingAction(true);
    viewerService
      .removeViewer(viewer._id, user?.token)
      .then((response) => {
        setRemovedViewer(response?.data?.data);
        toast.success("Viewer Removed Successfully");
        setIsLoadingAction(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
        setIsLoadingAction(false);
      });
  };

  const getAlbumData = (viewer) => {
    setLoadingFilteredAlbums(true);
    folderService
      .getAlbumList(loggedInOwnerData?._id)
      .then((res) => {
        let viewerFolderIds = [];
        if (viewer && Array.isArray(viewer.viewableTo)) {
          viewerFolderIds = viewer.viewableTo.flatMap((item) => item?.folderId);
        } else {
          console.log("Viewer is not in the expected format");
        }
        const filteredFolders = res?.data?.data.filter(
          (folder) => !viewerFolderIds.includes(folder?._id)
        );
        setAlbumsList(filteredFolders);
        setLoadingFilteredAlbums(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingFilteredAlbums(false);
      });
  };

  const handleVisibilityChange = (selectedOption, viewer) => {
    setIsLoadingAction(true);
    const payload = {
      folderId: [{ folderId: selectedOption?.value?._id }],
    };
    viewerService
      .manageViewerAlbumVisibiltiy(payload, viewer, user?.token)
      .then((response) => {
        if (response?.data?.status === true) {
          setIsLoadingAction(false);
          toast.success(
            "Selected album's visibility is been enabled successfully",
            {
              position: "top-right",
            }
          );
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })

      .catch((error) => {
        console.log("Network ERROR", error);
        toast.error("Unable to update viewer", {
          position: "top-right",
        });
        setIsLoadingAction(false);
      });
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">
          All Albums Have Been Granted Access
        </span>
      </components.NoOptionsMessage>
    );
  };
  // avatar img expiration
  useEffect(() => {
    const checkThumbnailUrl = async (album) => {
      if (album?.avatar) {
        try {
          await loadImage(album.avatar);
          console.log("Thumbnail URL is an image:>", album.avatar);
        } catch (error) {
          console.log("Thumbnail URL is not an image:>", album.avatar);
          if (
            !nonImageAlbums.find(
              (nonImageAlbum) => nonImageAlbum._id === album._id
            )
          ) {
            setNonImageAlbums((prevAlbums) => [...prevAlbums, album]);
          }
        }
      }
    };

    viewerListData.forEach((album) => checkThumbnailUrl(album));
  }, [viewerListData]);

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => reject();
      img.src = url;
    });
  };

  useEffect(() => {
    if (nonImageAlbums?.length > 0) {
      handleUpdateAlbumThumbnailUrls(nonImageAlbums);
    }
  }, [nonImageAlbums]);

  const handleUpdateAlbumThumbnailUrls = (fileDataArray) => {
    const updatePromises = fileDataArray?.map((album) => {
      const ownerAlbumUrlData = {
        role: album?.role,
        fileName: album?.fileName,
      };
      // Return the promise for updating the thumbnail URL

      return ownerSettingsService.updateProfileImageURL(
        album?._id,
        ownerAlbumUrlData
      );
    });

    // Use Promise.all to wait for all update requests to complete
    Promise.all(updatePromises)
      .then((responses) => {
        setUpdateFileUrl(Math.round());
        responses.forEach((response) => {
          if (response?.data?.status === true) {
            console.log("File URL updated successfully");
            window.location.reload();
            // getViewerListData();
          }
        });
      })
      .catch((error) => {
        console.log("Network ERROR", error);
      });
  };

  return (
    <>
      {isLoadingAction && <Loader />}
      {/* {viewerListData.length === 0 && <NoContent />} */}
      {(isGridViewActive && !isMobile) || (!isGridViewActive && isMobile) ? (
        <>
          {viewerListData?.map((viewer, ind) => {
            const extractInitials = (name) => {
              return name ? name.charAt(0).toUpperCase() : "";
            };

            return (
              <div className="invite-user-list" key={ind}>
                <div className="invite-user-icon">
                  <span className="username-icon">
                    {viewer.avatar == null ? (
                      <span className="username-icon ">
                        {" "}
                        {extractInitials(viewer.firstName)}
                        {extractInitials(viewer.lastName)}
                      </span>
                    ) : (
                      <span className="username-icon d-flex align-items-center justify-content-center">
                        <img src={viewer.avatar} alt="" />
                      </span>
                    )}
                  </span>
                </div>
                <div className="invite-user-info">
                  <div className="invite-user-detail">
                    <h5 className="text-capitalize">
                      <b>
                        {viewer?.firstName} {viewer?.lastName}
                      </b>
                    </h5>
                    <p>{viewer.email}</p>
                    <p>
                      {viewer.countryCode} {viewer.mobileNumber}
                    </p>
                  </div>
                  <div className="invite-user-btn-group gap-5">
                    {!isExector && (
                      // viewer?.status === "Verified" &&
                      <div className="select-folder-test2">
                        <AlbumVisibilityDropdown viewer={viewer} />
                      </div>
                    )}

                    {!isExector && (
                      <div className="enabledisableInvite d-flex justify-content-center">
                        <img className="w-30" src="assets/message.svg" alt="" />{" "}
                        {viewer?.status !== "Verified" && (
                          <>
                            {isLoading && ind === index ? (
                              <Spinner />
                            ) : (
                              <div
                                className="invite-you"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleSentinvitation(viewer);
                                  setIndex(ind);
                                }}
                              >
                                <img
                                  className="w-30"
                                  src="assets/message.svg"
                                  alt=""
                                />{" "}
                                Resend Invitation
                              </div>
                            )}
                          </>
                        )}
                        {viewer?.status === "Verified" && (
                          <>
                            <div
                              className="disableInvite"
                              style={{ cursor: "not-allowed" }}
                            >
                              <img
                                className="w-30"
                                src="assets/message.svg"
                                alt=""
                              />{" "}
                              Resend Invitation
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    <div>
                      {viewer.status === "Unverified"
                        ? "Unverified"
                        : viewer.status === "Verified"
                          ? "Verified"
                          : "Pending"}
                    </div>
                  </div>
                </div>
                {!isExector && (
                  <>
                    {pathname != "/owner/step-wizard" && (
                      <div className="edit-icon">
                        <Dropdown className="edit_option-dropdown">
                          <Dropdown.Toggle>
                            <i className="ri-more-fill text-muted"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <ul className="edit_option_list">
                              <li
                                onClick={() => {
                                  handleEditClick(viewer);
                                }}
                              >
                                <i className="ri-edit-line"></i> Edit
                              </li>
                              <li
                                onClick={() => {
                                  confirmRemove(viewer);
                                }}
                              >
                                <i className="ri-delete-bin-line"></i> Remove
                              </li>
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </>
      ) : (
        <>
          {
            <div className="grid-info-executor">
              <Row>
                {viewerListData?.map((viewer, ind) => {
                  const extractInitials = (name) => {
                    return name ? name.charAt(0).toUpperCase() : "";
                  };

                  // Determine avatar content
                  // const fullName = `${viewer?.firstName} ${viewer?.lastName}`;
                  // const avatarContent = viewer.avatar
                  //   ? viewer.avatar
                  //   : extractInitials(fullName);
                  return (
                    <Col
                      lg={4}
                      xl={4}
                      md={6}
                      xs={12}
                      className="mb-3"
                      key={ind}
                    >
                      <Card>
                        {!isExector && (
                          <div className="edit-icon d-flex align-items-right justify-content-end px-3 ">
                            <Dropdown className="edit_option-dropdown">
                              <Dropdown.Toggle>
                                <i className="ri-more-fill text-muted"></i>
                              </Dropdown.Toggle>

                              {pathname != "/owner/step-wizard" && (
                                <Dropdown.Menu>
                                  <ul className="edit_option_list">
                                    <li
                                      onClick={() => {
                                        handleEditClick(viewer);
                                      }}
                                    >
                                      <i className="ri-edit-line"></i> Edit
                                    </li>
                                    <li
                                      onClick={() => {
                                        confirmRemove(viewer);
                                      }}
                                    >
                                      <i className="ri-delete-bin-line"></i>{" "}
                                      Remove
                                    </li>
                                  </ul>
                                </Dropdown.Menu>
                              )}
                            </Dropdown>
                          </div>
                        )}

                        <Card.Body>
                          <div className="d-flex align-items-center gap-4 mb-3">
                            <div className="invite-user-icon d-flex align-items-center justify-content-center">
                              {viewer.avatar == null ? (
                                <span className="username-icon">
                                  {" "}
                                  {extractInitials(viewer.firstName)}
                                  {extractInitials(viewer.lastName)}
                                </span>
                              ) : (
                                <img src={viewer.avatar} alt="" />
                              )}
                            </div>
                            <div className="invite-user-info">
                              <div className="invite-user-detail text-capitalize">
                                <h5>
                                  <b>
                                    {viewer?.firstName} {viewer?.lastName}
                                  </b>
                                </h5>
                                <p>{viewer?.email}</p>
                                <p>
                                  {viewer?.countryCode} {viewer?.mobileNumber}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* <div className="d-flex align-items-center justify-content-between mb-2 col-12">
                            <span className="label">Can View Videos</span>
                            <span className="relation-name">
                              <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                label=""
                              />
                            </span>
                          </div> */}
                          {/* <div className="d-flex align-items-center justify-content-between mb-2 col-12">
                            <span className="label">Relation to you</span>
                            <span className="relation-name">
                              Wife
                            </span>
                          </div> */}
                          <div className="d-flex align-items-center justify-content-between mb-2 col-12">
                            <span className="label">Status</span>
                            <span className="relation-name">
                              {viewer?.status}
                            </span>
                          </div>

                          {!isExector && (
                            <div className="select-folder-test2">
                              <AlbumVisibilityDropdown viewer={viewer} />
                            </div>
                          )}

                          {!isExector && (
                            <div className="enabledisableInvite d-flex justify-content-center">
                              <img
                                className="w-30"
                                src="assets/message.svg"
                                alt=""
                              />{" "}
                              {viewer?.status === "Verified" && (
                                <>
                                  <div
                                    className="invite-you "
                                    style={{ cursor: "not-allowed" }}
                                  >
                                    <img
                                      className="w-30"
                                      src="assets/message.svg"
                                      alt=""
                                    />{" "}
                                    Resend Invitation
                                  </div>
                                </>
                              )}
                              {viewer?.status !== "Verified" && (
                                <>
                                  {isLoading && ind === index ? (
                                    <Spinner />
                                  ) : (
                                    <div
                                      className="invite-you"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        handleSentinvitation(viewer);
                                        setIndex(ind);
                                      }}
                                    >
                                      <img
                                        className="w-30"
                                        src="assets/message.svg"
                                        alt=""
                                      />{" "}
                                      Resend Invitation
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          }
        </>
      )}
    </>
  );
}

export default ViewerTable;
