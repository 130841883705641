import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { executorService } from "../../../_services";
import { useAuth } from "../../../_hooks/useAuth";
import moment from "moment";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { executorPassingDateStatus } from "../../../store/slices/executerSlice";
import { setExecutorFolders } from "../../../store/slices/executorFolderSlice";

function PassingDateModal({ show, onHide, demisedOwnerData }) {
  const [formData, setFormData] = useState({
    passingDate: null,
    isLoading: false,
    isError: false,
    isUpdated: false,
  });
  console.log(demisedOwnerData, "demisedOwnerData");
  const dispatch = useDispatch();
  const user = useAuth("user");

  const handleChange = (event) => {
    const selectedDateTime = moment(event.target.value);
    const currentDateTime = moment();

    if (selectedDateTime.isAfter(currentDateTime)) {
      toast.error("Please select a past or current date and time.");
      setFormData({ ...formData, isError: true });
    } else {
      const formattedDate = selectedDateTime.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      setFormData({ ...formData, passingDate: formattedDate, isError: false });
    }
  };

  const handleAddDate = () => {
    if (!formData.passingDate) {
      setFormData({ ...formData, isError: true });
      return;
    }
    setFormData({ ...formData, isLoading: true });

    const passingData = {
      dateOfDeath: formData.passingDate,
    };

    executorService
      .ownerPassingDate(demisedOwnerData?._id, passingData, user?.token)
      .then((response) => {
        if (response.data.status == true) {
          console.log(response.data.status, "response.data.status");
          setFormData({ ...formData, isUpdated: true, isLoading: false });
          toast.success("Passing date updated successfully");
          dispatch(
            executorPassingDateStatus({
              ownerPassingDateStatus: true,
            })
          );
          dispatch(setExecutorFolders(response.data.data.folderData));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => {
        setFormData({ ...formData, isLoading: false });
        toast.error("Unable to update passing date");
      });
  };

  return (
    <Modal
      className="pass-date-modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton onClick={onHide}></Modal.Header>
      {!formData.isUpdated ? (
        <Modal.Body className="text-center pt-4 pb-5">
          {!demisedOwnerData?.dateOfDeath ? (
            <>
              <h4 className="text-center pass-name w-75 mx-auto text-capitalize">
                Please input the date of passing for{" "}
                {demisedOwnerData?.userName || "Owner"}
              </h4>
            </>
          ) : (
            <>
              <h4>
                An entry for {demisedOwnerData?.userName || "Owner"}'s demised
                date has already been recorded.
              </h4>
              <h5 className="py-2">
                Date of Passing:{" "}
                {demisedOwnerData?.dateOfDeath
                  ? moment(demisedOwnerData.dateOfDeath).format("Do MMMM YYYY")
                  : "Date of passing not specified"}
              </h5>
            </>
          )}
          {!demisedOwnerData?.dateOfDeath && (
            <input
              className="d-block w-50 m-auto my-3"
              type="datetime-local"
              id="birthdaytime"
              name="birthdaytime"
              max={new Date().toISOString().split(".")[0]}
              onChange={handleChange}
            />
          )}
          {formData.isError && (
            <div className="pb-2">
              <p className="text-danger">Passing date is required</p>
              <p className="text-danger fw-bolder">
                Please be careful, once submitted, the information cannot be
                changed!
              </p>
            </div>
          )}
          {formData.isLoading ? (
            <Spinner animation="border" role="status"></Spinner>
          ) : (
            <>
              {!demisedOwnerData?.dateOfDeath && (
                <>
                  {/* <p className="text-danger py-3">
                    Please be careful, once submitted, the information cannot be
                    changed!
                  </p> */}
                  <Button
                    className="d-block w-25 m-auto"
                    onClick={handleAddDate}
                  >
                    Submit
                  </Button>
                </>
              )}
            </>
          )}
        </Modal.Body>
      ) : (
        <Modal.Body className="text-center pt-3 pb-5">
          <h4 className="text-center pass-name w-75 mx-auto">
            Thank you for entering the passing date for{" "}
            {demisedOwnerData?.userName || "Owner"}
          </h4>
          <p className="px-5 pass-modal-para">
            Please stay tuned as we verify the information. Once confirmed, you
            will receive an email granting access to the account. Your
            assistance in this matter is greatly appreciated.
          </p>
        </Modal.Body>
      )}
    </Modal>
  );
}

export default PassingDateModal;
