
import React from "react";
import { Button, Container, Modal, Spinner, Table } from "react-bootstrap";
import Loader from "../../../../components/Loader";
import moment from "moment";
import SkeletonLoader from "../../../../components/SkeletonLoader";

const SubscriptionHistory = ({
  show,
  handleClose,
  subscriptionHistoryData,
  loading,
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered className="subcription-popup-modal">
      <Modal.Header closeButton className="subcription-popup-header">
        <Modal.Title>Subscription Histories</Modal.Title>
      </Modal.Header>
      {loading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <Modal.Body>
       
          <Container>
            <Table  hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Plan</th>
                  <th>Price</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {subscriptionHistoryData
                  ?.map((subs, index) => (
                    <tr key={index}>
                      <td>{moment(subs.startDate).format("MM/DD/YYYY")}</td>
                      <td>{subs.selectedPlanType}</td>
                      <td>$ { subs?.selectedPlanType == 'Standard' ? subs?.discountPrice :  subs?.selectedPlanType == 'PayAsYouGo' ?  subs.monthlyEstimate : subs?.discountPrice}</td>
                      <td>
                        {subs.selectedPlanType !== "Free" ? "Paid" : "N/A"}
                      </td>
                    </tr>
                  ))
                  .reverse()}
              </tbody>
            </Table>
          </Container>
          {/* )} */}
        </Modal.Body>
      )}
      <Modal.Footer className="d-flex flex-column subcription-popup-footer">
        <Button variant="" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubscriptionHistory;
