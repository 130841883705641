import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slices/index.js";

import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
const persistConfig = {
  key: "forevermems",
  storage,
  blacklist: ['recordingForm'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  })
});

export const persistor = persistStore(store);
