import React, { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectedCheckoutPlanData } from "../../store/slices/planSlice";
import { useAuth } from "../../_hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { planServices } from "../../_services";
import { countryOptions } from "../../_helpers/countryOptions";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";

const CheckoutForm = () => {
  const user = useAuth("user");
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const checkOutplanData = useSelector(selectedCheckoutPlanData);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const checkout = JSON.parse(localStorage.getItem("checkout")) || null;
  const [countryErr, setCountryErr] = useState(true);

  useEffect(() => {
    const createPayment = async () => {
      try {
        const response = await planServices.createPaymentIntent(
          checkOutplanData,
          checkout.customer
        );
        const data = response.data;
        setClientSecret(data.client_secret);
      } catch (error) {
        console.error("Error creating payment intent:", error);
      }
    };

    if (checkOutplanData) {
      createPayment();
    }
  }, [checkOutplanData]);
  const savePaymentMethod = async (paymentMethodId, clientSecret) => {
    try {
      if (checkOutplanData.planType === "Standard") {
        const customerId = checkout.customer;
        const planTenure = checkout.planTenure;

        const paymentMethodResponse = await planServices.paymentMethodOfStripe(
          paymentMethodId,
          customerId
        );

        const customerResponse = await planServices.customerStripe(
          customerId,
          paymentMethodId
        );

        const priceId =
          planTenure !== "m"
            ? "price_1PaEPSJK9rsi42lCJTIyJnbT"
            : "price_1PaEhxJK9rsi42lCoK9jHXIy";
        const subscriptionResponse = await planServices.stripeSubscription(
          customerId,
          priceId
        );
      }
    } catch (error) {
      console.error("Error saving payment method:", error.message);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address format")
      .required("Email is required"),
    name: Yup.string().required("Full name is required"),
    address: Yup.string().required("Address is required"),
    country: Yup.string().required("Country is required"),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsProcessing(true);
    if (!stripe || !elements) {
      setPaymentError("Stripe has not loaded yet.");
      setIsProcessing(false);
      setSubmitting(false);
      return;
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name: values.name,
          email: values.email,
          address: {
            line1: values.address,
            country: values.country,
          },
        },
      });
      if (values.country) {
        setCountryErr(false);
      }
      if (error) {
        setPaymentError(error.message);
        setPaymentSuccess(null);
        setIsProcessing(false);
        setSubmitting(false);
        return;
      }

      await savePaymentMethod(paymentMethod.id, clientSecret);

      const { paymentIntent, error: confirmError } =
        await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethod.id,
        });

      if (confirmError) {
        setPaymentError(confirmError.message);
        setPaymentSuccess(null);
        setIsProcessing(false);
        setSubmitting(false);
        return;
      }

      if (paymentIntent.status === "succeeded") {
        setPaymentSuccess("Payment successful!");
        localStorage.setItem(
          "payment_intent",
          JSON.stringify({
            payment_intent: paymentIntent.id,
          })
        );
        setPaymentResponse(paymentIntent.id);
        setPaymentId(paymentIntent.id);
        setPaymentError(null);
        setTimeout(() => {
          navigate(`/${user?.role}/payment-success`);
        }, 3000);
      }
    } catch (error) {
      console.error("Error during payment:", error.message);
      setPaymentError("Failed to process payment. Please try again later.");
      setPaymentSuccess(null);
      setIsProcessing(false);
      setSubmitting(false);
    }
  };

  return (
    <Container className="checkout-form-container">
      <Row className="justify-content-md-center">
        <Col md={6}>
          <h2 className="mb-4 review-heading">Review order details</h2>
          <div className="order-summary">
            <h4 className="membership-heading">Forevermems Membership</h4>
            <p>Starting membership on {new Date().toLocaleDateString()}</p>
            <p>
              <strong>Monthly</strong> $
              {checkOutplanData?.discountPrice?.toFixed(2) ||
                checkOutplanData?.price?.toFixed(2)}
              {checkOutplanData?.validityType == "Year" ? "/year" : " /month"}
            </p>
            <p>
              <strong>Tax/VAT</strong>{" "}
              {checkOutplanData?.validityType == "Year"
                ? "$0/year"
                : "$0/month"}
            </p>
            <p>
              <strong>Total</strong>{" "}
              <strong>
                USD $
                {checkOutplanData?.discountPrice?.toFixed(2) ||
                  checkOutplanData?.price?.toFixed(2)}
                {checkOutplanData?.validityType == "Year" ? "/year" : " /month"}
              </strong>
            </p>
          </div>
          <h2 className="mb-4 review-secondary">Purchase with confidence</h2>
          <ul className="confidence-list">
            <li>
              <div className="d-flex align-items-start">
                <i className="ri-refund-2-line"></i>
                <div className="content">
                  <strong>Refund / Cancellation policy</strong>: Feel safe. 15
                  days for a Full refund
                </div>
              </div>
            </li>
            <li>
              <div className="d-flex align-items-start">
                <i className="ri-shield-line"></i>
                <div className="content">
                  <strong>Privacy and Security</strong>: All Personal
                  information you submit is Encrypted and Secure
                </div>
              </div>
            </li>
            <li>
              <div className="d-flex align-items-start">
                <i className="ri-team-line"></i>
                <div className="content">
                  <strong>Easy support & Help</strong>: Need help? Our support
                  team is available at any time. Please visit{" "}
                  <a href="mailto:support@forevermems.com">
                    support@forevermems.com
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </Col>
        <Col md={6}>
          <h2 className="mb-4 review-heading">Contact</h2>
          <Formik
            initialValues={{
              email: "",
              name: "",
              address: "",
              country: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, errors, touched }) => (
              <FormikForm>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Field
                    type="email"
                    name="email"
                    as={Form.Control}
                    isInvalid={errors.email && touched.email}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback my-3"
                  />
                </Form.Group>
                <h2 className="mb-4 review-secondary">Payment details</h2>
                <Form.Group controlId="cardElement">
                  <Form.Label>Card number</Form.Label>
                  <CardElement
                    className="p-3"
                    options={{
                      style: {
                        base: {
                          fontSize: "16px",
                          color: "#424770",
                          "::placeholder": {
                            color: "#aab7c4",
                          },
                        },
                        invalid: {
                          color: "#9e2146",
                        },
                      },
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="name">
                  <Form.Label>Full name</Form.Label>
                  <Field
                    type="text"
                    name="name"
                    as={Form.Control}
                    isInvalid={errors.name && touched.name}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback my-3"
                  />
                </Form.Group>
                <Form.Group controlId="country">
                  <Form.Label>Country or region</Form.Label>
                  <Field
                    as="select"
                    name="country"
                    className={`form-control ${
                      errors.country && touched.country ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">Select Country</option>
                    {countryOptions?.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="country"
                    component="div"
                    className="invalid-feedback my-3"
                  />
                </Form.Group>
                <Form.Group controlId="address">
                  <Form.Label>Address</Form.Label>
                  <Field
                    type="text"
                    name="address"
                    as={Form.Control}
                    className={`form-control ${
                      errors.address && touched.address ? "is-invalid" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="invalid-feedback my-3"
                  />
                </Form.Group>
                {paymentError && (
                  <div style={{ color: "red" }}>{paymentError}</div>
                )}
                {paymentSuccess && (
                  <div style={{ color: "green" }}>{paymentSuccess}</div>
                )}
                <Button
                  className="mt-4 w-100"
                  type="submit"
                  disabled={!stripe || isProcessing || isSubmitting}
                >
                  {isProcessing ? "Processing..." : "Subscribe"}
                </Button>
                <p className="mt-4 subsPara">
                  By confirming your subscription, you allow Forevermems to
                  charge your card for this payment and future payments in
                  accordance with their terms. You can always cancel your
                  subscription.
                </p>
              </FormikForm>
            )}
          </Formik>
        </Col>
        <Col md={12}>
          <div className="footer-container">
            <p>
              Powered by <strong>Forevermems</strong>
            </p>{" "}
            |{" "}
            <span>
              <a href="/terms-and-conditions">Terms</a>{" "}
              <a href="/privacy-policy">Privacy</a>
            </span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CheckoutForm;
