import axios from "axios";
export const authService = {
  login,
  register,
  loginRegisterViaGoogle,
  sendForgotPasswordMail,
  resetPassword,
  verifyUsername,
  ownerEmailVerification,
  ownerChangeEmail,
  resendEmailVerification,
  checkAuthTokenIsVaild,
  verifyEmailAddress,
  commonLogin,
  authCheck,
  checkOwnerEmailVerificationStatus,
  commonSignIn,
};

async function checkAuthTokenIsVaild(token) {
  return await axios.post(`${process.env.REACT_APP_API_URL}/check-auth`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

async function register(data) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/owner-signup`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
async function login(data) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/owner-signin`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

async function loginRegisterViaGoogle(data) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/login-with-google`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
async function sendForgotPasswordMail(data) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/forget-passoword-link`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

async function resetPassword(data, token) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/forget-passoword`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
async function verifyUsername(data) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/verify-username`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
async function ownerEmailVerification(token) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/email-verification`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
async function ownerChangeEmail(id, data) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/change-email/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
async function resendEmailVerification(data) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/resend-email-verification`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
async function verifyEmailAddress(data) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/verify-Email`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

async function commonLogin(data) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/common-signin`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

async function authCheck(token) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/check-auth`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
async function checkOwnerEmailVerificationStatus(token) {
  return await axios.get(`${process.env.REACT_APP_API_URL}/check-owner-email`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

// FOR OWNER / VIEWER /EXECUTOR : LOGIN 
async function commonSignIn(clientdata) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/signup`,
    clientdata,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
