import React from "react";
import Header from "../Header";

const SiteLayout = ({ children }) => {
  return (
    <div>
      <Header />
      <main className="py-3">{children}</main>
    </div>
  );
};

export default SiteLayout;
