import axios from "axios";
export const dashboardService = {
  loggedInDashboardUser,
  findUserRoles,
};
async function loggedInDashboardUser(dashboardData) {
  console.log(dashboardData, "dashboardData");
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/get-all-user`,
    dashboardData,
    {
      headers: {
        //   Authorization: `Bearer ${token}`,
      },
    }
  );
}
async function findUserRoles(data) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/check-roles-from-email`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
