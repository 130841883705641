import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  _id: "",
  userName: "",
  firstName: "",
  lastName: "",
  email: "",
  mobileNumber: "",
  countryCode: "",
  address: "",
  fileName: "",
  isSocialSignup: "",
  socialProvider: "",
  isVerified: "",
  avatar: "",
  verificationToken: "",
  parentOwner: "",
  dateOfDeath: "",
  isDeathVerified: "",
  billingCessationDate: "",
  isBillingCeased: "",
  isAccountOnHold: "",
  role: "",
  isProfileCompleted: "",
  deathDateAddedOn: "",
  isTnc: "",
  stripeCusID: "",
  token: "",
  existingFreePlan:""
};

const ownerSlice = createSlice({
  name: "owner",
  initialState: initialState,
  reducers: {
    ownerLoginAuth: (state, action) => {
      state._id = action?.payload?._id;
      state.userName = action?.payload?.userName;
      state.firstName = action?.payload?.firstName;
      state.lastName = action?.payload?.lastName;
      state.email = action?.payload?.email;
      state.mobileNumber = action?.payload?.mobileNumber;
      state.countryCode = action?.payload?.countryCode;

      state.address = action?.payload?.address;
      state.fileName = action?.payload?.fileName;
      state.isSocialSignup = action?.payload?.isSocialSignup;
      state.socialProvider = action?.payload?.socialProvider;
      state.isVerified = action?.payload?.isVerified;
      state.verificationToken = action?.payload?.verificationToken;
      state.parentOwner = action?.payload?.parentOwner;
      state.dateOfDeath = action?.payload?.dateOfDeath;
      state.isDeathVerified = action?.payload?.isDeathVerified;
      state.billingCessationDate = action?.payload?.billingCessationDate;
      state.isBillingCeased = action?.payload?.isBillingCeased;
      state.isAccountOnHold = action?.payload?.isAccountOnHold;
      state.role = action?.payload?.role;
      state.isProfileCompleted = action?.payload?.isProfileCompleted;
      state.deathDateAddedOn = action?.payload?.deathDateAddedOn;
      state.isTnc = action?.payload?.isTnc;
      state.stripeCusID = action?.payload?.stripeCusID;
      state.token = action?.payload?.token;
      state.avatar = action?.payload?.avatar;
      state.existingFreePlan = action?.payload?.existingFreePlan;
    },
    ownerLogoutAuth: (state, action) => {
      state._id = "";
      state.userName = "";
      state.firstName = "";
      state.lastName = "";
      state.email = "";
      state.mobileNumber = "";
      state.countryCode = "";
      state.address = "";
      state.fileName = "";
      state.isSocialSignup = "";
      state.socialProvider = "";
      state.isVerified = "";
      state.parentOwner = "";
      state.dateOfDeath = "";
      state.isDeathVerified = "";
      state.billingCessationDate = "";
      state.isBillingCeased = "";
      state.isAccountOnHold = "";
      state.role = "";
      state.isProfileCompleted = "";
      state.deathDateAddedOn = "";
      state.isTnc = "";
      state.stripeCusID = "";
      state.token = "";
      state.avatar = "";
      state.existingFreePlan=""
    },
    ownerAvatar: (state, action) => {
      state.avatar = action?.payload?.avatar;
      state.fileName = action?.payload?.fileName;
    },

    ownerUserName: (state, action) => {
      state.userName = action?.payload?.userName;
    },
    ownerUserEmail: (state, action) => {
      state.email = action?.payload?.email;
    },
    ownerPasswordUpdatedtime: (state, action) => {
      state.updatedPasswordTimeStamp =
        action?.payload?.updatedPasswordTimeStamp;
    },
    ownerUpdateValue: (state, action) => {
      state.firstName = action?.payload?.firstName;
      state.lastName = action?.payload?.lastName;
      state.avatar = action?.payload?.avatar;
    },
    ownerAvatarUpdate: (state, action) => {
      state.avatar = action?.payload?.avatar;
    },
    ownerProfileComplete: (state, action) => {
      state.isProfileCompleted = action?.payload?.isProfileCompleted;
    },
    ownerContactDetails: (state, action) => {
      state.countryCode = action?.payload?.countryCode;
      state.mobileNumber = action?.payload?.mobileNumber;
    },
    ownerEmailVerificationStatus: (state, action) => {
      state.isVerified = action?.payload?.isVerified;
    },
  },
});

export default ownerSlice.reducer;
export const {
  ownerUpdateValue,
  ownerAvatarUpdate,
  ownerLoginAuth,
  ownerLogoutAuth,
  ownerAvatar,
  ownerUserName,
  ownerUserEmail,
  ownerPasswordUpdatedtime,
  ownerProfileComplete,
  ownerContactDetails,
  ownerEmailVerificationStatus,
} = ownerSlice.actions;

export const getOwnerAuth = (state) => state.ownerAuth;
