import { useEffect, useState } from "react";
import DashboardHeading from "../../../components/DashboardHeading";
import ChangeView from "../../../components/ChangeView";
import MemoryView from "../../../components/MemoryView";
import { folderService, videoService, viewerService } from "../../../_services";
import { useSelector } from "react-redux";
import {
  getFolderData,
  getFolderDataForAlbum,
} from "../../../store/slices/folderSlice";
import { useAuth } from "../../../_hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import PreviewMedia from "../../../components/PreviewMedia";

const Viewermemories = () => {
  const user = useAuth("user");
  const navigate = useNavigate();
  const [isGridViewActive, setIsGridViewActive] = useState(false);
  const [viewerFilesData, setViewerFilesData] = useState([]);
  const [showMedia, setShowMedia] = useState(false);
  const fileDataViewer = useSelector(getFolderDataForAlbum);
  const [previewElements, setPreviewElements] = useState({});
  const handleCloseMedia = () => setShowMedia(false);
  const handleShowMedia = () => setShowMedia(true);
  const [videoUrl, setVidoeUrl] = useState();
  const [nonImageAlbums, setNonImageAlbums] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (fileDataViewer?._id) {
      getViewerdata(fileDataViewer?._id);
    }
  }, [fileDataViewer]);

  const getViewerdata = (folderId) => {
    setIsLoading(true);
    viewerService
      .getViewerFiles(folderId)
      .then((response) => {
        setViewerFilesData(response?.data?.data);
        console.log(response?.data, "FolderResponse");
      })
      .catch((error) => {
        console.error("Error fetching viewer data:", error);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (nonImageAlbums.length > 0) {
      handleUpdateAlbumThumbnailUrls(nonImageAlbums);
    }
  }, [nonImageAlbums]);

  useEffect(() => {
    const checkThumbnailUrl = async (album) => {
      if(album?.fileType !="document"){
      if (album?.thumbnailUrl) {
        try {
          await loadImage(album.thumbnailUrl);
          console.log("Thumbnail URL is an image:", album.thumbnailUrl);
        } catch (error) {
          console.log("Thumbnail URL is not an image:", album.thumbnailUrl);
          if (
            !nonImageAlbums.find(
              (nonImageAlbum) => nonImageAlbum._id === album._id
            )
          ) {
            setNonImageAlbums((prevAlbums) => [...prevAlbums, album]);
          }
        }
      }
      if (album?.thumnailUrl) {
        try {
          await loadImage(album.thumnailUrl);
          console.log("Thumbnail URL is an image:", album.thumnailUrl);
        } catch (error) {
          console.log("Thumbnail URL is not an image:", album.thumnailUrl);
          if (
            !nonImageAlbums.find(
              (nonImageAlbum) => nonImageAlbum._id === album._id
            )
          ) {
            setNonImageAlbums((prevAlbums) => [...prevAlbums, album]);
          }
        }
      }}
    };

    viewerFilesData.forEach((album) => checkThumbnailUrl(album));
  }, [viewerFilesData]);

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => reject();
      img.src = url;
    });
  };

  const handleUpdateAlbumThumbnailUrls = async (fileDataArray) => {

    const updatePromises = fileDataArray.map((album) => {
      let type = album.fileType || "";

      const ownerAlbumUrlData = {
        fileName: album.thumbnailFilename,
        _id: album._id,
        path: album?.path || "",
      };

      console.log(type, album, ownerAlbumUrlData);

      switch (type) {
        case "video":
          const videoAlbumUrlData = {
            fileName: album.fileName,
            _id: album._id,
            path: album?.folderPath || "",
          };
          return videoService.updateVideoThumbNailUrl(videoAlbumUrlData);

        case "files":
          return folderService.updateFileUrl(ownerAlbumUrlData);

        default:
          return folderService.updateFolderThumbNailUrl(ownerAlbumUrlData);
      }
    });

    try {
      await Promise.all(updatePromises);
      getViewerdata(fileDataViewer?._id);
    } catch (error) {
      console.log("Network ERROR", error);
    }
  };

  useEffect(() => {
    if (previewElements.fileType == "video") {
      handleUpdateSignedUrl(previewElements);
    }
  }, [previewElements]);
  const handleUpdateSignedUrl = (folderData) => {
    const folderImgData = {
      fileName: folderData?.fileName,
      _id: folderData?._id,
      path: folderData?.folderPath,
    };

    videoService
      .getSignedUrlForVideo(folderImgData)
      .then((response) => {
        if (response?.data?.status === true) {
          console.log("Url updated");
          setVidoeUrl(response?.data?.data.video);
        }
      })
      .catch((error) => {
        console.log("Network ERROR", error);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pb-3 border-bottom mb-3">
        <h4 className="dashboard-heading">
          {/* Memories{" "} */}
          <b
            className="fw-bold"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/${user?.role}/albums`);
            }}
          >
            My Albums{" "}
          </b>
          <b>
            <i className="ri-arrow-right-s-line"></i>
          </b>{" "}
          <span className="text-capitalize">{fileDataViewer?.folderName}</span>
        </h4>
        <ChangeView
          isGridViewActive={isGridViewActive}
          setIsGridViewActive={setIsGridViewActive}
        />
      </div>
      {/* <Row>
        <Col xl={6}>
          <div className="p-3 memory-view-container">
            <p className="mb-2">“Dearest Sarah,</p>
            <p className="mb-2">
              As you explore this album, I want you to feel the warmth of our
              shared moments. Each memory holds a piece of our journey together.
              May it bring a smile to your face, comfort to your heart, and a
              reminder of the love that will forever bind us. You are my
              greatest treasure, and these memories are my gift to you.
            </p>
            <p>With all my love, Sam ” </p>
          </div>
        </Col>
      </Row> */}
      <Row>
        <Col xl={12}>
          <MemoryView
            memories={viewerFilesData}
            isGridViewActive={isGridViewActive}
            handleShowMedia={handleShowMedia}
            setPreviewElements={setPreviewElements}
            isLoading={isLoading}
          />
        </Col>
      </Row>
      {showMedia && (
        <PreviewMedia
          showMedia={showMedia}
          setShowMedia={setShowMedia}
          handleCloseMedia={handleCloseMedia}
          handleShowMedia={handleShowMedia}
          previewElements={previewElements}
          videoUrl={videoUrl}
        />
      )}
    </>
  );
};

export default Viewermemories;
