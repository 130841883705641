/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import BackButton from "../../components/BackButton";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { loadStripe } from "@stripe/stripe-js";
import { planServices } from "../../_services";
import { useAuth } from "../../_hooks/useAuth";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  getPayAsYouGoData,
  payAsYouGoData,
} from "../../store/slices/payAsYouGoSlice";
import { setCheckoutPlanData } from "../../store/slices/planSlice";

const Estimator = () => {
  const user = useAuth("user");
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    videoQty: 0,
    videoLen: 0,
    mp3Qty: "",
    mp3Len: "",
    documentQty: "",
    documentSize: "",
    imgSize: "",
    imgQty: "",
    topUpPrice: "",
  });
  const [totalStorage, setTotalStorageValue] = useState(0);
  const [videoStorage, setVideoStorage] = useState(0);
  const [audioStorage, setAudioStorage] = useState(0);
  const [docStorage, setDocStorage] = useState(0);
  const [imgStorage, setImgStorage] = useState(0);
  const [monthlyEstimate, setMonthlyEstimate] = useState(0);
  const [topupCost, setTopupCost] = useState(0);
  const [nextPaymentDate, setNextPaymentDate] = useState();
  const [customStorageCapacity, setCustomStorageCapacity] = useState(0);

  const yearlyEstimate = (1 * 0.25 * 12) / 365;
  const currentDate = new Date();
  const [loading, setLoading] = useState(false);
  const plan = JSON.parse(localStorage.getItem("plans")) || null;
  useEffect(() => {
    if (plan === null) {
      navigate("/owner/plans");
    }
  }, []);

  useEffect(() => {
    setVideoStorage(formData.videoQty * formData.videoLen * 60);
    setAudioStorage((formData.mp3Len * formData.mp3Qty * 61.4) / 9);
    setImgStorage(formData.imgQty * formData.imgSize);
    setDocStorage(formData.documentQty * formData.documentSize);
  }, [formData]);
  useEffect(() => {
    setUpdateStorage();
  }, [imgStorage, docStorage, audioStorage, videoStorage]);

  // useEffect(() => {
  //   setMonthlyEstimate((totalStorage / 1000) * yearlyEstimate * 31);
  //   setTopupCost(formData.topUpPrice / monthlyEstimate);
  //   const monthly = (totalStorage / 1000) * yearlyEstimate * 31;
  //   if (formData.topUpPrice && totalStorage) {
  //     const PaymentDate = new Date(
  //       currentDate.setMonth(
  //         currentDate.getMonth() + formData.topUpPrice / monthly
  //       )
  //     );
  //     const formattedNextPaymentDate = PaymentDate.toLocaleDateString("en-US", {
  //       month: "2-digit",
  //       day: "2-digit",
  //       year: "numeric",
  //     });
  //     setNextPaymentDate(formattedNextPaymentDate);
  //   } else {
  //     setMonthlyEstimate(0);
  //     setNextPaymentDate("");
  //   }
  // }, [totalStorage, topupCost, formData.topUpPrice]);
  useEffect(() => {
    const price = parseFloat(formData.topUpPrice) || 0;
    setMonthlyEstimate(price);
    if (price > 0) {
      // Calculate monthly based on totalStorage and yearlyEstimate
      const monthly = (totalStorage / 1000) * yearlyEstimate * 31;
      const deposit = formData?.topUpPrice;
      const pricePerGB = 0.25;
      const bytesPerGB = 1073741824;
      const affordableStorageGB = deposit / pricePerGB;
      const totalDataStorageBytes = affordableStorageGB * bytesPerGB;
      // Calculate the total storage value in GB
      const totalDataStorageGB = totalDataStorageBytes / bytesPerGB;
      console.log("Total Storage in Bytes:", totalDataStorageBytes);
      console.log("Total Storage in GB:", totalDataStorageGB);
      setTotalStorageValue(totalDataStorageGB);
      if (monthly > 0) {
        const monthsToAdd = Math.ceil(price / monthly);
        console.log("monthsToAdd:", monthsToAdd);
        const currentDateCopy = new Date();
        // Set the next payment date by adding months
        currentDateCopy.setMonth(currentDateCopy.getMonth() + monthsToAdd);
        // Format the next payment date
        const formattedNextPaymentDate = currentDateCopy.toLocaleDateString(
          "en-US",
          {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }
        );

        setNextPaymentDate(formattedNextPaymentDate);
      } else {
        setNextPaymentDate("");
      }
    } else {
      setMonthlyEstimate(0);
      setTotalStorageValue(0);
      setNextPaymentDate("");
    }
  }, [formData.topUpPrice, totalStorage, yearlyEstimate]);

  const setUpdateStorage = () => {
    if (videoStorage || audioStorage || docStorage || imgStorage) {
      setCustomStorageCapacity(
        parseInt(videoStorage) +
          parseInt(audioStorage) +
          parseInt(docStorage) +
          parseInt(imgStorage)
      );
    }
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleCheckout = async () => {
    setLoading(true);
    handleStorePayAsYouData(
      formData,
      imgStorage,
      docStorage,
      audioStorage,
      videoStorage
      // topUpPrice
    );
    if (
      // monthlyEstimate &&
      formData?.topUpPrice >= 5 &&
      nextPaymentDate &&
      plan
    ) {
      const checkoutPlan = {
        name: plan?.name,
        discountPrice: formData?.topUpPrice,
        planType: plan.planType,
        planTenure: plan.planTenure,
      };

      const res = await planServices.checkOutSession(
        { plans: checkoutPlan },
        user?.token
      );

      console.log(res);
      localStorage.setItem(
        "checkout",
        JSON.stringify({
          sessionId: res?.data?.data?.session,
          planId: plan?._id,
          nextPaymentDate: nextPaymentDate,
          monthlyEstimate: monthlyEstimate.toFixed(2),
          customer: res?.data?.data?.customer,
          planTenure: plan.planTenure,
        })
      );
      setLoading(false);

      setTimeout(() => {
        window.location.href = `/checkout`;
      }, 2000);
    } else {
      console.log("Error In processing checkout");
      setLoading(false);
    }
    setLoading(false);
  };
  const handleStorePayAsYouData = async (
    formData,
    imgStorage,
    docStorage,
    audioStorage,
    videoStorage
  ) => {
    const payAsYouGoDataPayload = {
      video: {
        quantity: formData?.videoQty ? formData?.videoQty : "",
        length: formData?.videoLen ? formData?.videoLen : "",
        storageSize: videoStorage ? videoStorage : "",
      },
      mp3: {
        quantity: formData?.mp3Qty ? formData?.mp3Qty : "",
        length: formData?.mp3Len ? formData?.mp3Len : "",
        storageSize: audioStorage ? audioStorage : "",
      },
      document: {
        quantity: formData?.documentQty ? formData?.documentQty : "",
        length: formData?.documentSize ? formData?.documentSize : "",
        storageSize: docStorage ? docStorage : "",
      },
      image: {
        quantity: formData?.imgQty ? formData?.imgQty : "",
        length: formData?.imgSize ? formData?.imgSize : "",
        storageSize: imgStorage ? imgStorage : "",
      },
      depositAmount: formData?.topUpPrice ? formData?.topUpPrice : "",
      dataStorageTotal: totalStorage ? parseFloat(totalStorage).toFixed(2) : "",
      price: monthlyEstimate ? monthlyEstimate : "",
    };
    const payAsYou = {
      video: {
        quantity: formData?.videoQty ? formData?.videoQty : "",
        length: formData?.videoLen ? formData?.videoLen : "",
        storageSize: videoStorage ? videoStorage : "",
      },
      mp3: {
        quantity: formData?.mp3Qty ? formData?.mp3Qty : "",
        length: formData?.mp3Len ? formData?.mp3Len : "",
        storageSize: audioStorage ? audioStorage : "",
      },
      document: {
        quantity: formData?.documentQty ? formData?.documentQty : "",
        length: formData?.documentSize ? formData?.documentSize : "",
        storageSize: docStorage ? docStorage : "",
      },
      image: {
        quantity: formData?.imgQty ? formData?.imgQty : "",
        length: formData?.imgSize ? formData?.imgSize : "",
        storageSize: imgStorage ? imgStorage : "",
      },
      depositAmount: formData?.topUpPrice ? formData?.topUpPrice : "",
      dataStorageTotal: totalStorage ? parseFloat(totalStorage).toFixed(2) : "",
      price: monthlyEstimate ? monthlyEstimate : "",
      selectedPlanType: "PayAsYouGo",
    };
    // Dispatch the action with the payload
    dispatch(payAsYouGoData(payAsYouGoDataPayload));
    dispatch(setCheckoutPlanData(payAsYou));
  };

  return (
    <Container className="py-xl-5 py-2">
      <BackButton />
      <Row className="justify-content-center text-center">
        <Col xl={8} lg={8} md={8}>
          <h4 className="login-forevermems mb-2">Estimate Your Costs</h4>
          <p>
            Plan your budget with the Pay As You Go Estimator.
            <br /> Input your media details, hit 'Calculate Estimate,' and get
            insights into your monthly cost.
            <br /> Take control effortlessly!
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center text-center mt-4">
        <Col xl={8} lg={8} md={8}>
          <Row className="px-3">
            <Form.Group as={Col} md="4" className="estimator__form__input">
              <Form.Label>Deposit Amount</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Enter the amount"
                min={5}
                onChange={(e) => {
                  setFormData({ ...formData, topUpPrice: e.target.value });
                }}
              />
            </Form.Group>
            <Form.Group as={Col} md="8" className="estimator__form__input">
              <Form.Label>Data Storage total</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Total Storage"
                value={
                  totalStorage
                    ? `${parseFloat(totalStorage).toFixed(2)} GB`
                    : ""
                }
                disabled
                readOnly
              />
            </Form.Group>
          </Row>
          <hr />
          {/* <span className="personalize_your_plan" onClick={toggleVisibility}>
            <i className="ri-equalizer-2-fill"></i> Personalize your plan
            <i className={`ri-arrow-${isVisible ? "up" : "down"}-s-fill`}></i>
          </span> */}
          <Row>
            <Col md={5} className="pt-4">
              <div>
                <span
                  className="personalize_your_plan"
                  onClick={toggleVisibility}
                >
                  <i className="ri-equalizer-2-fill"></i> Personalize your plan
                  <i
                    className={`ri-arrow-${isVisible ? "up" : "down"}-s-fill`}
                  ></i>
                </span>
              </div>
            </Col>
            <Col md={7} className="text-end pb-4">
              <div>
                <Form.Group as={Col} md="8" className="estimator__form__input">
                  <Form.Label>Custom Storage Capacity</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Total Storage"
                    value={parseFloat(customStorageCapacity).toFixed(2)}
                    disabled
                    readOnly
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
          {isVisible && (
            <div>
              {isVisible && (
                <div>
                  <Row className="px-3 mb-3">
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Video Quantity</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="2"
                        min={0}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            videoQty: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Video Length</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="50 Min"
                        min={0}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            videoLen: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Video Storage Size</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="500 MB"
                        value={videoStorage?.toFixed(2)}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Row>
                  <Row className="px-3 mb-3">
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>MP3 Quantity</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="2"
                        min={0}
                        onChange={(e) => {
                          setFormData({ ...formData, mp3Qty: e.target.value });
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>MP3 Length</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="10 Min"
                        min={0}
                        onChange={(e) => {
                          setFormData({ ...formData, mp3Len: e.target.value });
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Mp3 Storage Size</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="50 MB"
                        value={audioStorage?.toFixed(2)}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Row>
                  <Row className="px-3 mb-3">
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Document Quantity</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="1"
                        min={0}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            documentQty: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Document Size (MB)</Form.Label>
                      <Form.Control
                        required
                        min={0}
                        type="number"
                        placeholder="20 MB"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            documentSize: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Docs Storage total</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="200 MB"
                        value={docStorage?.toFixed(2)}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Row>
                  <Row className="px-3 mb-3">
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Image Quantity</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        min={0}
                        placeholder="1"
                        onChange={(e) => {
                          setFormData({ ...formData, imgQty: e.target.value });
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Image Size (MB)</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        min={0}
                        placeholder="20 MB"
                        onChange={(e) => {
                          setFormData({ ...formData, imgSize: e.target.value });
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Image Storage total</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="100 MB"
                        value={imgStorage?.toFixed(2)}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Row>
                </div>
              )}
            </div>
          )}
        </Col>
        <Col xl={4} lg={4} md={4}>
          <Card className="py-5 px-2 result__card">
            <div className="card__header mb-5">
              <h4>
                <b>Estimation Results</b>
              </h4>
              <p>Monthly Cost Estimate</p>
              {/* {monthlyEstimate.toFixed(2) >= 5 ? (
                <h5>
                  <b>$ {monthlyEstimate.toFixed(2)}</b>
                </h5>
              ) : (
                <h5>
                  <b className="text-danger">
                    The amount entered is $ {monthlyEstimate.toFixed(2)}, it  must be least $5 or more..
                  </b>
                </h5>
              )} */}
              {monthlyEstimate >= 5 ? (
                <h5>
                  <b>$ {monthlyEstimate.toFixed(2)}</b>
                </h5>
              ) : (
                monthlyEstimate > 0 && (
                  <div className="alert alert-warning mt-3" role="alert">
                    <b>
                      The amount entered is $ {monthlyEstimate.toFixed(2)}. The
                      minimum amount allowed is $5. Please enter at least $5 to
                      proceed.
                    </b>
                  </div>
                )
              )}
            </div>
            <div className="card__body">
              <p>Next Billing Estimate</p>
              <h5>
                <b>{nextPaymentDate ? nextPaymentDate : "MM/DD/YYYY"}</b>
              </h5>
              <h5 className="mt-5">
                <b>
                  Ready to Dive In? <br />
                  Hit 'Confirm' to Activate!
                </b>
              </h5>
              <Form.Check
                className="gift__checkbox"
                type="checkbox"
                label="Gift Foervermems to your loved ones"
              />
            </div>
            <div className="card__footer mt-4">
              {/* <Button
                className="w-50"
                variant="primary"
                onClick={handleCheckout}
              >
                Confirm
              </Button> */}
              {/* <Button
                className="w-50"
                variant="primary"
                onClick={handleCheckout}
                disabled={parseFloat(monthlyEstimate.toFixed(2)) < 5} // Disable if monthlyEstimate < 5
              >
                {loading ? `Loading....` : `Confirm`}
              </Button> */}
              <Button
                className="w-50"
                variant="primary"
                onClick={handleCheckout}
                disabled={parseFloat(monthlyEstimate.toFixed(2)) < 5 || loading}
              >
                {loading ? `Loading....` : `Confirm`}
              </Button>

              <small className="d-block text-muted mt-3">Not Now</small>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Estimator;
