import { Outlet, Navigate, useLocation } from "react-router-dom";
import useAuthToken from "../_hooks/useAuthToken";
import AuthLayout from "../components/Layouts/AuthLayout";
import SiteLayout from "../components/Layouts/SiteLayout";
import Spinner from "react-bootstrap/Spinner";
import { useAuth } from "../_hooks/useAuth";
import React from "react";
import { EXECUTOR, OWNER, VIEWER } from "../_helpers";

const ProtectedWithSiteLayout = () => {
  const token = useAuthToken();
  const { hash, pathname, search } = useLocation();
  const isAuthenticated = token;
  const user = useAuth("user");
  const role = useAuth("role");
  console.log(pathname, "pathname");

  if (!isAuthenticated && pathname === "/owner/plans") {
    const fullUrl = `${pathname}${search}${hash}`;
    localStorage.setItem("buyPlan", fullUrl);
    return <Navigate to="/sign-up" replace />;
  }

  return isAuthenticated ? (
    <SiteLayout>
      <React.Suspense
        fallback={
          <div className="loading-loader-section">
            <Spinner animation="border" variant="primary" />
          </div>
        }
      >
        <Outlet />
      </React.Suspense>
    </SiteLayout>
  ) : (
    <Navigate to="/sign-as" replace />
  );
};

export default ProtectedWithSiteLayout;
