import React from "react";
import { Row, Col } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
export default function SkeletonLoader() {
  return (
    <>
      <Row>
        <Col xl={3} md={3} sm={3}>
          <Skeleton
            containerClassName="flex-1"
            height={120}
            width="100%"
            count={1}
            highlightColor="#444"
          />
        </Col>
        <Col xl={3} md={3} sm={3}>
          <Skeleton
            containerClassName="flex-1"
            height={120}
            width="100%"
            count={1}
            highlightColor="#444"
          />
        </Col>
        <Col xl={3} md={3} sm={3}>
          <Skeleton
            containerClassName="flex-1"
            height={120}
            width="100%"
            count={1}
            highlightColor="#444"
          />
        </Col>
        <Col xl={3} md={3} sm={3}>
          <Skeleton
            containerClassName="flex-1"
            height={120}
            width="100%"
            count={1}
            highlightColor="#444"
          />
        </Col>
      </Row>
      <Row>
        
        <Col xl={3} md={3} sm={3}>
          <Skeleton
            containerClassName="flex-1"
            height={200}
            width="100%"
            count={1}
            highlightColor="#444"
          />
        </Col>
        <Col xl={3} md={3} sm={3}>
          <Skeleton
            containerClassName="flex-1"
            height={200}
            width="100%"
            count={1}
            highlightColor="#444"
          />
        </Col>
        <Col xl={3} md={3} sm={3}>
          <Skeleton
            containerClassName="flex-1"
            height={200}
            width="100%"
            count={1}
            highlightColor="#444"
          />
        </Col>
        <Col xl={3} md={3} sm={3}>
          <Skeleton
            containerClassName="flex-1"
            height={200}
            width="100%"
            count={1}
            highlightColor="#444"
          />
        </Col>
      </Row>
      <Row>
        
        <Col xl={3} md={3} sm={3}>
          <Skeleton
            containerClassName="flex-1"
            height={200}
            width="100%"
            count={1}
            highlightColor="#444"
          />
        </Col>
        <Col xl={3} md={3} sm={3}>
          <Skeleton
            containerClassName="flex-1"
            height={200}
            width="100%"
            count={1}
            highlightColor="#444"
          />
        </Col>
        <Col xl={3} md={3} sm={3}>
          <Skeleton
            containerClassName="flex-1"
            height={200}
            width="100%"
            count={1}
            highlightColor="#444"
          />
        </Col>
        <Col xl={3} md={3} sm={3}>
          <Skeleton
            containerClassName="flex-1"
            height={200}
            width="100%"
            count={1}
            highlightColor="#444"
          />
        </Col>
      </Row>
    </>
  );
}
