import React from "react";

const DashboardHeading = (props) => {
  return (
    <>
      <h4 className="dashboard-heading">{props.heading}</h4>
    </>
  );
};

export default DashboardHeading;
