import React from "react";
import { Dropdown } from "react-bootstrap";

const DownLoadOption = () => {
  return (
    <Dropdown className="edit_option-dropdown">
      <Dropdown.Toggle>
        <i className="ri-more-fill text-muted"></i>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <ul className="edit_option_list">
          <li>
            <svg
              width="14"
              height="17"
              viewBox="0 0 14 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.75781 1C1.78952 1 1 1.78952 1 2.75781V14.2422C1 15.2105 1.78952 16 2.75781 16H11.3711C12.3394 16 13.1289 15.2105 13.1289 14.2422V5.62873C13.1289 5.08391 12.9123 4.5611 12.5271 4.17585L9.95306 1.60184C9.5678 1.21659 9.04494 1 8.50011 1H2.75781ZM2.75781 1.64453H8.50011C8.87425 1.64453 9.23274 1.79305 9.4973 2.05761L12.0713 4.63161C12.3359 4.89616 12.4843 5.2546 12.4843 5.62873V14.2422C12.4843 14.8646 11.9935 15.3555 11.3711 15.3555H2.75781C2.13537 15.3555 1.64453 14.8646 1.64453 14.2422V2.75781C1.64453 2.13537 2.13537 1.64453 2.75781 1.64453Z"
                fill="#5C5C5C"
                stroke="#5C5C5C"
                strokeWidth="0.5"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.06523 5.33203C6.97976 5.33203 6.89779 5.36599 6.83736 5.42642C6.77692 5.48686 6.74297 5.56883 6.74297 5.6543L6.74314 10.778L5.14 9.17477C5.11007 9.14485 5.07454 9.12111 5.03544 9.10491C4.99635 9.08872 4.95444 9.08038 4.91212 9.08038C4.8698 9.08038 4.82789 9.08872 4.78879 9.10491C4.74969 9.12111 4.71416 9.14485 4.68424 9.17477C4.65431 9.2047 4.63057 9.24022 4.61438 9.27932C4.59818 9.31842 4.58984 9.36033 4.58984 9.40265C4.58984 9.44497 4.59818 9.48688 4.61438 9.52598C4.63057 9.56508 4.65431 9.60061 4.68424 9.63053L6.47861 11.425C6.80001 11.7464 7.33086 11.7464 7.65226 11.425L9.44664 9.63053C9.47656 9.60061 9.5003 9.56508 9.5165 9.52598C9.53269 9.48688 9.54103 9.44497 9.54103 9.40265C9.54103 9.36033 9.53269 9.31842 9.5165 9.27932C9.5003 9.24022 9.47656 9.2047 9.44664 9.17477C9.41671 9.14485 9.38119 9.12111 9.34209 9.10491C9.30299 9.08872 9.26108 9.08038 9.21876 9.08038C9.17644 9.08038 9.13453 9.08872 9.09543 9.10491C9.05633 9.12111 9.0208 9.14485 8.99088 9.17477L7.38773 10.778L7.3875 5.6543C7.3875 5.56883 7.35354 5.48686 7.29311 5.42642C7.23267 5.36599 7.1507 5.33203 7.06523 5.33203Z"
                fill="#5C5C5C"
                stroke="#5C5C5C"
                strokeWidth="0.5"
              />
            </svg>
            Download
          </li>
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DownLoadOption;
