import { useEffect, useState } from "react";
import { Row, Col, Table } from "react-bootstrap";
import DashboardHeading from "../../../components/DashboardHeading";
import ChangeView from "../../../components/ChangeView";
import { folderService, videoService, viewerService } from "../../../_services";
import { useDispatch, useSelector } from "react-redux";
import { getViewerAuth } from "../../../store/slices/viewerSlice";
import RecentMemoriesFolder from "../../../components/RecentMemories";
import { useNavigate } from "react-router-dom";
import { VIEWER } from "../../../_helpers";
import {
  setCurrentFolder,
  setFolderDataForAlbum,
} from "../../../store/slices/folderSlice";
import RecentViewerAlbum from "../../../components/RecentViewerAlbum";
import SkeletonLoader from "../../../components/SkeletonLoader";
import DataNotFound from "../../../components/DataNotFound";
import { useAuth } from "../../../_hooks/useAuth";
import { getViewerFolder } from "../../../store/slices/viewerFolderSlice";
const Vieweralbum = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useAuth("user");
  const [isGridViewActive, setIsGridViewActive] = useState(false);
  const [viewerAlbumData, setViewerAlbumData] = useState([]);
  const [nonImageAlbums, setNonImageAlbums] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const viewerData = useSelector(getViewerAuth);
  const viewerFolderData = useSelector(getViewerFolder);
  console.log(viewerFolderData, "viewerFolderData", user);
  useEffect(() => {
    if (viewerFolderData?.viewerFolders?.length > 0) {
      const mappedArray = viewerFolderData?.viewerFolders?.filter((item) => {
        // Check if user?._id exists and is not null or undefined
        if (user?._id && item.viewableTo) {
          // Filter out items where any user ID in viewableTo matches user?._id
          return item.viewableTo.some((userObj) => userObj.userId === user._id);
        } else {
          // If user?._id is null or undefined, keep all items
          return true;
        }
      });

      setViewerAlbumData(mappedArray || []);

      // console.log(mappedArray , 'new arr' , user);
    }
  }, [viewerFolderData]);

  // useEffect(() => {
  //   if (viewerData) {
  //     getViewerdata(viewerData?._id, viewerData?.ownerId);
  //   }
  // }, [viewerData]);

  const getViewerdata = (_id, ownerId) => {
    // setIsLoading(true);
    // viewerService
    //   .getViewerPolicy(_id, ownerId)
    //   .then((response) => {
    //     const viewerData = response?.data?.data
    //     if (user.status == 'Unverified') {
    //       const filter = viewerData.filter(
    //         (album) => album.isPublic == true
    //       )
    //       setViewerAlbumData(filter);
    //     } else {
    //       setViewerAlbumData(viewerData);
    //     }
    //     console.log(response?.data, "RESPONSEEEE");
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching viewer data:", error);
    //   })
    //   .finally(() => setIsLoading(false));
  };

  const handleFolderData = (item) => {
    dispatch(setFolderDataForAlbum(item));
    navigate(`/${VIEWER}/memories`);
  };
  useEffect(() => {
    const checkThumbnailUrl = async (album) => {
      if (album?.thumbnailUrl) {
        try {
          await loadImage(album.thumbnailUrl);
          console.log("Thumbnail URL is an image:", album.thumbnailUrl);
        } catch (error) {
          console.log("Thumbnail URL is not an image:", album.thumbnailUrl);
          if (
            !nonImageAlbums.find(
              (nonImageAlbum) => nonImageAlbum._id === album._id
            )
          ) {
            setNonImageAlbums((prevAlbums) => [...prevAlbums, album]);
          }
        }
      }
    };

    viewerAlbumData.forEach((album) => checkThumbnailUrl(album));
  }, [viewerAlbumData]);

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => reject();
      img.src = url;
    });
  };

  useEffect(() => {
    if (nonImageAlbums?.length > 0) {
      handleUpdateAlbumThumbnailUrls(nonImageAlbums);
    }
  }, [nonImageAlbums]);

  const handleUpdateAlbumThumbnailUrls = (fileDataArray) => {
    // Create an array of promises for updating thumbnail URLs
    const updatePromises = fileDataArray.map((album) => {
      const ownerAlbumUrlData = {
        fileName: album?.thumbnailFilename,
        _id: album?._id,
        path: album?.path,
      };
      // Return the promise for updating the thumbnail URL
      return folderService.updateFolderThumbNailUrl(ownerAlbumUrlData);
    });

    // // Use Promise.all to wait for all update requests to complete
    // Promise.all(updatePromises)
    //   .then((responses) => {
    //     getViewerdata(viewerData?._id, viewerData?.ownerId);
    //   })
    //   .catch((error) => {
    //     console.log("Network ERROR", error);
    //   });
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center pb-3 border-bottom mb-3">
        <DashboardHeading heading="Viewer Albums" />
        <ChangeView
          isGridViewActive={isGridViewActive}
          setIsGridViewActive={setIsGridViewActive}
        />
      </div>
      <p>You can easily access the assigned album here.</p>
      {/* <Row className="my-3">
        {isLoading && <SkeletonLoader />}
        {!isLoading && viewerAlbumData.length === 0 && (
          <DataNotFound isAlbum={true} />
        )}
        {viewerAlbumData &&
          viewerAlbumData.map((item, index) => {
            return (
              <RecentViewerAlbum
                keys={index}
                isGridViewActive={isGridViewActive}
                album={item}
                navigateAlbumPage={handleFolderData}
                isLoading={isLoading}
              />
            );
          })}
      </Row> */}
    </>
  );
};

export default Vieweralbum;
