/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import DashboardHeading from "../../../components/DashboardHeading";
import { Button, Row, Col, Card } from "react-bootstrap";
import SubscriptionHistory from "./subscription-history-popup";
import { useNavigate } from "react-router";
import { useAuth } from "../../../_hooks/useAuth";
import { planServices } from "../../../_services";
import moment from "moment";
import Loader from "../../../components/Loader";
import { setPlanData } from "../../../store/slices/planSlice";
import { useDispatch, useSelector } from "react-redux";
import { getViwerAsOwnerData } from "../../../store/slices/viewerFolderSlice";
import { getExecutorAsOwnerData } from "../../../store/slices/executerSlice";
import { EXECUTOR, OWNER, VIEWER } from "../../../_helpers";

const BillingPlans = function () {
  const user = useAuth("user");
  const role = useAuth("role");

  const [show, setShow] = useState(false);
  const [subscriptionHistoryData, setSubscriptionHistoryData] = useState([]);
  const [subscriptionCurrentPlanData, setSubscriptionCurrentPlanData] =
    useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const viewerAsOwnerDataStore = useSelector(getViwerAsOwnerData);
  const executorAsOwnerDataStore = useSelector(getExecutorAsOwnerData);

  useEffect(() => {
    if (user) {
      if (user.role === OWNER) {
        getCurrentPlan(user?._id);
      } else if (user.role === VIEWER && viewerAsOwnerDataStore) {
        getCurrentPlan(viewerAsOwnerDataStore?._id);
      } else if (user.role === EXECUTOR && executorAsOwnerDataStore) {
        getCurrentPlan(executorAsOwnerDataStore?._id);
      }
    }
  }, []);
  const getCurrentPlan = (LoggedInId) => {
    setLoading(true);
    planServices
      .getOwnerCurrentSubscription(LoggedInId, user?.token)
      .then((response) => {
        if (response?.data?.status === true) {
          dispatch(
            setPlanData({
              selectedPlanType: response?.data?.data?.selectedPlanType,
            })
          );
          setSubscriptionCurrentPlanData(response?.data?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching subscription data:", error.message);
        setLoading(false);
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    if (user) {
      getSubscriptionHistory(user?.token);
    }
  };

  const getSubscriptionHistory = (token) => {
    setLoading(true);
    planServices
      .getSubscriptionHistory(token)
      .then((response) => {
        if (response?.data?.status === true) {
          setSubscriptionHistoryData(response?.data?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching subscription data:", error.message);
        setLoading(false);
      });
  };

  const bytesToGB = (bytes) => {
    return bytes / (1024 * 1024 * 1024);
  };

  const storageLimit = parseFloat(
    subscriptionCurrentPlanData?.features?.storageLimit
  );
  const usedSpaceBytes = parseFloat(subscriptionCurrentPlanData?.spaceUsed);
  const usedSpaceGB = bytesToGB(usedSpaceBytes);
  const remainingSpace =
    isNaN(storageLimit) || isNaN(usedSpaceGB)
      ? "Invalid data"
      : storageLimit - usedSpaceGB;

  let formattedRemainingSpace;
  if (typeof remainingSpace === "number") {
    formattedRemainingSpace = remainingSpace.toFixed(2) + " GB";
  } else {
    formattedRemainingSpace = remainingSpace;
  }

  const totalDataUsed = (
    subscriptionCurrentPlanData?.spaceUsed /
    (1024 * 1024 * 1024)
  ).toFixed(2);

  function gbToBytes(gb) {
    const bytesPerGB = 1024 * 1024 * 1024; // 1 GB = 2^30 bytes
    let x = gb * bytesPerGB - subscriptionCurrentPlanData?.spaceUsed;

    // Calculate the number of GB
    const gigabytes = x / bytesPerGB;

    // Calculate the total price
    const totalPrice =
      gigabytes * parseFloat(subscriptionCurrentPlanData?.discountPrice);

    return " $ " + parseFloat(totalPrice).toFixed(2);
  }

  return (
    <>
      <div className="d-flex align-items-center pb-3 border-bottom mb-3">
        <div className="d-flex my-account">
          <div
            className="back-button"
            onClick={() => navigate("/owner/dashboard")}
            style={{ cursor: "pointer" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
            </svg>
          </div>
          <DashboardHeading heading="Billing & Plans" />
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (user.role === OWNER && user?.isProfileCompleted === true) ||
        (user.role !== OWNER && viewerAsOwnerDataStore) ||
        executorAsOwnerDataStore ? (
        <div className="billing-container">
          <Row>
            <Col xl={10} lg={10} md={12} xs={12}>
              <div className="py-4 justify-content-between align-items-center">
                <div className="d-flex align-items-start billingPlans gap-3 my-2">
                  <div className="bill__profile--container d-flex align-items-center">
                    <div className="bill__mobile--content">
                      <h3 className="user-label">Payment Method</h3>
                    </div>
                  </div>
                  <div className="user-info w-50">
                    <h3 className="user-label">
                      {subscriptionCurrentPlanData?.selectedPlanType}
                    </h3>
                    <p className="user-txt">
                      Some info about{" "}
                      {subscriptionCurrentPlanData?.selectedPlanType} Plan{" "}
                    </p>
                    <Card className="mt-2">
                      <Card.Body>
                        <div className="d-flex align-items-start gap-3">
                          <img width={50} src="/billing.png" alt=""></img>
                          <div>
                            <h5>
                              {subscriptionCurrentPlanData?.selectedPlanType}
                            </h5>
                            <small className="text-muted">
                              {subscriptionCurrentPlanData?.selectedPlanType !=
                                "PayAsYouGo" && (
                                <>
                                  Next bill on{" "}
                                  {subscriptionCurrentPlanData?.selectedPlanType !==
                                  "Free"
                                    ? moment(
                                        subscriptionCurrentPlanData?.nextPaymentDate
                                      ).format("MM/DD/YYYY")
                                    : "N/A"}
                                  <br />
                                </>
                              )}
                              Discounted Price{" "}
                              {subscriptionCurrentPlanData?.selectedPlanType !==
                              "Free"
                                ? `$${subscriptionCurrentPlanData?.discountPrice}`
                                : "N/A"}
                              <span>
                                {subscriptionCurrentPlanData?.selectedPlanType ==
                                "PayAsYouGo"
                                  ? ` per gb`
                                  : ""}
                              </span>
                            </small>
                          </div>
                        </div>
                        <div className="p-3 border-top mt-3 text-muted">
                          <p>
                            Data storage:{" "}
                            <b>
                              {
                                subscriptionCurrentPlanData?.features
                                  ?.storageLimit
                              }
                            </b>
                          </p>
                          {subscriptionCurrentPlanData?.selectedPlanType !==
                            "PayAsYouGo" && (
                            <p>
                              Total data storage Available:{" "}
                              <b>
                                {subscriptionCurrentPlanData?.selectedPlanType !==
                                "PayAsYouGo"
                                  ? formattedRemainingSpace
                                  : "N/A"}
                              </b>
                            </p>
                          )}
                          <p>
                            Total data storage Used:{" "}
                            <b>{totalDataUsed + " GB"}</b>
                          </p>
                        </div>
                        {subscriptionCurrentPlanData?.selectedPlanType ==
                          "PayAsYouGo" && (
                          <>
                            <div className="p-3 border-top  text-muted">
                              <small className="text-muted">
                                {" "}
                                Current Account Balance Calculated On Daily
                                Basis
                                <b className="ml-3">
                                  {gbToBytes(
                                    parseFloat(
                                      subscriptionCurrentPlanData?.monthlyEstimate
                                    ) /
                                      subscriptionCurrentPlanData?.discountPrice
                                  )}
                                </b>
                              </small>{" "}
                              <small className="text-muted">
                                <br />
                                {subscriptionCurrentPlanData?.selectedPlanType !=
                                  "PayAsYouGo" && (
                                  <>
                                    Next Bill On
                                    <span className="ml-3">
                                      <b>
                                        {""}{" "}
                                        {moment(
                                          subscriptionCurrentPlanData?.nextPaymentDate
                                        ).format("MM/DD/YYYY")}
                                      </b>
                                    </span>
                                  </>
                                )}
                              </small>
                              <small className="text-muted">
                                <br />
                                Estimated Top Up Amount Based on One Month
                                <span>
                                  <b>
                                    {" "}
                                    $
                                    {
                                      subscriptionCurrentPlanData?.monthlyEstimate
                                    }
                                  </b>
                                </span>
                              </small>
                            </div>

                            {subscriptionCurrentPlanData?.expired && (
                              <div className="mt-3 text-end">
                                <Button
                                  className="payment-button"
                                  variant="primary-outline"
                                  onClick={() => {
                                    navigate(`/${role}/plans`);
                                  }}
                                >
                                  Renew{" "}
                                </Button>
                              </div>
                            )}
                          </>
                        )}
                      </Card.Body>
                    </Card>
                    {user.role === OWNER && (
                      <p className="text-end" role="button">
                        <small className="text-primary" onClick={handleShow}>
                          See order history
                        </small>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        "Payment is still not made..."
      )}
      {show && (
        <div className="subcription-popup">
          <SubscriptionHistory
            loading={loading}
            show={show}
            setShow={setShow}
            handleClose={handleClose}
            handleShow={handleShow}
            subscriptionHistoryData={subscriptionHistoryData}
          />
        </div>
      )}
    </>
  );
};

export default BillingPlans;
