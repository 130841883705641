import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Loader from "../Loader";
import { useAuth } from "../../_hooks/useAuth";
import toast from "react-hot-toast";

const RenameAlert = ({
  album,
  isAlbum = true,
  albumName,
  handleRenameValue,
}) => {
  // if (isActionLoading) return <Loader />;
  const user = useAuth("user");

  return (
    <span
      onClick={() => {
        if ( user?.isProfileCompleted == true) {
        Swal.fire({
          title: `Rename Your ${isAlbum ? "Album" : "Memories"}`,
          html: `<input type="text" id="newName" className="swal2-input" placeholder="New Name" value="${albumName}" />`,
          showCancelButton: true,
          confirmButtonText: "Rename",
          cancelButtonText: "Cancel",
          preConfirm: () => {
            const inputValue = document.getElementById("newName").value;
            if (!inputValue.trim()) {
              Swal.showValidationMessage("New name is required.");
            }
            return inputValue;
          },
        }).then((result) => {
          if (result.isConfirmed) {
            handleRenameValue(result.value, album);
          }
        });
      }
        else{
          toast.error("Upgrade Required: Unlock this feature by completing your payment. Questions? Contact support.");
        }
      }}
    >
      <i className="ri-edit-line"></i> Rename
    </span>
  );
};
export default RenameAlert;
