import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  planData: {},
  checkoutPlanData: {},
};

const planSlice = createSlice({
  name: "plans",
  initialState: initialState,
  reducers: {
    setPlanData: (state, action) => {
      state.planData = {
        ...state.planData,
        ...action.payload,
      };
    },
    removePlanData: (state, action) => {
      state.planData = {}; // Resetting planData to an empty object
    },
    setCheckoutPlanData: (state, action) => {
      state.checkoutPlanData = action.payload;
    },
    removeCheckoutPlanData: (state, action) => {
      state.checkoutPlanData = {}; // Resetting planData to an empty object
    },
  },
});

export default planSlice.reducer;
export const {
  setPlanData,
  removePlanData,
  setCheckoutPlanData,
  removeCheckoutPlanData,
} = planSlice.actions;

export const selectPlanData = (state) => state.plans.planData;
export const selectedCheckoutPlanData = (state) => state.plans.checkoutPlanData;
