/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import BackButton from "../../components/BackButton";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { loadStripe } from "@stripe/stripe-js";
import { planServices } from "../../_services";
import { useAuth } from "../../_hooks/useAuth";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  getPayAsYouGoData,
  payAsYouGoData,
} from "../../store/slices/payAsYouGoSlice";
import { setCheckoutPlanData } from "../../store/slices/planSlice";

const Estimator = () => {
  const user = useAuth("user");
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    videoQty: 0,
    videoLen: 0,
    mp3Qty: "",
    mp3Len: "",
    documentQty: "",
    documentSize: "",
    imgSize: "",
    imgQty: "",
    topUpPrice: "",
  });
  console.log(formData, "formData--->>>>>>>>");
  const [totalStorage, setTotalStorageValue] = useState(0);
  const [videoStorage, setVideoStorage] = useState(0);
  const [audioStorage, setAudioStorage] = useState(0);
  const [docStorage, setDocStorage] = useState(0);
  const [imgStorage, setImgStorage] = useState(0);
  const [monthlyEstimate, setMonthlyEstimate] = useState(0);
  const [topupCost, setTopupCost] = useState(0);
  const [nextPaymentDate, setNextPaymentDate] = useState();
  const yearlyEstimate = (1 * 0.25 * 12) / 365;
  const currentDate = new Date();

  const plan = JSON.parse(localStorage.getItem("plans")) || null;
  useEffect(() => {
    if (plan === null) {
      navigate("/owner/plans");
    }
  }, []);

  useEffect(() => {
    setVideoStorage(formData.videoQty * formData.videoLen * 60);
    setAudioStorage((formData.mp3Len * formData.mp3Qty * 61.4) / 9);
    setImgStorage(formData.imgQty * formData.imgSize);
    setDocStorage(formData.documentQty * formData.documentSize);
  }, [formData]);
  useEffect(() => {
    setUpdateStorage();
  }, [imgStorage, docStorage, audioStorage, videoStorage]);

  useEffect(() => {
    setMonthlyEstimate((totalStorage / 1000) * yearlyEstimate * 31);
    setTopupCost(formData.topUpPrice / monthlyEstimate);
    const monthly = (totalStorage / 1000) * yearlyEstimate * 31;
    if (formData.topUpPrice && totalStorage) {
      const PaymentDate = new Date(
        currentDate.setMonth(
          currentDate.getMonth() + formData.topUpPrice / monthly
        )
      );
      const formattedNextPaymentDate = PaymentDate.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
      setNextPaymentDate(formattedNextPaymentDate);
    } else {
      setMonthlyEstimate(0);
      setNextPaymentDate("");
    }
  }, [totalStorage, topupCost, formData.topUpPrice]);

  const setUpdateStorage = () => {
    if (videoStorage || audioStorage || docStorage || imgStorage) {
      setTotalStorageValue(
        parseInt(videoStorage) +
          parseInt(audioStorage) +
          parseInt(docStorage) +
          parseInt(imgStorage)
      );
    }
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleCheckout = async () => {

    handleStorePayAsYouData(
      formData,
      imgStorage,
      docStorage,
      audioStorage,
      videoStorage
      // topUpPrice
    );
    if (
      monthlyEstimate &&
      // formData?.topUpPrice >= 5 &&

      formData?.topUpPrice > 5 &&
      nextPaymentDate &&
      plan
    ) {
      const checkoutPlan = {
        name: plan?.name,
        discountPrice: formData?.topUpPrice,
        planType: plan.planType,
        planTenure: plan.planTenure

      };

      // const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      const res = await planServices.checkOutSession(
        { plans: checkoutPlan },
        user?.token
      );
      console.log(res);
      localStorage.setItem(
        "checkout",
        JSON.stringify({
          sessionId: res?.data?.data?.session,
          planId: plan?._id,
          nextPaymentDate: nextPaymentDate,
          monthlyEstimate: monthlyEstimate.toFixed(2),
          customer: res?.data?.data?.customer,
          planTenure: plan.planTenure
        })
      );
      // const result = await stripe.redirectToCheckout({
      //   sessionId: res?.data?.data?.session,
      // });
      setTimeout(() => {
        window.location.href = `/checkout`;
      }, 2000);
      // if (result.error) {
      //   toast.warning("something went wrong");
      // }
    // } else if (formData.topUpPrice && formData.topUpPrice < 5) {

    } else if (formData.topUpPrice && formData.topUpPrice < 6) {
      Swal.fire({
        title: "Validation Error!",
        text: "The minimum deposit amount must exceed $5.",
        icon: "error",
      });
    } else {
      Swal.fire({
        text: "Please Input the media details for estimate your cost before proceed",
        icon: "info",
      });
    }
  };
  const handleStorePayAsYouData = async (
    formData,
    imgStorage,
    docStorage,
    audioStorage,
    videoStorage
  ) => {
    console.log(formData, "FFFF");
    const payAsYouGoDataPayload = {
      video: {
        quantity: formData?.videoQty ? formData?.videoQty : "",
        length: formData?.videoLen ? formData?.videoLen : "",
        storageSize: videoStorage ? videoStorage : "",
      },
      mp3: {
        quantity: formData?.mp3Qty ? formData?.mp3Qty : "",
        length: formData?.mp3Len ? formData?.mp3Len : "",
        storageSize: audioStorage ? audioStorage : "",
      },
      document: {
        quantity: formData?.documentQty ? formData?.documentQty : "",
        length: formData?.documentSize ? formData?.documentSize : "",
        storageSize: docStorage ? docStorage : "",
      },
      image: {
        quantity: formData?.imgQty ? formData?.imgQty : "",
        length: formData?.imgSize ? formData?.imgSize : "",
        storageSize: imgStorage ? imgStorage : "",
      },
      depositAmount: formData?.topUpPrice ? formData?.topUpPrice : "",
      dataStorageTotal: totalStorage ? parseFloat(totalStorage).toFixed(2) : "",
      price: monthlyEstimate ? monthlyEstimate : "",
    };
    const payAsYou = {
      video: {
        quantity: formData?.videoQty ? formData?.videoQty : "",
        length: formData?.videoLen ? formData?.videoLen : "",
        storageSize: videoStorage ? videoStorage : "",
      },
      mp3: {
        quantity: formData?.mp3Qty ? formData?.mp3Qty : "",
        length: formData?.mp3Len ? formData?.mp3Len : "",
        storageSize: audioStorage ? audioStorage : "",
      },
      document: {
        quantity: formData?.documentQty ? formData?.documentQty : "",
        length: formData?.documentSize ? formData?.documentSize : "",
        storageSize: docStorage ? docStorage : "",
      },
      image: {
        quantity: formData?.imgQty ? formData?.imgQty : "",
        length: formData?.imgSize ? formData?.imgSize : "",
        storageSize: imgStorage ? imgStorage : "",
      },
      depositAmount: formData?.topUpPrice ? formData?.topUpPrice : "",
      dataStorageTotal: totalStorage ? parseFloat(totalStorage).toFixed(2) : "",
      price: monthlyEstimate ? monthlyEstimate : "",
      selectedPlanType: "PayAsYouGo"
    };
    console.log(payAsYouGoDataPayload, "payAsYouGoDataPayload");
    // Dispatch the action with the payload
    dispatch(payAsYouGoData(payAsYouGoDataPayload));
    dispatch(setCheckoutPlanData(payAsYou));

  };

  return (
    <Container className="py-xl-5 py-2">
      <BackButton />
      <Row className="justify-content-center text-center">
        <Col xl={8} lg={8} md={8}>
          <h4 className="login-forevermems mb-2">Estimate Your Costs</h4>
          <p>
            Plan your budget with the Pay As You Go Estimator.
            <br /> Input your media details, hit 'Calculate Estimate,' and get
            insights into your monthly cost.
            <br /> Take control effortlessly!
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center text-center mt-4">
        <Col xl={8} lg={8} md={8}>
          <Row className="px-3">
            <Form.Group as={Col} md="4" className="estimator__form__input">
              <Form.Label>Deposit Amount</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Enter the amount"
                min={5}
                onChange={(e) => {
                  setFormData({ ...formData, topUpPrice: e.target.value });
                }}
              />
            </Form.Group>
            <Form.Group as={Col} md="8" className="estimator__form__input">
              <Form.Label>Data Storage total</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Total Storage"
                value={parseFloat(totalStorage).toFixed(2)}
                disabled
                readOnly
              />
            </Form.Group>
          </Row>
          <hr />
          <span className="personalize_your_plan" onClick={toggleVisibility}>
            <i className="ri-equalizer-2-fill"></i> Personalize your plan
            <i className={`ri-arrow-${isVisible ? "up" : "down"}-s-fill`}></i>
          </span>
          {isVisible && (
            <div>
              {isVisible && (
                <div>
                  <Row className="px-3 mb-3">
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Video Quantity</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="2"
                        min={0}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            videoQty: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Video Length</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="50 Min"
                        min={0}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            videoLen: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Video Storage Size</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="500 MB"
                        value={videoStorage}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Row>
                  <Row className="px-3 mb-3">
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>MP3 Quantity</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="2"
                        min={0}
                        onChange={(e) => {
                          setFormData({ ...formData, mp3Qty: e.target.value });
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>MP3 Length</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="10 Min"
                        min={0}
                        onChange={(e) => {
                          setFormData({ ...formData, mp3Len: e.target.value });
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Mp3 Storage Size</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="50 MB"
                        value={audioStorage}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Row>
                  <Row className="px-3 mb-3">
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Document Quantity</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="1"
                        min={0}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            documentQty: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Document Size (MB)</Form.Label>
                      <Form.Control
                        required
                        min={0}
                        type="number"
                        placeholder="20 MB"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            documentSize: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Docs Storage total</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="200 MB"
                        value={docStorage}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Row>
                  <Row className="px-3 mb-3">
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Image Quantity</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        min={0}
                        placeholder="1"
                        onChange={(e) => {
                          setFormData({ ...formData, imgQty: e.target.value });
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Image Size (MB)</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        min={0}
                        placeholder="20 MB"
                        onChange={(e) => {
                          setFormData({ ...formData, imgSize: e.target.value });
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      className="estimator__form__input"
                    >
                      <Form.Label>Image Storage total</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="100 MB"
                        value={imgStorage}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Row>
                </div>
              )}
            </div>
          )}
        </Col>
        <Col xl={4} lg={4} md={4}>
          <Card className="py-5 px-2 result__card">
            <div className="card__header mb-5">
              <h4>
                <b>Estimation Results</b>
              </h4>
              <p>Monthly Cost Estimate</p>
              <h5>
                <b>$ {monthlyEstimate.toFixed(2)}</b>
              </h5>
            </div>
            <div className="card__body">
              <p>Next Billing Estimate</p>
              <h5>
                <b>{nextPaymentDate ? nextPaymentDate : "MM/DD/YYYY"}</b>
              </h5>
              <h5 className="mt-5">
                <b>
                  Ready to Dive In? <br />
                  Hit 'Confirm' to Activate!
                </b>
              </h5>
              <Form.Check
                className="gift__checkbox"
                type="checkbox"
                label="Gift Foervermems to your loved ones"
              />
            </div>
            <div className="card__footer mt-4">
              <Button
                className="w-50"
                variant="primary"
                onClick={handleCheckout}
              >
                Confirm
              </Button>
              <small className="d-block text-muted mt-3">Not Now</small>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Estimator;
