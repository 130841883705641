// GridView.js
import React, { useState } from "react";
import { Row, Col, Modal, Dropdown } from "react-bootstrap";
import DownLoadOption from "../DownLoadOption";
import DataNotFound from "../DataNotFound";
import Loader from "../Loader";
import SkeletonLoader from "../SkeletonLoader";

const MemoryView = ({
  memories,
  isGridViewActive,
  handleShowMedia,
  setPreviewElements,
  isLoading,
}) => {
  const [isActionLoading, setIsActionLoading] = useState(false);

  const handleViewClick = (item) => {
    setPreviewElements(item);
    handleShowMedia();
  };

  async function handleDownload(item) {
    setIsActionLoading(true);
    const { url, fileName } = item;
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading file:", error))
      .finally(() => setIsActionLoading(false));
  }
  if (isActionLoading) return <Loader />;
  return (
    <Row className="my-3">
      {isLoading && <SkeletonLoader />}
      {!isLoading && memories && memories.length === 0 && (
        <DataNotFound isAlbum={false} />
      )}
      {memories &&
        memories.length !== 0 &&
        memories.map((item, index) => (
          <React.Fragment key={index}>
            {isGridViewActive ? (
              <Col xl={12} lg={12} md={12}>
                <div className="list-item">
                  <div
                    className="list-item-image"
                    onClick={() => {
                      handleViewClick(item);
                    }}
                    role="button"
                  >
                    <img
                      src={
                        item.fileType == "video"
                          ? item.thumnailUrl
                          : item.fileType == "image"
                          ? item?.fileUrl
                          : item.fileType == "document"
                          ? "/doc-placeholder.png"
                          : ""
                      }
                    
                      alt=""
                    />
                  </div>
                  <div
                   
                    className="list-item-content  d-flex align-items-center justify-content-between"
                  >
                    <h5>
                   
                    </h5>
                    <div className="button-group">
                      <div className="album_folder_detail_views">
                        <div className="avatars">
                          <span className="avatar">
                            <img
                              src="https://picsum.photos/1920?1080?random=21"
                              alt=""
                            />
                          </span>
                          <span className="avatar">
                            <img
                              src="https://picsum.photos/1920?1080?random=21"
                              alt=""
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </Col>
            ) : (
              <Col xl={3} lg={4} md={6}>
                <div className="grid-card">
                  {item?.fileType != "video" && (
                    <div className="grid-card-header">
                      <h6>{""}</h6>
                    
                    </div>
                  )}
                  <div
                    className="grid-card-body"
                    onClick={() => {
                      handleViewClick(item);
                    }}
                    role="button"
                  >
                    <img
                    
                      src={
                        item.fileType == "video"
                          ? item.thumnailUrl
                          : item.fileType == "image"
                          ? item?.fileUrl
                          : item.fileType == "document"
                          ? "/doc-placeholder.png"
                          : ""
                      }
                      alt=""
                    />
                  </div>
                  <div
                  
                    className="memory-card-footer d-flex justify-content-between align-items-center"
                  >
                    <div className="memory-card-footer-detail w-50">
                     
                    </div>
                    <div className="avatars">
                      <div className="album_folder_detail_views">
                       
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            )}
          </React.Fragment>
        ))}
    </Row>
  );
};

export default MemoryView;
