import { useDispatch, useSelector } from "react-redux";
import { getOwnerAuth, ownerLoginAuth } from "../store/slices/ownerSlice";
import {
  executorLoginAuth,
  getExecutorAuth,
} from "../store/slices/executerSlice";
import { getViewerAuth, viewerLoginAuth } from "../store/slices/viewerSlice";
import { authService } from "../_services";
import { EXECUTOR, OWNER, VIEWER } from "../_helpers";
const useAuthToken = () => {
  const ownerAuth = useSelector(getOwnerAuth);
  const viewerAuth = useSelector(getViewerAuth);
  const executorAuth = useSelector(getExecutorAuth);
  //const dispatch = useDispatch();
  //const token = ownerAuth?.token || viewerAuth?.token || executorAuth.token;
  // let status = false;

  // if (token) {
  //   authService
  //     .checkAuthTokenIsVaild(token)
  //     .then((res) => {
  //       let authData = res?.data?.data;

  //       if (authData?.role === OWNER) {
  //         dispatch(ownerLoginAuth(authData));
  //       }

  //       if (authData?.role === EXECUTOR) {
  //         dispatch(executorLoginAuth(authData));
  //       }

  //       if (authData?.role === VIEWER) {
  //         dispatch(viewerLoginAuth(authData));
  //       }

  //       status = true;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       if (error.response && error.response.status === 401) {
  //         status = false;
  //       }
  //     });

  //   return status;
  // }
  // return status;
  if (ownerAuth.token) {
    return ownerAuth.token;
  }
  if (viewerAuth.token) {
    return viewerAuth.token;
  }
  if (executorAuth.token) {
    return executorAuth.token;
  }
  return "";
};
export default useAuthToken;
