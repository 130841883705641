import axios from "axios";

export const ownerSettingsService = {
  updateOwnerDetails,
  deleteOwnerAccount,
  signoutFromAllDevices,
  updateProfileImageURL,
  updateOwnerContactNo,
};

async function updateOwnerDetails(owenerData, ownerId) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/update-owner-details/${ownerId}`,
    owenerData,
    { headers: { "Content-Type": "application/json" } }
  );
}
async function deleteOwnerAccount(ownerId) {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL}/delete-owner-account/${ownerId}`,

    { headers: { "Content-Type": "application/json" } }
  );
}
async function signoutFromAllDevices(ownerId) {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL}/sign-out-all/${ownerId}`,

    { headers: { "Content-Type": "application/json" } }
  );
}

async function updateProfileImageURL(id, data) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/update-profile-url/${id}`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
async function updateOwnerContactNo(data, token) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/update-number-after-signup`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
