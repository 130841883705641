import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { EXECUTOR, OWNER, VIEWER } from "../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUserDashBoardData } from "../../store/slices/dashboardSlice";
import FolderModal from "../FolderModal";
import VideoModal from "../VideoModal";
import { useAuth } from "../../_hooks/useAuth";
import { ownerSettingsService, planServices } from "../../_services";
import toast from "react-hot-toast";
import {
  emptyRecordedData,
  setRecordVdoStep1Data,
  setterFieldValue,
} from "../../store/slices/recordingFormSlice";
import VerifyProfileAndManagePaymentPopUp from "../SideBar/verifyProfileBuyPlanAlert";
import { ownerAvatar } from "../../store/slices/ownerSlice";
import {
  executorAvatarUpdate,
  getExecutorAsOwnerData,
} from "../../store/slices/executerSlice";
import { viewerAvatarUpdate } from "../../store/slices/viewerSlice";
import { setPlanData } from "../../store/slices/planSlice";
import { getViwerAsOwnerData } from "../../store/slices/viewerFolderSlice";
const TabsBottom = () => {
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  // const handleShow1 = () => setShow1(true);
  const toggleModel = () => {
    setShow1(!show1);
  };

  const backdrop = document.querySelector(".modal-backdrop");
  const loggedInUSerRole = useSelector(getLoggedInUserDashBoardData);
  const viewerAsOwnerDataStore = useSelector(getViwerAsOwnerData);
  const executorAsOwnerDataStore = useSelector(getExecutorAsOwnerData);

  const user = useAuth("user");
  const [subscriptionCurrentPlanData, setSubscriptionCurrentPlanData] =
    useState({});
  // CURRENT PLAN START
  useEffect(() => {
    if (user) {
      if (user.role == OWNER) {
        getCurrentPlan(user?._id, user?.token);
      }
      if (user.role == VIEWER) {
        getCurrentPlan(viewerAsOwnerDataStore?._id, user?.token);
      }
      if (user == EXECUTOR && executorAsOwnerDataStore) {
        getCurrentPlan(executorAsOwnerDataStore?._id);
      }
    }
  }, []);
  const getCurrentPlan = (loggedInUserId) => {
    planServices
      .getOwnerCurrentSubscription(loggedInUserId, user?.token)
      .then((response) => {
        if (response?.data?.status == true) {
          setSubscriptionCurrentPlanData(response?.data?.data);
          dispatch(
            setPlanData({
              selectedPlanType: response?.data?.data?.selectedPlanType,
            })
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching subscription data:", error.message);
      });
  };
  // CURRENT PLAN ENDS
  const handleShow = () => {
    if (user?.isProfileCompleted == true) {
      setShow(true);
    } else {
      toast.error(
        "Upgrade Required: Unlock this feature by completing your payment. Questions? Contact support."
      );
    }
  };
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    dispatch(setterFieldValue(false));
    dispatch(setRecordVdoStep1Data());
    dispatch(emptyRecordedData());
  };
  const [showRecordingModel, setShowRecordingModel] = useState(false);
  const [recordingData, setRecordingData] = useState({});

  const [showPaymentVerificationPopup, setShowPaymentVerificationPopup] =
    useState(false);
  const [nonImageAvatar, setNonImageAvatar] = useState();

  const handleClosePaymentVerificationPopup = () =>
    setShowPaymentVerificationPopup(false);
  const handleShowPaymentVerificationPopup = () =>
    setShowPaymentVerificationPopup(true);
  // Expiration of Avatar Image
  useEffect(() => {
    if (user?.avatar != null) {
      // const checkThumbnailUrl = (album) => {
      if (user?.avatar != null && user?.fileName != null) {
        const img = new Image();
        img.onload = function () {
          console.log("Thumbnail URL is an image:", user.avatar);
        };
        img.onerror = function () {
          console.log("Thumbnail URL is not an image:", user.avatar);
          // for reupdating the thumbnail
          setNonImageAvatar(user.avatar);
        };
        img.src = user.avatar;
      }
    }
  }, [user]);

  useEffect(() => {
    if (nonImageAvatar) {
      handleUpdateExpiredProPicFileUrl(nonImageAvatar);
    }
  }, [nonImageAvatar]);
  const handleUpdateExpiredProPicFileUrl = () => {
    const ownerAlbumUrlData = {
      role: loggedInUSerRole,
      fileName: user.fileName,
    };
    // Return the promise for updating the thumbnail URL
    return ownerSettingsService
      .updateProfileImageURL(user?._id, ownerAlbumUrlData)
      .then((response) => {
        if (response?.data?.status === true) {
          if (loggedInUSerRole.role == OWNER) {
            dispatch(ownerAvatar({ avatar: response.data.data.avatar }));
          }
          if (loggedInUSerRole.role == EXECUTOR) {
            dispatch(
              executorAvatarUpdate({ avatar: response.data.data.avatar })
            );
          } else {
            dispatch(viewerAvatarUpdate({ avatar: response.data.data.avatar }));
          }
        }
      })
      .catch((error) => {
        console.log("Network ERROR", error);
      });
  };
  return (
    <>
      <div className="bottom-bar">
        <ul className="d-flex align-items-center mb-0 p-0 justify-content-between pointer list-style-none">
          <li className="py-2 mb-0 text-center">
            <NavLink
              to={`/${
                loggedInUSerRole.role == OWNER ? OWNER : EXECUTOR
              }/dashboard`}
              className="navigate-popover-pages "
            >
              <svg
                width="36"
                height="36"
                viewBox="0 0 28 25"
                fill="#076AFE"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.9353 10.8738C26.9344 10.8732 26.9338 10.8725 26.9331 10.8719L15.6413 0.674248C15.16 0.239372 14.5201 0 13.8394 0C13.1588 0 12.5189 0.239372 12.0373 0.674248L0.75145 10.8667C0.747648 10.8702 0.743636 10.8738 0.740045 10.8772C-0.248333 11.775 -0.246644 13.2317 0.744903 14.1272C1.19791 14.5365 1.79601 14.7734 2.43571 14.7984C2.46189 14.8006 2.48808 14.8018 2.51448 14.8018H2.96432V22.3064C2.96432 23.7917 4.30243 25 5.94699 25H10.3647C10.8126 25 11.1757 24.6719 11.1757 24.2676V18.3838C11.1757 17.7061 11.7862 17.1549 12.5366 17.1549H15.1423C15.8927 17.1549 16.503 17.7061 16.503 18.3838V24.2676C16.503 24.6719 16.866 25 17.314 25H21.7317C23.3765 25 24.7144 23.7917 24.7144 22.3064V14.8018H25.1317C25.8121 14.8018 26.452 14.5624 26.9338 14.1273C27.9264 13.2305 27.9268 11.7712 26.9353 10.8738ZM25.7868 13.0917C25.6117 13.2498 25.379 13.3369 25.1317 13.3369H23.9034C23.4554 13.3369 23.0924 13.6648 23.0924 14.0694V22.3064C23.0924 22.9839 22.4821 23.5352 21.7317 23.5352H18.125V18.3838C18.125 16.8985 16.7871 15.69 15.1423 15.69H12.5366C10.8918 15.69 9.55372 16.8985 9.55372 18.3838V23.5352H5.94699C5.19683 23.5352 4.58628 22.9839 4.58628 22.3064V14.0694C4.58628 13.6648 4.22324 13.3369 3.7753 13.3369H2.56812C2.55545 13.3362 2.54299 13.3356 2.53011 13.3354C2.28851 13.3316 2.0619 13.245 1.8921 13.0915C1.53096 12.7653 1.53096 12.2345 1.8921 11.9081C1.89189 11.9083 1.89231 11.9081 1.8921 11.9081L1.89315 11.9072L13.1845 1.70994C13.3594 1.55182 13.5919 1.46484 13.8394 1.46484C14.0868 1.46484 14.3193 1.55182 14.4944 1.70994L25.7832 11.9051C25.7849 11.9066 25.7868 11.9081 25.7885 11.9097C26.1477 12.2364 26.1471 12.7661 25.7868 13.0917Z"
                  fill="#076AFE"
                  stroke="#076AFE"
                />
              </svg>
              <br />
              Home
            </NavLink>
          </li>
          {loggedInUSerRole.role == OWNER && (
            <li className="py-2 mb-0 text-center">
              <Button className="add-memory-btn" onClick={toggleModel}>
                {!show1 ? (
                  <i className="ri-add-line"></i>
                ) : (
                  <i className="ri-arrow-up-s-line"></i>
                )}
              </Button>
              <br />
              <span>Add Memory</span>
            </li>
          )}
          <li className="py-2 mb-0 text-center">
            <NavLink
              to={`/${
                loggedInUSerRole.role == OWNER ? OWNER : EXECUTOR
              }/albums`}
              className="navigate-popover-pages "
            >
              <svg
                width="36"
                height="36"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_2937_11616)">
                  <path
                    d="M18.1797 25.0002C18.0047 25.0002 17.8256 24.9784 17.6485 24.9315L1.54117 20.6179C0.437007 20.3138 -0.221326 19.17 0.0682572 18.0659L2.10055 10.4909C2.17555 10.2127 2.46097 10.0513 2.73805 10.1221C3.01617 10.1961 3.18076 10.4825 3.1068 10.7596L1.07555 18.3325C0.930757 18.8846 1.26201 19.4596 1.81513 19.6127L17.9162 23.9242C18.4693 24.07 19.0401 23.7409 19.1839 23.1909L19.9974 20.1763C20.0724 19.8981 20.3578 19.7325 20.636 19.8086C20.9141 19.8836 21.0776 20.17 21.0037 20.4471L20.1912 23.4575C19.9464 24.3846 19.1026 25.0002 18.1797 25.0002Z"
                    fill="#5C5C5C"
                    stroke="#5C5C5C"
                    strokeWidth="0.4"
                  />
                  <path
                    d="M22.918 18.7487H6.2513C5.10234 18.7487 4.16797 17.8143 4.16797 16.6654V4.16536C4.16797 3.01641 5.10234 2.08203 6.2513 2.08203H22.918C24.0669 2.08203 25.0013 3.01641 25.0013 4.16536V16.6654C25.0013 17.8143 24.0669 18.7487 22.918 18.7487ZM6.2513 3.1237C5.67734 3.1237 5.20964 3.59141 5.20964 4.16536V16.6654C5.20964 17.2393 5.67734 17.707 6.2513 17.707H22.918C23.4919 17.707 23.9596 17.2393 23.9596 16.6654V4.16536C23.9596 3.59141 23.4919 3.1237 22.918 3.1237H6.2513Z"
                    fill="#5C5C5C"
                    stroke="#5C5C5C"
                    strokeWidth="0.45"
                  />
                  <path
                    d="M9.3763 9.3737C8.22734 9.3737 7.29297 8.43932 7.29297 7.29036C7.29297 6.14141 8.22734 5.20703 9.3763 5.20703C10.5253 5.20703 11.4596 6.14141 11.4596 7.29036C11.4596 8.43932 10.5253 9.3737 9.3763 9.3737ZM9.3763 6.2487C8.80234 6.2487 8.33464 6.71641 8.33464 7.29036C8.33464 7.86432 8.80234 8.33203 9.3763 8.33203C9.95026 8.33203 10.418 7.86432 10.418 7.29036C10.418 6.71641 9.95026 6.2487 9.3763 6.2487Z"
                    fill="#5C5C5C"
                  />
                  <path
                    d="M4.75938 17.6341C4.62604 17.6341 4.49271 17.5831 4.39062 17.482C4.1875 17.2789 4.1875 16.9487 4.39062 16.7456L9.31042 11.8258C9.9 11.2362 10.9302 11.2362 11.5198 11.8258L12.9844 13.2904L17.0385 8.42578C17.3333 8.07266 17.7667 7.86745 18.2281 7.86328H18.2396C18.6958 7.86328 19.1281 8.0612 19.426 8.40807L24.874 14.7643C25.0615 14.982 25.0365 15.3112 24.8177 15.4987C24.6 15.6862 24.2719 15.6622 24.0833 15.4424L18.6354 9.0862C18.5344 8.96953 18.3948 8.90495 18.2396 8.90495C18.1313 8.89557 17.9406 8.97057 17.8396 9.09245L13.4198 14.3956C13.326 14.5081 13.1896 14.5758 13.0427 14.582C12.8948 14.5924 12.7542 14.5341 12.651 14.4299L10.7833 12.5622C10.5865 12.3664 10.2438 12.3664 10.0469 12.5622L5.12708 17.482C5.02604 17.5831 4.89271 17.6341 4.75938 17.6341Z"
                    fill="#5C5C5C"
                    stroke="#5C5C5C"
                    strokeWidth="0.4"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2937_11616">
                    <rect width="25" height="25" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <br />
              Albums
            </NavLink>
          </li>
        </ul>
      </div>
      <Modal
        show={show1}
        onHide={handleClose}
        backdrop={false}
        className="add-memory-tab-modal"
      >
        <Modal.Body>
          <div className="btn-container d-flex flex-column align-item-center justify-content-between gap-3">
            <Button
              onClick={() => {
                handleClose1();
                handleShow();
              }}
            >
              Add Files
            </Button>
            <Button
              onClick={() => {
                handleClose1();
                handleShow();
              }}
            >
              Record Video
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {show && !showRecordingModel && (
        <Modal
          className=" create-folder-modal"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Body className="py-5 px-4">
            <FolderModal
              show={show}
              setShow={setShow}
              handleClose={handleClose}
              handleShow={handleShow}
              showRecordingModel={showRecordingModel}
              setShowRecordingModel={setShowRecordingModel}
              recordingData={recordingData}
              setRecordingData={setRecordingData}
              user={user}
              subscriptionCurrentPlanData={subscriptionCurrentPlanData}
            />
          </Modal.Body>
        </Modal>
      )}
      {showRecordingModel && !show && (
        <Modal
          className=" create-folder-modal video-recoder-modal"
          show={showRecordingModel}
          onHide={() => setShowRecordingModel(false)}
          centered
        >
          <Modal.Body className="py-5 px-4">
            <VideoModal
              showRecordingModel={showRecordingModel}
              setShowRecordingModel={setShowRecordingModel}
              recordingData={recordingData}
              setRecordingData={setRecordingData}
              show={show}
              setShow={setShow}
            />
          </Modal.Body>
        </Modal>
      )}
      {/* opens when the owner is not paid one */}
      {showPaymentVerificationPopup && (
        <VerifyProfileAndManagePaymentPopUp
          showPaymentVerificationPopup={showPaymentVerificationPopup}
          setShowPaymentVerificationPopup={setShowPaymentVerificationPopup}
          handleClosePaymentVerificationPopup={
            handleClosePaymentVerificationPopup
          }
          handleShowPaymentVerificationPopup={
            handleShowPaymentVerificationPopup
          }
        />
      )}
    </>
  );
};

export default TabsBottom;
