import React, { useEffect, useState, useCallback } from "react";
import { useAuth } from "../../_hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dashboardService } from "../../_services";
import { loggedInUserRole } from "../../store/slices/dashboardSlice";
import { ownerLoginAuth, ownerLogoutAuth } from "../../store/slices/ownerSlice";
import { EXECUTOR, OWNER, VIEWER } from "../../_helpers";
import {
  executorLoginAuth,
  executorLogoutAuth,
  executorPlanUgradeStatus,
} from "../../store/slices/executerSlice";
import {
  viewerLoginAuth,
  viewerLogoutAuth,
  viewerPlanUgradeStatus,
} from "../../store/slices/viewerSlice";
import toast from "react-hot-toast";
import { Spinner } from "react-bootstrap";
import { getViewerOwner } from "../../store/slices/viewerFolderSlice";

const DashboardRoles = () => {
  const role = useAuth("role");
  const user = useAuth("user");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loggedInDashboardUserRoles, setLoggedInDashboardUserRoles] = useState(
    []
  );
  const [loading, setLoading] = useState(true);
  const viewerOwnerStore = useSelector(getViewerOwner);
  useEffect(() => {
    const fetchUserRoles = async () => {
      if (user) {
        const data = {
          email: user.email,
        };
        try {
          const res = await dashboardService.findUserRoles(data);
          console.log(res?.data?.data);
          setLoggedInDashboardUserRoles(res?.data?.data);
        } catch {
        } finally {
          setLoading(false);
        }
      }
    };
    fetchUserRoles();
  }, [user]);

  const handleRoleClick = useCallback(
    async (key) => {
      const dashboardData = {
        email: user.email,
        role: key,
      };
      try {
        const response = await dashboardService.loggedInDashboardUser(
          dashboardData
        );
        if (response?.data?.status === true) {
          dispatch(
            loggedInUserRole({
              role: response?.data?.data?.role,
              token: response?.data?.data?.token,
            })
          );
          if (response?.data?.data?.role === OWNER) {
            dispatch(ownerLoginAuth(response?.data?.data));
            dispatch(executorLogoutAuth());
            dispatch(viewerLogoutAuth());
            navigate(`${OWNER}/dashboard`);
          } else if (response?.data?.data?.role === EXECUTOR) {
            dispatch(executorLoginAuth(response?.data?.data));
            dispatch(ownerLogoutAuth());
            dispatch(viewerLogoutAuth());
            dispatch(
              executorPlanUgradeStatus({
                isPlanPurchased: true,
              })
            );
            navigate(`${EXECUTOR}/dashboard`);
          } else if (response?.data?.data?.role === VIEWER) {
            dispatch(viewerLoginAuth(response?.data?.data));
            dispatch(executorLogoutAuth());
            dispatch(ownerLogoutAuth());
            dispatch(
              viewerPlanUgradeStatus({
                isPlanPurchased: true,
              })
            );
            if (viewerOwnerStore?.length > 0) {
              navigate(
                `${viewerOwnerStore[0]?.userName}/${viewerOwnerStore[0]?._id}`
              );
            } else {
              navigate(`${VIEWER}/albums`);
            }
          }
        }
      } catch (error) {
        console.log("Network ERROR", error);
      }

      console.log("Role clicked:", key);
      console.log("User email:", user.email);
      console.log("User ID:", user._id);
    },
    [dispatch, navigate, user]
  );

  if (loading) {
    return (
      <div className="text-center">
        <Spinner size="sm" />
      </div>
    );
  }

  if (
    loggedInDashboardUserRoles.length === 1 &&
    loggedInDashboardUserRoles[0] === OWNER
  ) {
    return null;
  }

  return (
    <ul>
      {loggedInDashboardUserRoles.length > 0 &&
        loggedInDashboardUserRoles.map((item, index) => (
          <li key={index}>
            <a href="#" onClick={() => handleRoleClick(item)}>
              <img src="/assets/folder.png" alt="User" />{" "}
              {`${item.replace(/^([a-z])/, (c) =>
                c.toUpperCase()
              )}'s Dashboard`}
            </a>
          </li>
        ))}
    </ul>
  );
};

export default DashboardRoles;
