import React from "react";
import { Button, Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../_hooks/useAuth";
const Header = () => {
  const role = useAuth("role");
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search, state } = location;
  const Menu = [
    {
      Link: "https://www.forevermems.com/about-us/",
      Name: "About",
    },
    {
      Link: "https://www.forevermems.com/pricing/",
      Name: "Pricing",
    },
    {
      Link: "https://www.forevermems.com/f-a-q/",
      Name: "FAQ",
    },
    {
      Link: "https://www.forevermems.com/contact-us/",
      Name: "Contact Us",
    },
  ];
  return (
    <header>
      <Navbar expand="lg" className="py-3">
        <Container>
          <Navbar.Brand className="m-0 p-0" href="/">
            <img alt="Forevermems" src="/logo.png" />
          </Navbar.Brand>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="start"
          >
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-5 align-items-center">
                {Menu.map((Menu, index) => (
                  <Nav.Link
                    key={index}
                    href={Menu.Link}
                    target={
                      Menu.Link.startsWith("https") ||
                      Menu.Link.startsWith("www")
                        ? "_blank"
                        : ""
                    }
                    rel={
                      Menu.Link.startsWith("https") ||
                      Menu.Link.startsWith("www")
                        ? "noopener noreferrer"
                        : ""
                    }
                  >
                    {Menu.Name}
                  </Nav.Link>
                ))}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          {role == "" && pathname!= "/sign-in" && (
            <Button
              variant="primary"
              className="btn btn-primary login-header-btn sign-in-page"
              onClick={() => navigate("/sign-in")}
            >
              Log In
            </Button>
          )}
          {role && (
            <Button
              variant="primary"
              className="btn btn-primary login-header-btn"
              onClick={() => {
                navigate(`/${role}/dashboard`);
              }}
            >
              Dashboard
            </Button>
          )}
          {role && (
            <div className="d-flex gap-2 align-items-center dashbard-mb">
              <Button
                variant="primary"
                onClick={() => {
                  navigate(`/${role}/dashboard`);
                }}
                className="dashbard-mb"
              >
                {/* Dashboard {role} */}
                {role != "owner"
                  ? "Dashboard"
                  : role == "executor"
                  ? "Executor Dashboard"
                  : role == "viewer"
                  ? "Viewer Dashboard"
                  : "Dashboard"}
              </Button>
            </div>
          )}
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
