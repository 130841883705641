import axios from "axios";

export const videoService = {
  uploadVideo,
  updateVideoThumbNailUrl,
  getSignedUrlForVideo,
};

async function uploadVideo(data, token) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/upload-video`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

async function updateVideoThumbNailUrl(data) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/update-video-thumbnail`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
async function getSignedUrlForVideo(data) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/update-video-signed-url`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
