import React, { useState, useRef } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  setBlobRecordingData,
  setterFieldValue,
} from "../../store/slices/recordingFormSlice";

const VideoModal = ({
  show,
  setShow,
  showRecordingModel,
  setShowRecordingModel,
  user
}) => {
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const mediaRecorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedVideoURL, setRecordedVideoURL] = useState(null);
  const [error, setError] = useState(null);
  const [manageAutoPlay, setManageAutoPlay] = useState("");
  const [recordingBlobData, setRecordingBlobData] = useState();

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      videoRef.current.srcObject = stream;
      mediaRecorderRef.current = new MediaRecorder(stream);
      const chunks = [];

      mediaRecorderRef.current.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: "video/mp4" });
        const { size, type } = blob;
        const extractedBlobData = { size, type };
        setRecordingBlobData(blob);
        const videoURL = URL.createObjectURL(blob);
        if (videoRef.current) {
          videoRef.current.src = videoURL;
          videoRef.current.play();
        }
        setRecordedVideoURL(videoURL);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing media devices: ", error);
      setError(
        "Error accessing media devices. Please make sure your camera and microphone are enabled."
      );
    }
  };

  // const stopRecording = () => {
  //   if (
  //     mediaRecorderRef.current &&
  //     mediaRecorderRef.current.state !== "inactive"
  //   ) {
  //     mediaRecorderRef.current.stop();
  //     setIsRecording(false);
  //   }
  // };
  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach(track => track.stop());
      }
    }
  };
  
  const sendData = (recordingBlobData, recordedVideoURL) => {
    if (recordingBlobData && recordedVideoURL) {
      const data = { blobData: recordingBlobData, vdoUrl: recordedVideoURL };
      if (data) {
        dispatch(setterFieldValue(true));
        dispatch(setBlobRecordingData(data));
      } else {
        console.log("could not sent");
      }
    }
  };

  return (
    <>
      <div className="form-container">
        <Row>
          <Col xl={12} lg={12} xs={12} className="text-center">
            <p className="create-new">Create New Memory</p>
            <h3 className="modal-heading">Record a Video</h3>
          </Col>
        </Row>
        {recordedVideoURL && manageAutoPlay === "hide-webcam" && (
          <Row>
            <Col xl={12} lg={12} xs={12} className="text-center mt-3">
              <video src={recordedVideoURL} controls />
            </Col>
            <Col xl={12} lg={12} xs={12} className="text-center mt-3">
              <Button
                className="create-btn"
                onClick={() => {
                  sendData(recordingBlobData, recordedVideoURL);
                  setShowRecordingModel(false);
                  setShow(true);
                  stopRecording()
                }}
              >
                Save And Add
              </Button>
            </Col>
            <Col xl={12} lg={12} xs={12} className="text-center mt-3">
              <Button
                className="create-btn"
                onClick={() => {
                  setManageAutoPlay("");
                }}
              >
                Re-record
              </Button>
            </Col>
          </Row>
        )}

        {error && (
          <Row>
            <Col xl={12} lg={12} xs={12} className="text-center">
              <p className="text-danger">{error}</p>
            </Col>
          </Row>
        )}
        {manageAutoPlay !== "hide-webcam" && (
          <>
            <Row>
              <Col xl={12} lg={12} xs={12}>
                <div className="video-container">
                  <video ref={videoRef} autoPlay muted />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} xs={12} className="text-center mt-5">
                {!isRecording ? (
                  <Button
                    variant="danger"
                    className="create-btn mb-3"
                    onClick={startRecording}
                  >
                    Start recording
                  </Button>
                ) : (
                  <Button
                    variant="danger"
                    className="create-btn mb-3"
                    onClick={() => {
                      stopRecording();
                      setManageAutoPlay("hide-webcam");
                    }}
                  >
                    Stop recording
                  </Button>
                )}
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col xl={12} lg={12} xs={12} className="text-center mt-5">
            <span
              onClick={() => setShowRecordingModel(false)}
              className="w-100 text-muted d-flex justify-content-center align-items-center"
            >
              <b>Cancel</b>
            </span>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default VideoModal;
