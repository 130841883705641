import React from "react";

function NoContent({ msg }) {
  return (
    <div className="fm-not-found-box">
      <i className="ri-emotion-unhappy-line"></i>
      <span>{msg ? msg : "Data not found"}</span>
    </div>
  );
}

export default NoContent;
