import React, { useState } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import { mediaServices } from "../../_services";
import toast from "react-hot-toast";
import DataNotFound from "../DataNotFound";
import RenameAlert from "../RenameAlert";
import DeleteAlert from "../DeleteAlert";

const RecentMemoriesFolder = ({
  isGridViewActive,
  recentMemories,
  setDeleteFile,
  handleShowMedia,
  setPreviewElements,
  setIsActiveLoading,
  loading,
  setLoading,
  updateFileUrl,
  setUpdateFileUrl,
  user,
  pageType,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleRemoveFile = (fileId) => {
    setIsActiveLoading(true);
    mediaServices
      .softDeleteFiles(fileId, user?.token)
      .then((response) => {
        toast.success("File deleted successfully and moved to trash");
        setDeleteFile(Date.now());
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      })
      .finally(() => {
        setIsActiveLoading(false);
      });
  };

  const handleRenameValue = (title, item) => {
    setIsActiveLoading(true);
    console.log(item._id, title);
    let data = {
      title: title,
    };
    mediaServices
      .updateTitleForMemories(item._id, data, user?.token)
      .then((response) => {
        toast.success("File is been renamed successfully");
        setDeleteFile(Date.now());
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      })
      .finally(() => {
        setIsActiveLoading(false);
      });
  };

  const handleViewClick = (item) => {
    setPreviewElements(item);
    handleShowMedia();
    if (item.fileType == "document") {
      handleUpdateDocFileUrl(item);
    }
  };
  const handleUpdateDocFileUrl = (docUrl) => {
    setLoading(true);
    // Create an array of promises for updating thumbnail URLs
    // const updatePromises = docUrl?.map((fileData) => {
    const ownerFileUrlData = {
      fileName: docUrl?.fileName,
      _id: docUrl?._id,
      path: docUrl?.folderPath,
    };
    // Return the promise for updating the thumbnail URL
    return mediaServices
      .updateFileUrl(ownerFileUrlData)
      .then((response) => {
        if (response.data.status) console.log("Doc file url is updated");
        setLoading(false);
        setUpdateFileUrl(response);
      })
      .catch((error) => {
        console.log("Error");
        setLoading(false);
      });
  };

  return (
    <Row className="my-3 scroll-section">
      {recentMemories.length === 0 && <DataNotFound isAlbum={false} />}
      {recentMemories &&
        recentMemories.length !== 0 &&
        recentMemories.map((item, index) => (
          <React.Fragment key={index}>
            {isGridViewActive ? (
              <Col xl={12} lg={12} md={12}>
                <div className="list-item">
                  <div
                    className="list-item-image"
                    onClick={() => {
                      handleViewClick(item);
                    }}
                    role="button"
                  >
                    <img
                      src={
                        item.fileType == "video"
                          ? item.thumnailUrl
                          : item.fileType == "image"
                          ? item?.fileUrl
                          : item.fileType == "document"
                          ? "/doc-placeholder.png"
                          : ""
                      }
                      alt=""
                    />
                  </div>
                  <div
                    onClick={() => {
                      // handleViewClick(item);
                    }}
                    className="list-item-content  d-flex align-items-center justify-content-between"
                  >
                    <h5>
                      <b>{item.title}</b>
                      <br></br>
                      <small className="text-capitalize">
                        {item.folderName}
                      </small>

                      {item.description != "N/A" && (
                        <div className="d-flex flex-column">
                          <small>
                            {expanded
                              ? item.description
                              : item.description.slice(0, 100)}
                            {item.description.length > 100 && (
                              <span
                                onClick={toggleExpand}
                                style={{ cursor: "pointer", color: "#296Cf5" }}
                              >
                                {expanded ? " View less" : " ... View more"}
                              </span>
                            )}
                          </small>
                        </div>
                      )}
                    </h5>
                    {/* <div className="button-group">
                      <div className="album_folder_detail_views">
                        <div className="avatars">
                          <span className="avatar">
                            <img
                              src="https://picsum.photos/1920?1080?random=21"
                              alt=""
                            />
                          </span>
                          <span className="avatar">
                            <img
                              src="https://picsum.photos/1920?1080?random=21"
                              alt=""
                            />
                          </span>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {pageType != "executor-media" && (
                    <div className="edit-icon">
                      <Dropdown className="edit_option-dropdown">
                        <Dropdown.Toggle>
                          <i className="ri-more-fill text-muted"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <ul className="edit_option_list">
                            <li>
                              <RenameAlert
                                album={item}
                                isAlbum={false}
                                albumName={item.title}
                                handleRenameValue={handleRenameValue}
                              />
                            </li>
                            <li>
                              <DeleteAlert
                                album={item._id}
                                handleRemoveFile={handleRemoveFile}
                              />
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                </div>
              </Col>
            ) : (
              <Col xl={3} lg={4} md={6}>
                <div className="grid-card">
                  <div className="grid-card-header">
                    <h6>{""}</h6>
                    {pageType != "executor-media" && (
                      <Dropdown className="edit_option-dropdown">
                        <Dropdown.Toggle>
                          <i className="ri-more-fill text-muted"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <ul className="edit_option_list">
                            <li>
                              <RenameAlert
                                album={item}
                                isAlbum={false}
                                albumName={item.title}
                                handleRenameValue={handleRenameValue}
                              />
                            </li>
                            <li>
                              <DeleteAlert
                                album={item._id}
                                handleRemoveFile={handleRemoveFile}
                              />
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                  <div
                    className="grid-card-body"
                    onClick={() => {
                      handleViewClick(item);
                    }}
                    role="button"
                  >
                    <img
                      src={
                        item.fileType == "video"
                          ? item.thumnailUrl
                          : item.fileType == "image"
                          ? item?.fileUrl
                          : item.fileType == "document"
                          ? "/doc-placeholder.png"
                          : ""
                      }
                      alt=""
                    />
                  </div>
                  <div
                    onClick={() => {
                      handleViewClick(item);
                    }}
                    className="memory-card-footer d-flex justify-content-between align-items-center"
                  >
                    <div className="memory-card-footer-detail w-50">
                      <h6>{item.title}</h6>
                      <small className="text-capitalize">
                        {item.folderName}
                      </small>
                    </div>

                    <div className="avatars">
                      <div className="album_folder_detail_views">
                        {/* <div className="avatars">
                          <span className="avatar">
                            <img
                              src="https://picsum.photos/1920?1080?random=21"
                              alt=""
                            />
                          </span>
                          <span className="avatar">
                            <img
                              src="https://picsum.photos/1920?1080?random=21"
                              alt=""
                            />
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {item.description != "N/A" && (
                    <div className="d-flex flex-column">
                      <small>
                        {expanded
                          ? item.description
                          : item.description.slice(0, 100)}
                        {item.description.length > 100 && (
                          <span
                            onClick={toggleExpand}
                            style={{ cursor: "pointer", color: "#296Cf5" }}
                          >
                            {expanded ? " View less" : " ... View more"}
                          </span>
                        )}
                      </small>
                    </div>
                  )}
                </div>
              </Col>
            )}
          </React.Fragment>
        ))}
    </Row>
  );
};

export default RecentMemoriesFolder;
