import axios from "axios";
export const viewerService = {
  createViewer,
  signinViewer,
  getViewerList,
  viewerVerification,
  sendViewerInvitation,
  removeViewer,
  getViewerPolicy,
  getViewerFiles,
  updateViewerDetails,
  manageViewerAlbumVisibiltiy,
};
async function createViewer(data, token) {
  console.log(token, "token");
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/viewer-signup`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
async function signinViewer(data) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/viewer-signin`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
async function getViewerList(ownerId) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/get-viewer-by-owner/${ownerId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

async function viewerVerification(data, token) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/viewer-verification`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
async function sendViewerInvitation(data, executorId, token) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/send-viewer-invitation/${executorId}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

async function removeViewer(viewerId, token) {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL}/remove-viewer/${viewerId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

async function getViewerPolicy(id, ownerId) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/get-album-privacy?id=${id}&ownerId=${ownerId}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

async function getViewerFiles(folderId) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/get-viewer-files/${folderId}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

async function updateViewerDetails(data, viewerId, token) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/update-viewer/${viewerId}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

async function manageViewerAlbumVisibiltiy(data, viewerId, token) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/set-album-visibilty/${viewerId}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
