import axios from "axios";
export const publicAccessService = {
  managePublicAccess,
  searchPublicOwners,
  publicOwnerFolders,
  publicOwnerFiles,
};
async function managePublicAccess(data) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/updateFolderAccesibility`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
async function searchPublicOwners(searchedOwner) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/findOwners/${searchedOwner}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
async function publicOwnerFolders(ownerId) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/findFolders/${ownerId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
async function publicOwnerFiles(folderId) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/findOpenFiles/${folderId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
