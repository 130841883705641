import React from "react";
import {
  Badge,
  OverlayTrigger,
  Popover,
  Tab,
  Tabs,
  Button,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ownerLogoutAuth } from "../../store/slices/ownerSlice";
import { executorLogoutAuth } from "../../store/slices/executerSlice";
import { viewerLogoutAuth } from "../../store/slices/viewerSlice";
import { useNavigate, NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import { useAuth } from "../../_hooks/useAuth";
import DashboardTabs from "../DashboardTabs";
import { setExecutorFoldersLogout } from "../../store/slices/executorFolderSlice";
import {
  removeViewerAsOwnerData,
  removeViewerIdArr,
  removeViewerOwner,
  setViewerFoldersLogout,
} from "../../store/slices/viewerFolderSlice";
import { removePayAsYouGoData } from "../../store/slices/payAsYouGoSlice";
import {
  removeCheckoutPlanData,
  removePlanData,
} from "../../store/slices/planSlice";
import { ownerSettingsService } from "../../_services";
import { removeloggedInUserRole } from "../../store/slices/dashboardSlice";
const TopNavbar = ({ handleClick }) => {
  const role = useAuth("role");
  const user = useAuth("user");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const style = {
    display: "flex",
    marginBottom: "0px",
    cursor: "pointer",
    listStyle: "none",
    padding: "0px",
    flexDirection: "column",
  };
  const handleLogout = () => {
    ownerSettingsService
      .signoutFromAllDevices(user?._id)
      .then((response) => {
        if (response.data.status == true) {
          toast.success("Your account has been logged out");
          // setIsActiveLoading(false);
          setTimeout(() => {
            dispatch(ownerLogoutAuth());
            dispatch(executorLogoutAuth());
            dispatch(viewerLogoutAuth());
            dispatch(setExecutorFoldersLogout());
            dispatch(setViewerFoldersLogout());
            dispatch(removePayAsYouGoData());
            dispatch(removePlanData());
            dispatch(removeCheckoutPlanData());
            dispatch(removeViewerOwner());
            dispatch(removeViewerIdArr());
            dispatch(removeloggedInUserRole());
            dispatch(removeViewerAsOwnerData());

            navigate("/");
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
        setTimeout(() => {
          dispatch(ownerLogoutAuth());
          dispatch(executorLogoutAuth());
          dispatch(viewerLogoutAuth());
          dispatch(setExecutorFoldersLogout());
          dispatch(setViewerFoldersLogout());
          dispatch(removePayAsYouGoData());
          dispatch(removePlanData());
          dispatch(removeCheckoutPlanData());
          dispatch(removeViewerOwner());
          dispatch(removeViewerIdArr());
          dispatch(removeloggedInUserRole());
          dispatch(removeViewerAsOwnerData());

          navigate("/");
        }, 1000);
      });
  };
  const extractInitials = (name) => {
    return name
      ?.split(" ")
      .map((part) => part.charAt(0))
      .join("");
  };

  const fullName = `${user?.userName}`;
  const avatarContent = extractInitials(fullName);
  const avatarContentForExecutor = extractInitials(
    user.firstName + " " + user.lastName
  );

  return (
    <>
      <div className="py-3 mobile-header">
        {/* <div className="d-flex justify-content-between"> */}
        <div className="d-flex justify-content-end top-mobile-menu">
          {/*------------------ DASHBOARD TABS---------------- */}
          {/* <DashboardTabs /> */}
          {/*------------------ DASHBOARD TABS-------------------------- */}
          <Button onClick={handleClick} className="top-nav-menu">
            <i className="ri-menu-line"></i>
          </Button>
          <div className="">
            <div className="top-nav-section">
              <i className="ri-notification-3-line"></i>
              <OverlayTrigger
                trigger="click"
                key="bottom"
                placement="bottom"
                rootClose 
                overlay={
                  <Popover id={`popover-positioned-bottom`}>
                    <Popover.Body className="py-0">
                      <ul style={style}>
                        <li className="py-2 mb-0 border-bottom">
                          <NavLink
                            to={role + "/myaccount"}
                            className="navigate-popover-pages"
                            onClick={() => document.body.click()}
                          >
                            Settings
                          </NavLink>
                        </li>
                        <li className="py-2 mb-0 border-bottom">
                          <NavLink
                            to="/privacy-policy"
                            className="navigate-popover-pages"
                            onClick={() => document.body.click()}
                          >
                            Privacy Policy
                          </NavLink>
                        </li>
                        <li className="py-2 mb-0 border-bottom">
                          <NavLink
                            to="/terms-and-conditions"
                            className="navigate-popover-pages"
                            onClick={() => document.body.click()}
                          >
                            Terms And Conditions
                          </NavLink>
                        </li>
                        <li className="py-2 mb-0">
                          <span>
                            <b>{user.email}</b>
                          </span>
                          <div className="mt-0">
                            <Badge bg="primary">{role}</Badge>
                          </div>
                        </li>
                        <hr className="m-0" />
                        <li className="py-2" onClick={handleLogout}>
                          <i
                            className="ri-logout-box-line me-1"
                            style={{ fontSize: "12px" }}
                          ></i>
                          <span>
                            <b>Logout</b>
                          </span>
                        </li>
                      </ul>
                    </Popover.Body>
                  </Popover>
                }
              >
                {user?.avatar ? (
                  <img className="username-icon" src={user?.avatar} alt="" />
                ) : (
                  <span className="username-icon">
                    {role == "owner" ? avatarContent : avatarContentForExecutor}
                  </span>
                )}
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopNavbar;
