import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  recordingData: [],
  screenShareData: [],
  recordVdoStep: {
    title: "",
    folderName: "",
    discription: "",
    scheduleTime: "",
  },
  setFieldValue: false,
};

const recordingFormSlice = createSlice({
  name: "recordingForm",
  initialState: initialState,
  reducers: {
    setBlobRecordingData: (state, action) => {
      state.recordingData = action.payload;
    },
    setRecordVdoStep1Data: (state, action) => {
      state.recordVdoStep = action.payload;
    },
    setScreenShareData: (state, action) => {
      state.screenShareData = action.payload;
    },
    setterFieldValue: (state, action) => {
      state.setFieldValue = action.payload;
    },
    emptyRecordedData: (state, action) => {
      state.recordingData = "";
      state.recordVdoStep = "";
    },
  },
});

export default recordingFormSlice.reducer;
export const {
  setBlobRecordingData,
  setRecordVdoStep1Data,
  setterFieldValue,
  setScreenShareData,
  emptyRecordedData,
} = recordingFormSlice.actions;
export const getRecordingFormData = (state) => state.recordingFormSlice;
