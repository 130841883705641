import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  video: "",
  mp3: "",
  document: "",
  image: "",
  depositAmount: "",
  dataStorageTotal: "",
  price: "",
  selectedPlanType: "PayAsYouGo",
};

const payAsYouGoSlice = createSlice({
  name: "payAsYouGo",
  initialState: initialState,
  reducers: {
    payAsYouGoData: (state, action) => {
      const {
        depositAmount,
        dataStorageTotal,
        price,
        video,
        mp3,
        document,
        image,
        selectedPlanType,
      } = action.payload;
      state.depositAmount = depositAmount || "";
      state.dataStorageTotal = dataStorageTotal || "";
      state.price = price || "";
      state.video = video || "";
      state.mp3 = mp3 || "";
      state.document = document || "";
      state.image = image || "";
      state.selectedPlanType = "PayAsYouGo";
    },
    removePayAsYouGoData: (state, action) => {
      state.depositAmount = "";
      state.dataStorageTotal = "";
      state.price = "";
      state.video = "";
      state.mp3 = "";
      state.document = "";
      state.image = "";
      state.selectedPlanType = "";
    },
  },
});

export default payAsYouGoSlice.reducer;
export const { payAsYouGoData, removePayAsYouGoData } = payAsYouGoSlice.actions;

export const getPayAsYouGoData = (state) => state.payAsYouGo;
