import { useEffect, useState } from "react";
import { Row, Col, Table, Card } from "react-bootstrap";
import DashboardHeading from "../../../components/DashboardHeading";
import ChangeView from "../../../components/ChangeView";
import { useSelector } from "react-redux";
import { getExecutorAuth } from "../../../store/slices/executerSlice";
import SkeletonLoader from "../../../components/SkeletonLoader";
import DataNotFound from "../../../components/DataNotFound";
import { useAuth } from "../../../_hooks/useAuth";
import { getExecutorFolder } from "../../../store/slices/executorFolderSlice";
import { useDeviceScreen } from "../../../components/DeviceScreen";
import { folderService } from "../../../_services";
import { useNavigate } from "react-router-dom";

const Executoralbum = () => {
  const user = useAuth("user");
  const { isMobile } = useDeviceScreen();

  const [isGridViewActive, setIsGridViewActive] = useState(true);
  const [executorAlbumData, setExecutorAlbumData] = useState([]);
  const [isLoadingForList, setIsLoadingForList] = useState(false);
  const executorFolderData = useSelector(getExecutorFolder);
  const executorData = useSelector(getExecutorAuth);
  const [nonImageAlbums, setNonImageAlbums] = useState([]);
  const [updateFileUrl, setUpdateFileUrl] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (executorFolderData?.executorFolders?.length > 0) {
      const mappedArray = executorFolderData?.executorFolders?.filter(
        (item) => {
          if (user?._id && item.viewableTo) {
            return item.viewableTo.some(
              (userObj) => userObj.userId === user._id
            );
          } else {
            return true;
          }
        }
      );

      setExecutorAlbumData(mappedArray || []);
    }
    // setExecutorAlbumData(executorFolderData?.executorFolders || []);
  }, [executorFolderData]);
  useEffect(() => {
    const checkThumbnailUrl = async (album) => {
      if (album?.thumbnailUrl) {
        try {
          await loadImage(album.thumbnailUrl);
          console.log("Thumbnail URL is an image:>", album.thumbnailUrl);
        } catch (error) {
          console.log("Thumbnail URL is not an image:>", album.thumbnailUrl);
          if (
            !nonImageAlbums.find(
              (nonImageAlbum) => nonImageAlbum._id === album._id
            )
          ) {
            setNonImageAlbums((prevAlbums) => [...prevAlbums, album]);
          }
        }
      }
    };

    executorAlbumData.forEach((album) => checkThumbnailUrl(album));
  }, [executorAlbumData]);

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => reject();
      img.src = url;
    });
  };

  useEffect(() => {
    if (nonImageAlbums?.length > 0) {
      handleUpdateAlbumThumbnailUrls(nonImageAlbums);
    }
  }, [nonImageAlbums]);

  const handleUpdateAlbumThumbnailUrls = (fileDataArray) => {
    const updatePromises = fileDataArray?.map((album) => {
      const ownerAlbumUrlData = {
        fileName: album?.thumbnailFilename,
        _id: album?._id,
        path: album?.path,
      };
      return folderService.updateFolderThumbNailUrl(ownerAlbumUrlData);
    });

    Promise.all(updatePromises)
      .then((responses) => {
        setUpdateFileUrl(Math.round());
        responses.forEach((response) => {
          if (response?.data?.status === true) {
            console.log("File URL updated successfully");
          }
        });
      })
      .catch((error) => {
        console.log("Network ERROR", error);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pb-3 border-bottom mb-3">
        <DashboardHeading heading="My Albums" />
        <ChangeView
          setIsGridViewActive={setIsGridViewActive}
          isGridViewActive={isGridViewActive}
        />
      </div>
      <div>
        <Row>
          <Col xl={6}>
            <div className="p-3 memory-view-container">
              <p className="mb-2">
                {`"Dearest ${executorData?.firstName || "Executor"},`}
              </p>
              <p className="mb-2">
                As you explore this album, I want you to feel the warmth of our
                shared moments. Each memory holds a piece of our journey
                together. May it bring a smile to your face, comfort to your
                heart, and a reminder of the love that will forever bind us. You
                are my greatest treasure, and these memories are my gift to you.
              </p>
              <p>{`With all my love, ${
                executorData?.ownerName || "Owner"
              } "`}</p>
            </div>
          </Col>
        </Row>
        {isLoadingForList ? (
          <SkeletonLoader />
        ) : executorAlbumData?.length === 0 ? (
          <DataNotFound isAlbum={true} />
        ) : (
          <>
            {!isGridViewActive || isMobile ? (
              <div className="grid-info-executor">
                <Row>
                  {executorAlbumData?.map((item, index) => (
                    <Col
                      lg={4}
                      xl={4}
                      md={6}
                      xs={12}
                      className="mb-3"
                      key={index}
                    >
                      <Card  onClick={() =>
                            navigate(
                              `/executor/${item?.folderName
                                ?.trim()
                                .replace(/\s+/g, "")}/${item._id}`
                            )
                          }
                          role="button">
                        <div
                          className="edit-icon d-flex align-items-right justify-content-end px-3 "
                         
                        >
                          {/* Additional actions can be added here */}
                        </div>
                        <Card.Body >
                          <div className="d-flex align-items-center gap-4 mb-3">
                            <div className="album_folder_section">
                              <img
                                src="/assets/folder.png"
                                className="album_folder_img"
                                alt=""
                              />
                              {item.thumbnailUrl && (
                                <img
                                  src={item?.thumbnailUrl}
                                  className="album_img"
                                  alt=""
                                />
                              )}
                            </div>
                            <div className="invite-user-info">
                              <div className="invite-user-detail">
                                <h5 className="text-capitalize">
                                  <b>{item?.folderName}</b>
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className="enabledisableInvite d-flex justify-content-center">
                            <img
                              className="w-30"
                              src="assets/message.svg"
                              alt=""
                            />
                            <div
                              className="invite-you"
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                className="w-30"
                                src="assets/message.svg"
                                alt=""
                              />{" "}
                              Notify Loved One
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            ) : (
              <div className="w-100">
                <div className="pb-3 mb-3">
                  <div className="table-container p-1">
                    <Table className="executer-table">
                      <thead>
                        <tr>
                          <th className="table-head-color">Album</th>
                          <th className="table-head-color">Publish</th>
                        </tr>
                      </thead>
                      <tbody>
                        {executorAlbumData?.map((item) => (
                          <tr
                            key={item._id}
                            onClick={() =>
                              navigate(
                                `/executor/${item?.folderName
                                  ?.trim()
                                  .replace(/\s+/g, "")}/${item._id}`
                              )
                            }
                            role="button"
                          >
                            <td>
                              <div className="album_folder_wrapper mb-0">
                                <div className="album_folder_section">
                                  <img
                                    src="/assets/folder.png"
                                    className="album_folder_img"
                                    alt=""
                                  />
                                  {item.thumbnailUrl && (
                                    <img
                                      src={item?.thumbnailUrl}
                                      className="album_img"
                                      alt=""
                                    />
                                  )}
                                </div>
                                <div className="album_folder_detail">
                                  <div className="album_folder_detail">
                                    <h5 className="text-capitalize">
                                      <b>{item?.folderName}</b>
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center gap-2">
                                <svg
                                  width="24"
                                  height="14"
                                  viewBox="0 0 24 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  {/* Publish icon */}
                                </svg>
                                <span className="notify">Notify Loved One</span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Executoralbum;
