import React, { useEffect, useState } from "react";
import TopNavbar from "../TopNavbar";
import SideBar from "../SideBar";
import TabsBottom from "../MobTabs";
import { useDispatch, useSelector } from "react-redux";

import { useAuth } from "../../_hooks/useAuth";
import { useNavigate } from "react-router";
import { authService } from "../../_services";
import { ownerLogoutAuth } from "../../store/slices/ownerSlice";
import { executorLogoutAuth } from "../../store/slices/executerSlice";
import { viewerLogoutAuth } from "../../store/slices/viewerSlice";
import {
  getLoggedInUserDashBoardData,
  removeloggedInUserRole,
} from "../../store/slices/dashboardSlice";
import { EXECUTOR, OWNER } from "../../_helpers";
import { setExecutorFoldersLogout } from "../../store/slices/executorFolderSlice";
import {
  removeViewerAsOwnerData,
  removeViewerIdArr,
  removeViewerOwner,
  setViewerFoldersLogout,
} from "../../store/slices/viewerFolderSlice";
import { removePayAsYouGoData } from "../../store/slices/payAsYouGoSlice";
import {
  removeCheckoutPlanData,
  removePlanData,
} from "../../store/slices/planSlice";
const AuthLayout = ({ children }) => {
  const user = useAuth("user");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addClass, setAddClass] = useState(true);
  useEffect(() => {
    if (user.socialProvider == null)
      authService
        .authCheck(user.token)
        .then((res) => {
          console.log("Token Validated");
        })
        .catch((err) => {
          console.log(err, "Error auth");
          dispatch(ownerLogoutAuth());
          dispatch(executorLogoutAuth());
          dispatch(viewerLogoutAuth());
          dispatch(executorLogoutAuth());
          dispatch(viewerLogoutAuth());
          dispatch(setExecutorFoldersLogout());
          dispatch(setViewerFoldersLogout());
          dispatch(removePayAsYouGoData());
          dispatch(removePlanData());
          dispatch(removeCheckoutPlanData());
          dispatch(removeViewerOwner());
          dispatch(removeViewerIdArr());
          dispatch(removeViewerAsOwnerData());

          navigate("/");
        });
  }, []);
  const handleClick = () => {
    setAddClass(!addClass);
    document.body.click();
    if (user.socialProvider == null)
      authService
        .authCheck(user.token)
        .then((res) => {
          console.log("Token Validated");
        })
        .catch((err) => {
          console.log(err, "Error auth");
          dispatch(ownerLogoutAuth());
          dispatch(removeloggedInUserRole());

          dispatch(executorLogoutAuth());
          dispatch(viewerLogoutAuth());
          dispatch(setExecutorFoldersLogout());
          dispatch(setViewerFoldersLogout());
          dispatch(removePayAsYouGoData());
          dispatch(removePlanData());
          dispatch(removeCheckoutPlanData());
          dispatch(removeViewerOwner());
          dispatch(removeViewerIdArr());
          dispatch(removeViewerAsOwnerData());

          navigate("/");
        });
  };
  const loggedInUserRole = useSelector(getLoggedInUserDashBoardData);
  return (
    <>
      <div className="dashboard-layout">
        <div className="dashboard-wrapper">
          <div
            className={
              addClass ? "dashboard-sidebar" : "dashboard-sidebar iss-open"
            }
          >
            <SideBar
              handleClick={handleClick}
              addClass={addClass}
              setAddClass={setAddClass}
            />
          </div>
          {addClass ? (
            ""
          ) : (
            <div onClick={handleClick} className="sidebar-overlay"></div>
          )}
          <div className="dashboard-main-content px-3">
            <TopNavbar handleClick={handleClick} />
            <main className="dashboard-inner-content-wrapper mt-4">
              {children}
            </main>
          </div>
        </div>
        {loggedInUserRole &&
          [OWNER, EXECUTOR].indexOf(loggedInUserRole.role) !== -1 && (
            <TabsBottom />
          )}
      </div>
    </>
  );
};

export default AuthLayout;
