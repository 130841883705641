/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import FolderModal from "../FolderModal";
import VideoModal from "../VideoModal";
import { useAuth } from "../../_hooks/useAuth";
import { EXECUTOR, OWNER, VIEWER } from "../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  emptyRecordedData,
  setRecordVdoStep1Data,
  setterFieldValue,
} from "../../store/slices/recordingFormSlice";
import {
  executorService,
  ownerSettingsService,
  planServices,
} from "../../_services";
import { ownerAvatar } from "../../store/slices/ownerSlice";
import PassingDateModal from "../Modal/PassingDateModal";
import VerifyProfileAndManagePaymentPopUp from "./verifyProfileBuyPlanAlert";
import { getLoggedInUserDashBoardData } from "../../store/slices/dashboardSlice";
import DashboardTabs from "../DashboardTabs";
import MbToSideSection from "../MobSideTopSec";
import { viewerAvatarUpdate } from "../../store/slices/viewerSlice";
import {
  executorAvatarUpdate,
  getExecutorAsOwnerData,
} from "../../store/slices/executerSlice";
import { useDeviceScreen } from "../DeviceScreen";
import { setPlanData } from "../../store/slices/planSlice";
import {
  getViewerOwner,
  getViwerAsOwnerData,
} from "../../store/slices/viewerFolderSlice";

const SideBar = ({ handleClick, addClass, setAddClass }) => {
  const { isMobile } = useDeviceScreen();

  const role = useAuth("role");
  const user = useAuth("user");
  const loggedInUSerRole = useSelector(getLoggedInUserDashBoardData);
  const viewerOwnerStore = useSelector(getViewerOwner);
  const viewerAsOwnerDataStore = useSelector(getViwerAsOwnerData);

  const executorAsOwnerDataStore = useSelector(getExecutorAsOwnerData);
  useEffect(() => {
    if (loggedInUSerRole?.role === OWNER) {
      ExecutorLinks();
    }
    if (loggedInUSerRole?.role === EXECUTOR) {
      ExecutorLinks();
      getExecutorOwner(user);
    }
    if (loggedInUSerRole?.role === VIEWER) {
      ViewerLinks();
      getViewerOwnersArr();
    }
  }, [loggedInUSerRole]);
  const getViewerOwnersArr = () => {
    if (viewerOwnerStore) {
      setViewerOwnerArr(viewerOwnerStore || []);
    }
  };
  const dispatch = useDispatch();
  const [subscriptionCurrentPlanData, setSubscriptionCurrentPlanData] =
    useState({});
  const [loading, setLoading] = useState(false);
  const [totalStorageLimit, setTotalStorageLimit] = useState(0);
  const [useSpace, setUseSpace] = useState(0);
  const [usedPercentage, setUsedPercentage] = useState(0);
  const [show, setShow] = useState(false);
  const [nonImageAvatar, setNonImageAvatar] = useState();
  const [executorOwnerArr, setExecutorOwnerArr] = useState([]);
  const [demisedOwnerData, setDemisedOwnerData] = useState({});
  const [viewerOwnerArr, setViewerOwnerArr] = useState([]);
  const handleClose = () => {
    setShow(false);
    dispatch(setterFieldValue(false));
    dispatch(setRecordVdoStep1Data());
    dispatch(emptyRecordedData());
  };
  const navigate = useNavigate();
  const [showRecordingModel, setShowRecordingModel] = useState(false);
  const [recordingData, setRecordingData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();

  const [activePage, setActivePage] = useState("");
  const [showPaymentVerificationPopup, setShowPaymentVerificationPopup] =
    useState(false);

  const handleClosePaymentVerificationPopup = () =>
    setShowPaymentVerificationPopup(false);
  const handleShowPaymentVerificationPopup = () =>
    setShowPaymentVerificationPopup(true);

  useEffect(() => {
    // Check if user object and avatar URL exist
    if (user) {
      const img = new Image();
      img.onload = function () {
        console.log("Avatar URL is an image:", user.avatar);
      };
      img.onerror = function () {
        console.log("Avatar URL is not an image:", user.avatar);
        setNonImageAvatar(user.avatar);
      };
      img.src = user.avatar;
    }
  }, [user]);

  useEffect(() => {
    // When nonImageAvatar state changes, update the expired profile picture URL
    if (nonImageAvatar) {
      handleUpdateExpiredProPicFileUrl();
    }
  }, [nonImageAvatar]);

  const handleUpdateExpiredProPicFileUrl = () => {
    if (!user || !user.fileName || !user.avatar) return;

    const ownerAlbumUrlData = {
      role: user?.role,
      fileName: user.fileName,
    };
    // Update the profile picture URL via an API call
    ownerSettingsService
      .updateProfileImageURL(user._id, ownerAlbumUrlData)
      .then((response) => {
        if (response?.data?.status === true) {
          if (user.role === OWNER) {
            dispatch(ownerAvatar({ avatar: response.data.data.avatar }));
          } else if (user.role === EXECUTOR) {
            dispatch(
              executorAvatarUpdate({ avatar: response.data.data.avatar })
            );
          } else {
            dispatch(viewerAvatarUpdate({ avatar: response.data.data.avatar }));
          }
        }
      })
      .catch((error) => {
        console.log("Network ERROR", error);
      });
  };

  // CURRENT PLAN START
  useEffect(() => {
    if (user) {
      if (role == OWNER) {
        getCurrentPlan(user?._id);
      }
      if (role == VIEWER && viewerAsOwnerDataStore) {
        getCurrentPlan(viewerAsOwnerDataStore?._id);
      }
      if (role == EXECUTOR && executorAsOwnerDataStore) {
        getCurrentPlan(executorAsOwnerDataStore?._id);
      }
    }
  }, []);

  const getCurrentPlan = (LoggedInId) => {
    planServices
      .getOwnerCurrentSubscription(LoggedInId, user?.token)
      .then((response) => {
        if (response?.data?.status === true) {
          dispatch(
            setPlanData({
              selectedPlanType: response?.data?.data?.selectedPlanType,
            })
          );
          setSubscriptionCurrentPlanData(response?.data?.data);
          const totalStorage =
            parseInt(response?.data?.data?.features?.storageLimit) *
            1024 *
            1024 *
            1024;
          const percentage = (useSpace / totalStorage) * 100;
          setUsedPercentage(percentage <= 100 ? percentage : 100);
          setTotalStorageLimit(totalStorage);
          setUseSpace(response?.data?.data?.spaceUsed);
        }
      })
      .catch((error) => {
        console.error("Error fetching subscription data:", error.message);
      });
  };
  // CURRENT PLAN ENDS
  useEffect(() => {
    setActivePage(location.pathname.replace("/", ""));
  }, [location.pathname]);
  const currentURL = location.pathname;
  const [currenUrlForExecutorOwner, setCurrenUrlForExecutorOwner] =
    useState("");
  const [currenUrlForViewerOwner, setCurrenUrlForViewerOwner] = useState("");
  // Executor Owner
  const getExecutorOwner = (user) => {
    setLoading(true);
    if (user?.email) {
      executorService
        .getExecutorOwners(user?.email)
        .then((res) => {
          if (res) {
            setExecutorOwnerArr(res?.data?.data?.executorData || []);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err, "ERR IN FETCHING EXECUTOR'S OWNER");
          setLoading(false);
        });
    }
  };
  const handleShowDemisePopUp = (owner) => {
    if (owner) {
      setDemisedOwnerData(owner);
    }
    setAddClass(true);
    setShowModal(true);
    setActivePage(`${owner?.userName}/${owner?._id}`);
    setCurrenUrlForExecutorOwner(`${owner?.userName}/${owner?._id}`);
  };
  const handleShow = () => {
    setShow(true);
    console.log(user, "user-->>");

    if (user) {
      if (role == OWNER) {
        getCurrentPlan(user?._id);
      }
      if (role == VIEWER && viewerAsOwnerDataStore) {
        getCurrentPlan(viewerAsOwnerDataStore?._id);
      }
      if (role == EXECUTOR && executorAsOwnerDataStore) {
        getCurrentPlan(executorAsOwnerDataStore?._id);
      }
    }
  };

  const OwnerLinks = () => {
    const routes = [
      "/owner/myaccount",
      "/owner/billingPlans",
      "/owner/dailyUsage",
    ];
    return (
      <>
        {!routes.includes(currentURL) ? (
          <div className="sidebar-list">
            {user?.isProfileCompleted === true && (
              <Button onClick={handleShow} className="sidebar-addmemory">
                <i className="ri-add-fill"></i> New Memory
              </Button>
            )}
            {user?.isProfileCompleted === false && (
              <Button
                onClick={handleShowPaymentVerificationPopup}
                className="sidebar-addmemory"
              >
                <i className="ri-add-fill"></i> New Memory
              </Button>
            )}
            <ul>
              <li
                className={activePage === `${OWNER}/dashboard` ? "active" : ""}
              >
                <NavLink onClick={handleClick} to={`/${OWNER}/dashboard`}>
                  <img src="/assets/sidebar-icon/home.png" alt="Home" /> Home
                </NavLink>
              </li>
              <li className={activePage === `${OWNER}/albums` ? "active" : ""}>
                <NavLink onClick={handleClick} to={`/${OWNER}/albums`}>
                  <img src="/assets/sidebar-icon/gallery.png" alt="Albums" /> My
                  Albums
                </NavLink>
              </li>
              <li
                className={activePage === `${OWNER}/memories` ? "active" : ""}
              >
                <NavLink onClick={handleClick} to={`/${OWNER}/memories`}>
                  <img
                    src="/assets/sidebar-icon/gallery.png"
                    alt="My Memories"
                  />
                  My Memories
                </NavLink>
              </li>
            </ul>
            <ul>
              <li
                className={activePage === `${OWNER}/loved-ones` ? "active" : ""}
              >
                <NavLink onClick={handleClick} to={`/${OWNER}/loved-ones`}>
                  <img src="/assets/sidebar-icon/users.png" alt="Loved ones" />{" "}
                  Loved ones
                </NavLink>
              </li>
              <li
                className={activePage === `${OWNER}/executors` ? "active" : ""}
              >
                <NavLink onClick={handleClick} to={`/${OWNER}/executors`}>
                  <img src="/assets/sidebar-icon/user.png" alt="Executor" />{" "}
                  Executors
                </NavLink>
              </li>
            </ul>
            <ul></ul>
            {
              <DashboardTabs
                activePage={activePage}
                handleClick={handleClick}
              />
            }
            <ul>
              <li className={activePage === `${OWNER}/trash` ? "active" : ""}>
                <NavLink onClick={handleClick} to={`/${OWNER}/trash`}>
                  <img src="/assets/sidebar-icon/trash.png" alt="Trash" /> Trash
                </NavLink>
              </li>
            </ul>

            {isMobile && (
              <div>
                <ul>
                  <li
                    className={
                      currentURL === "/owner/myaccount" ? "active" : ""
                    }
                  >
                    <NavLink onClick={handleClick} to={`/${OWNER}/myaccount`}>
                      <img src="/assets/person.png" alt="person" />
                      Settings
                      <svg
                        width="7"
                        height="12"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.03026 5.99262L0.319869 2.03616C0.114622 1.80955 0.0010519 1.50606 0.00361919 1.19104C0.00618649 0.876013 0.124686 0.574668 0.333594 0.351904C0.542503 0.129141 0.825107 0.00278289 1.12054 4.5419e-05C1.41597 -0.00269205 1.70059 0.11841 1.9131 0.337269L6.4201 5.14317C6.63134 5.36848 6.75 5.67403 6.75 5.99262C6.75 6.31121 6.63134 6.61675 6.4201 6.84206L1.9131 11.648C1.80864 11.7598 1.68448 11.8484 1.54776 11.9088C1.41105 11.9692 1.26447 12.0002 1.11648 12C0.8952 11.9963 0.679758 11.9237 0.496707 11.7911C0.313657 11.6585 0.170999 11.4716 0.0863137 11.2536C0.00162888 11.0356 -0.0213814 10.7959 0.0201192 10.5641C0.0616193 10.3323 0.165817 10.1185 0.319869 9.94908L4.03026 5.99262Z"
                          fill="black"
                        />
                      </svg>
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}
            <div className="storage-section">
              <NavLink onClick={handleClick} to="#">
                <img src="/assets/sidebar-icon/storage.png" alt="Storage" />{" "}
                Storage
              </NavLink>
              <div className="storage-progress-bar">
                <div
                  className="storage-progress-bar-indicator"
                  style={{ width: `${usedPercentage}%` }}
                ></div>
              </div>
              <p className="text-muted">
                {`${
                  isNaN(useSpace / (1024 * 1024 * 1024))
                    ? "0"
                    : (useSpace / (1024 * 1024 * 1024)).toFixed(2)
                } GB of ${
                  subscriptionCurrentPlanData?.selectedPlanType === "PayAsYouGo"
                    ? "Unlimited"
                    : isNaN(totalStorageLimit / (1024 * 1024 * 1024))
                    ? "0.00"
                    : (totalStorageLimit / (1024 * 1024 * 1024)).toFixed(2)
                } GB Used`}
              </p>
            </div>
          </div>
        ) : (
          <div className="sidebar-list">
            <ul>
              <li className={activePage === "loved" ? "active" : ""}>
                <span className="settings-heading">
                  <b>Settings</b>
                </span>
              </li>
              <li className={currentURL === "/owner/myaccount" ? "active" : ""}>
                <NavLink onClick={handleClick} to={`/${OWNER}/myaccount`}>
                  <img src="/assets/person.png" alt="person" /> My Account
                </NavLink>
              </li>
              <li
                className={currentURL === "/owner/billingPlans" ? "active" : ""}
              >
                <NavLink onClick={handleClick} to={`/${OWNER}/billingPlans`}>
                  <img src="/assets/card.png" alt="card" /> Billing & Plans
                </NavLink>
              </li>
              {subscriptionCurrentPlanData?.selectedPlanType ===
                "PayAsYouGo" && (
                <li
                  className={currentURL === "/owner/dailyUsage" ? "active" : ""}
                >
                  <NavLink onClick={handleClick} to={`/${OWNER}/dailyUsage`}>
                    <img src="/assets/card.png" alt="card" /> Daily Usage
                  </NavLink>
                </li>
              )}
            </ul>
            {
              <DashboardTabs
                activePage={activePage}
                handleClick={handleClick}
              />
            }
          </div>
        )}
      </>
    );
  };
  const ViewerLinks = () => {
    const routes = [
      "/viewer/myaccount",
      "/viewer/billingPlans",
      "/viewer/dailyUsage",
    ];
    return (
      <>
        <div className="sidebar-list">
          {!routes.includes(currentURL) ? (
            <>
              {/* <ul>
                <li className={activePage === "viewer/albums" ? "active" : ""}>
                  <NavLink
                    to={`/${VIEWER}/albums`}
                    onClick={() => setAddClass(true)}
                  >
                    <img src="/assets/sidebar-icon/gallery.png" alt="Albums" />{" "}
                    My Albums
                  </NavLink>
                </li>
              </ul>  */}
              {/* Viewer's Owner */}
              {
                <ul>
                  {!loading ? (
                    viewerOwnerArr?.map((owner, index) => {
                      return (
                        <li
                          className={
                            currenUrlForViewerOwner ===
                              `${owner?.userName}/${owner?._id}` &&
                            activePage === `${owner?.userName}/${owner?._id}`
                              ? "active"
                              : ""
                          }
                          key={index}
                        >
                          {
                            <NavLink
                              to={`${owner?.userName}/${owner?._id}`}
                              onClick={() => {
                                setCurrenUrlForViewerOwner(
                                  `${owner?.userName}/${owner?._id}`
                                );
                                handleClick();
                              }}
                            >
                              {" "}
                              <>
                                <img
                                  src="/assets/sidebar-icon/user.png"
                                  alt="Executor owner"
                                />{" "}
                                <span
                                  className="text-capitalize text-truncate"
                                  title={owner?.userName + " " + "Albums"}
                                >
                                  {owner?.userName}'s
                                </span>
                                Albums
                              </>
                            </NavLink>
                          }
                        </li>
                      );
                    })
                  ) : (
                    <div className="text-center">
                      <Spinner animation="grow" variant="primary" />
                      <Spinner animation="grow" variant="primary" />
                      <Spinner animation="grow" variant="primary" />
                      <Spinner animation="grow" variant="primary" />
                    </div>
                  )}
                  {user.isPlanPurchased == true && (
                    <DashboardTabs activePage={activePage} />
                  )}
                </ul>
              }
            </>
          ) : (
            <>
              <ul>
                <li
                  className={
                    activePage === "viewer/myaccount" ||
                    activePage === "viewer/memories"
                      ? "active"
                      : ""
                  }
                >
                  <NavLink
                    to={`/${VIEWER}/myaccount`}
                    onClick={() => {
                      setAddClass(true);
                      handleClick();
                    }}
                  >
                    <img
                      src="/assets/sidebar-icon/users.png"
                      alt="Loved ones"
                    />{" "}
                    My Account
                  </NavLink>
                </li>
                {viewerAsOwnerDataStore && (
                  <li
                    className={
                      currentURL === "/executor/billingPlans" ? "active" : ""
                    }
                  >
                    <NavLink
                      onClick={handleClick}
                      to={`/${VIEWER}/billingPlans`}
                    >
                      <img src="/assets/card.png" alt="card" /> Billing & Plans
                    </NavLink>
                  </li>
                )}
                {viewerAsOwnerDataStore &&
                  subscriptionCurrentPlanData?.selectedPlanType ===
                    "PayAsYouGo" && (
                    <li
                      className={
                        currentURL === "/executor/dailyUsage" ? "active" : ""
                      }
                    >
                      <NavLink
                        onClick={handleClick}
                        to={`/${VIEWER}/dailyUsage`}
                      >
                        <img src="/assets/card.png" alt="card" /> Daily Usage
                      </NavLink>
                    </li>
                  )}
              </ul>
              {user.isPlanPurchased == true && (
                <DashboardTabs activePage={activePage} />
              )}
            </>
          )}
        </div>
      </>
    );
  };

  const ExecutorLinks = () => {
    const routes = [
      "/executor/myaccount",
      "/executor/billingPlans",
      "/executor/dailyUsage",
    ];
    return (
      <>
        <div className="sidebar-list">
          {!routes.includes(currentURL) ? (
            <>
              <ul>
                <li
                  className={activePage == "executor/dashboard" ? "active" : ""}
                >
                  <NavLink
                    to={`/${EXECUTOR}/dashboard`}
                    onClick={() => {
                      setAddClass(true);
                      handleClick();
                    }}
                  >
                    <img src="/assets/sidebar-icon/home.png" alt="Home" /> Home
                  </NavLink>
                </li>
                <li
                  className={activePage === `executor/albums` ? "active" : ""}
                >
                  <NavLink
                    to={`/${EXECUTOR}/albums`}
                    onClick={() => {
                      setAddClass(true);
                      handleClick();
                    }}
                  >
                    <img src="/assets/sidebar-icon/gallery.png" alt="Albums" />{" "}
                    My Albums
                  </NavLink>
                </li>
              </ul>
              <ul>
                <li
                  className={
                    activePage === `executor/loved-ones` ? "active" : ""
                  }
                >
                  <NavLink
                    to={`/${EXECUTOR}/loved-ones`}
                    onClick={() => {
                      setAddClass(true);
                      handleClick();
                    }}
                  >
                    <img
                      src="/assets/sidebar-icon/users.png"
                      alt="Loved ones"
                    />{" "}
                    Loved ones
                  </NavLink>
                </li>
              </ul>
              {/* Executor's Owner */}
              {
                <ul>
                  {!loading ? (
                    executorOwnerArr?.map((owner, index) => {
                      return (
                        <li
                          className={
                            currenUrlForExecutorOwner ===
                              `${owner?.userName}/${owner?._id}` &&
                            activePage === `${owner?.userName}/${owner?._id}`
                              ? "active"
                              : ""
                          }
                          key={index}
                        >
                          {
                            <NavLink
                              to={`${owner?.userName}/${owner?._id}`}
                              onClick={() => {
                                handleShowDemisePopUp(owner);
                                handleClick();
                              }}
                            >
                              {" "}
                              <>
                                <img
                                  src="/assets/sidebar-icon/user.png"
                                  alt="Executor owner"
                                />{" "}
                                <span className="text-capitalize">
                                  {owner?.userName}'s
                                </span>
                                Albums
                              </>
                            </NavLink>
                          }
                        </li>
                      );
                    })
                  ) : (
                    <div className="text-center">
                      <Spinner animation="grow" variant="primary" />
                      <Spinner animation="grow" variant="primary" />
                      <Spinner animation="grow" variant="primary" />
                      <Spinner animation="grow" variant="primary" />
                    </div>
                  )}
                </ul>
              }
              {user.isPlanPurchased == true && (
                <DashboardTabs activePage={activePage} />
              )}
            </>
          ) : (
            <>
              <ul>
                <li
                  className={
                    activePage === "executor/myaccount" ? "active" : ""
                  }
                >
                  <NavLink
                    to={`/${EXECUTOR}/myaccount`}
                    onClick={() => {
                      setAddClass(true);
                      handleClick();
                    }}
                  >
                    <img
                      src="/assets/sidebar-icon/users.png"
                      alt="Loved ones"
                    />{" "}
                    My Account
                  </NavLink>
                </li>
                {user.executorLoginDataForOwner != null && (
                  <li
                    className={
                      currentURL === "/executor/billingPlans" ? "active" : ""
                    }
                  >
                    <NavLink
                      onClick={handleClick}
                      to={`/${EXECUTOR}/billingPlans`}
                    >
                      <img src="/assets/card.png" alt="card" /> Billing & Plans
                    </NavLink>
                  </li>
                )}
                {user.executorLoginDataForOwner != null &&
                  subscriptionCurrentPlanData?.selectedPlanType ===
                    "PayAsYouGo" && (
                    <li
                      className={
                        currentURL === "/executor/dailyUsage" ? "active" : ""
                      }
                    >
                      <NavLink
                        onClick={handleClick}
                        to={`/${EXECUTOR}/dailyUsage`}
                      >
                        <img src="/assets/card.png" alt="card" /> Daily Usage
                      </NavLink>
                    </li>
                  )}
              </ul>
              {user.isPlanPurchased == true && (
                <DashboardTabs
                  activePage={activePage}
                  handleClick={handleClick}
                />
              )}
            </>
          )}
        </div>
      </>
    );
  };
  const handleLogoClick = () => {
    handleClick();
    if (role === OWNER) {
      navigate("/owner/dashboard");
    } else if (role === VIEWER) {
      navigate(
        viewerOwnerArr?.length > 0
          ? `/${viewerOwnerArr?.[0]?.userName}/${viewerOwnerArr?.[0]?._id}`
          : `/${role}/albums`
      );
    } else if (role === EXECUTOR) {
      navigate("/executor/dashboard");
    }
  };

  return (
    <>
      <aside className="py-3">
        <MbToSideSection></MbToSideSection>
        <div className="text-center">
          <img
            alt=""
            className="sidebarlogo"
            src="/logo.png"
            onClick={handleLogoClick}
            style={{ cursor: "pointer" }}
          />
        </div>
        {loggedInUSerRole?.role === OWNER && (
          <>
            <OwnerLinks />
          </>
        )}
        {role === VIEWER && (
          <>
            <ViewerLinks />
          </>
        )}
        {role === EXECUTOR && (
          <>
            <ExecutorLinks />
          </>
        )}
      </aside>
      {showModal && (
        <PassingDateModal
          show={showModal}
          demisedOwnerData={demisedOwnerData}
          onHide={() => setShowModal(false)}
        />
      )}
      {show && !showRecordingModel && (
        <Modal
          className=" create-folder-modal"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Body className="py-5 px-4">
            <FolderModal
              show={show}
              setShow={setShow}
              handleClose={handleClose}
              handleShow={handleShow}
              showRecordingModel={showRecordingModel}
              setShowRecordingModel={setShowRecordingModel}
              recordingData={recordingData}
              setRecordingData={setRecordingData}
              user={user}
              subscriptionCurrentPlanData={subscriptionCurrentPlanData}
            />
          </Modal.Body>
        </Modal>
      )}
      {showRecordingModel && !show && (
        <Modal
          className=" create-folder-modal"
          show={showRecordingModel}
          onHide={() => setShowRecordingModel(false)}
          centered
        >
          <Modal.Body className="py-5 px-4">
            <VideoModal
              showRecordingModel={showRecordingModel}
              setShowRecordingModel={setShowRecordingModel}
              recordingData={recordingData}
              setRecordingData={setRecordingData}
              show={show}
              setShow={setShow}
            />
          </Modal.Body>
        </Modal>
      )}
      {showPaymentVerificationPopup && (
        <VerifyProfileAndManagePaymentPopUp
          showPaymentVerificationPopup={showPaymentVerificationPopup}
          setShowPaymentVerificationPopup={setShowPaymentVerificationPopup}
          handleClosePaymentVerificationPopup={
            handleClosePaymentVerificationPopup
          }
          handleShowPaymentVerificationPopup={
            handleShowPaymentVerificationPopup
          }
        />
      )}
    </>
  );
};

export default SideBar;
