import React, { createContext, useState, useEffect, useContext } from 'react';

const DeviceScreenContext = createContext();

export const useDeviceScreen = () => useContext(DeviceScreenContext);

export const DeviceScreenProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); 
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <DeviceScreenContext.Provider value={{ isMobile }}>
      {children}
    </DeviceScreenContext.Provider>
  );
};
