/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import DashboardHeading from "../../../components/DashboardHeading";
import { Button, Row, Col, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { useAuth } from "../../../_hooks/useAuth";
import { planServices } from "../../../_services";
import moment from "moment";
import Loader from "../../../components/Loader";
import SubscriptionHistory from "../../../pages/forevermems/billingPlans/subscription-history-popup";
import { setPlanData } from "../../../store/slices/planSlice";
import { useDispatch } from "react-redux";
const CurrentPlanDetails = function () {
  const user = useAuth("user");
  const params = useParams();
  const ownerId = params.id;
  const ownerName = params.ownerName;
  const [show, setShow] = useState(false);
  const [subscriptionHistoryData, setSubscriptionHistoryData] = useState([]);
  const [subscriptionCurrentPlanData, setSubscriptionCurrentPlanData] =
    useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
const dispatch =useDispatch()
  //  getting current subscription
  useEffect(() => {
    if (ownerId && user?.token) {
      getCurrentPlan(ownerId, user?.token);
    }
  }, [ownerId]);
  const getCurrentPlan = () => {
    setLoading(true);
    planServices
      .getOwnerCurrentSubscription(ownerId, user?.token)
      .then((response) => {
        if (response?.data?.status === true) {
          setSubscriptionCurrentPlanData(response?.data?.data);
          dispatch(
            setPlanData({
              selectedPlanType: response?.data?.data?.selectedPlanType,
            })
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching subscription data:", error.message);
        setLoading(false);
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    if (user) {
      // subs history
      getSubscriptionHistory(user?.token);
    }
  };

  const getSubscriptionHistory = () => {
    setLoading(true);
    planServices
      .getSubscriptionHistory(user?.token)
      .then((response) => {
        if (response?.data?.status === true) {
          setSubscriptionHistoryData(response?.data?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching subscription data:", error.message);
        setLoading(false);
      });
  };
  // Function to convert bytes to gigabytes
  function bytesToGB(bytes) {
    return bytes / (1024 * 1024 * 1024); // 1024 bytes in a kilobyte, 1024 kilobytes in a megabyte, and 1024 megabytes in a gigabyte
  }

  // Get storage limit and used space
  const storageLimit = parseFloat(
    subscriptionCurrentPlanData?.features?.storageLimit
  );
  const usedSpaceBytes = parseFloat(subscriptionCurrentPlanData?.spaceUsed);

  // Convert used space from bytes to gigabytes
  const usedSpaceGB = bytesToGB(usedSpaceBytes);

  // Calculate remaining space in gigabytes
  const remainingSpace =
    isNaN(storageLimit) || isNaN(usedSpaceGB)
      ? "Invalid data"
      : storageLimit - usedSpaceGB;
  let formattedRemainingSpace;
  if (typeof remainingSpace === "number") {
    formattedRemainingSpace = remainingSpace.toFixed(2) + " GB";
  } else {
    formattedRemainingSpace = remainingSpace; // Set to "Invalid data"
  }

  let totalDataUsed = (
    subscriptionCurrentPlanData?.spaceUsed /
    (1024 * 1024 * 1024)
  ).toFixed(2);

  return (
    <>
      {
        <>
          <div className="d-flex align-items-center pb-3 border-bottom mb-3">
            <div className="d-flex">
              <DashboardHeading
                heading={
                  ownerName
                    ? `${ownerName}'s Current Plan`
                    : "Current Plan Details"
                }
              />
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            //  user?.isProfileCompleted !== true ? (

            <div className="billing-container">
              <Row>
                <Col md={12}>
                  <div>
                    <div className="d-flex justify-content-center">
                      <div className="user-info w-50">
                        <h3 className="user-label">
                          {subscriptionCurrentPlanData?.selectedPlanType}
                        </h3>
                        <p className="user-txt">
                          Some info about{" "}
                          {subscriptionCurrentPlanData?.selectedPlanType} Plan
                        </p>
                        <Card className="mt-2">
                          <Card.Body>
                            <div className="d-flex align-items-start gap-3">
                              <img width={50} src="/billing.png" alt=""></img>
                              <div>
                                <h5>
                                  {
                                    subscriptionCurrentPlanData?.selectedPlanType
                                  }
                                </h5>
                                <small className="text-muted">
                                  Next bill on{" "}
                                  {subscriptionCurrentPlanData?.selectedPlanType !==
                                  "Free"
                                    ? moment(
                                        subscriptionCurrentPlanData?.nextPaymentDate
                                      ).format("MM/DD/YYYY")
                                    : "N/A"}
                                  <br />
                                  Discounted Price{" "}
                                  {subscriptionCurrentPlanData?.selectedPlanType !==
                                  "Free"
                                    ? `$${subscriptionCurrentPlanData?.discountPrice}`
                                    : "N/A"}
                                </small>
                              </div>
                            </div>
                            <div className="p-3 border-top mt-3 text-muted">
                              {
                                <p>
                                  Data storage:{" "}
                                  <b>
                                    {" "}
                                    {
                                      subscriptionCurrentPlanData?.features
                                        ?.storageLimit
                                    }{" "}
                                  </b>
                                </p>
                              }

                              {subscriptionCurrentPlanData?.selectedPlanType !==
                                "PayAsYouGo" && (
                                <p>
                                  Total data storage Available:{" "}
                                  <b>
                                    {subscriptionCurrentPlanData?.selectedPlanType !==
                                    "PayAsYouGo"
                                      ? formattedRemainingSpace
                                      : "N/A"}
                                  </b>
                                </p>
                              )}
                              <p>
                                Total data storage Used:{" "}
                                <b>{totalDataUsed + " GB"}</b>
                              </p>
                            </div>
                            {subscriptionCurrentPlanData?.expired && (
                              <div className="mt-3 text-end">
                                <Button
                                  className="payment-button"
                                  variant="primary-outline"
                                  // onClick={() => {
                                  //   navigate("/owner/plans");
                                  // }}
                                >
                                  Renew{" "}
                                </Button>
                              </div>
                            )}
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </>
      }
    </>
  );
};
export default CurrentPlanDetails;
