import React from "react";
import { useDeviceScreen } from "../DeviceScreen";

const ChangeView = ({ isGridViewActive, setIsGridViewActive }) => {
  const { isMobile } = useDeviceScreen();

  return (
    <div className="change_view">
      <i
        onClick={() => setIsGridViewActive(true)}
        className={`ri-list-check ${isGridViewActive ? "active" : ""}`}
      ></i>
      <i
        onClick={() => setIsGridViewActive(false)}
        className={`ri-layout-grid-fill ${
          isGridViewActive && !isMobile ? "" : "active"
        }`}
      ></i>
    </div>
  );
};

export default ChangeView;
