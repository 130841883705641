import axios from "axios";
export const albumPrivacyService = {
  manageAlbumPrivacy,
  manageUserPrivacySettings,
  scheduleSharing,
  updateUserPrivacySettings,
};

async function manageAlbumPrivacy(folderId, statusData, token) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/album-privacy/${folderId}`,
    statusData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
async function manageUserPrivacySettings(folderId, payloadData, token) {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/set-album-privacy/${folderId}`,
      payloadData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error, "Error");
  }
}

async function updateUserPrivacySettings(folderId, payloadData, token) {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/remove-album-permission/${folderId}`,
      payloadData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error, "Error");
  }
}

async function scheduleSharing(folderId, payloadData, token) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/album-schedule-time/${folderId}`,
    payloadData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
