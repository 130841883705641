import { useEffect, useState } from "react";
import { Row, Col, Table, Card, Dropdown } from "react-bootstrap";
import DashboardHeading from "../../../components/DashboardHeading";
import ChangeView from "../../../components/ChangeView";
import { useSelector } from "react-redux";
import {
  viewerService,
  executorService,
  folderService,
} from "../../../_services";
import { getExecutorAuth } from "../../../store/slices/executerSlice";
import SkeletonLoader from "../../../components/SkeletonLoader";
import DataNotFound from "../../../components/DataNotFound";
import { useAuth } from "../../../_hooks/useAuth";
import { getExecutorFolder } from "../../../store/slices/executorFolderSlice";
import { useDeviceScreen } from "../../../components/DeviceScreen";
import { useNavigate } from "react-router-dom";

const ExecutorHome = () => {
  const [isGridViewActive, setIsGridViewActive] = useState(true);
  const [executorAlbumData, setExecutorAlbumData] = useState([]);
  const [isLoadingForList, setIsLoadingForList] = useState(false);
  const [nonImageAlbums, setNonImageAlbums] = useState([]);
  const [updateFileUrl, setUpdateFileUrl] = useState("");
  const executorData = useSelector(getExecutorAuth);
  const executorFolderData = useSelector(getExecutorFolder);
  console.log(executorFolderData, "executorFolderData");
  console.log(executorAlbumData, "executorAlbumData");
  const user = useAuth("user");
  console.log(user, "executor user");
  const { isMobile } = useDeviceScreen();
  const navigate = useNavigate();
  useEffect(() => {
    if (executorFolderData?.executorFolders?.length > 0) {
      const mappedArray = executorFolderData?.executorFolders?.filter(
        (item) => {
          // Check if user?._id exists and is not null or undefined
          if (user?._id && item.viewableTo) {
            // Filter out items where any user ID in viewableTo matches user?._id
            return item.viewableTo.some(
              (userObj) => userObj.userId === user._id
            );
          } else {
            // If user?._id is null or undefined, keep all items
            return true;
          }
        }
      );

      setExecutorAlbumData(mappedArray || []);

      // console.log(mappedArray , 'new arr' , user);
    }
    // setExecutorAlbumData(executorFolderData?.executorFolders || []);
  }, [executorFolderData]);

  useEffect(() => {
    const checkThumbnailUrl = async (album) => {
      if (album?.thumbnailUrl) {
        try {
          await loadImage(album.thumbnailUrl);
          console.log("Thumbnail URL is an image:>", album.thumbnailUrl);
        } catch (error) {
          console.log("Thumbnail URL is not an image:>", album.thumbnailUrl);
          if (
            !nonImageAlbums.find(
              (nonImageAlbum) => nonImageAlbum._id === album._id
            )
          ) {
            setNonImageAlbums((prevAlbums) => [...prevAlbums, album]);
          }
        }
      }
    };

    executorAlbumData.forEach((album) => checkThumbnailUrl(album));
  }, [executorAlbumData]);

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => reject();
      img.src = url;
    });
  };

  useEffect(() => {
    if (nonImageAlbums?.length > 0) {
      handleUpdateAlbumThumbnailUrls(nonImageAlbums);
    }
  }, [nonImageAlbums]);

  const handleUpdateAlbumThumbnailUrls = (fileDataArray) => {
    const updatePromises = fileDataArray.map((album) => {
      const ownerAlbumUrlData = {
        fileName: album?.thumbnailFilename,
        _id: album?._id,
        path: album?.path,
      };
      return folderService.updateFolderThumbNailUrl(ownerAlbumUrlData);
    });

    Promise.all(updatePromises)
      .then((responses) => {
        setUpdateFileUrl(Math.round());
        responses.forEach((response) => {
          if (response?.data?.status === true) {
            console.log("File URL updated successfully");
          }
        });
      })
      .catch((error) => {
        console.log("Network ERROR", error);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pb-3 border-bottom mb-3">
        <DashboardHeading heading="Home" />
        <ChangeView
          setIsGridViewActive={setIsGridViewActive}
          isGridViewActive={isGridViewActive}
        />
      </div>
      <div>
        <Row>
          <Col xl={6}>
            <div className="p-3 memory-view-container">
              <p className="mb-2">“Dearest {user?.firstName || "Executor"},</p>
              <p className="mb-2">
                As you explore this album, I want you to feel the warmth of our
                shared moments. Each memory holds a piece of our journey
                together. May it bring a smile to your face, comfort to your
                heart, and a reminder of the love that will forever bind us. You
                are my greatest treasure, and these memories are my gift to you.
              </p>
              <p>With all my love, {user?.ownerName || "owner"} ” </p>
            </div>
          </Col>
        </Row>
        {isLoadingForList ? (
          <>
            <SkeletonLoader />
          </>
        ) : executorAlbumData?.length === 0 ? (
          <DataNotFound isAlbum={true} />
        ) : (
          <>
            {!isGridViewActive || isMobile ? (
              <div className="grid-info-executor">
                <Row>
                  {executorAlbumData && executorAlbumData?.length >= 5
                    ? executorAlbumData
                        ?.slice(0, 5)
                        ?.map((item, index) => {
                          return (
                            <Col
                              lg={4}
                              xl={4}
                              md={6}
                              xs={12}
                              className="mb-3"
                              key={index}
                            >
                              {console.log(item, "oooooo")}
                              <Card
                                onClick={() =>
                                  navigate(
                                    `/executor/${item?.folderName
                                      ?.trim()
                                      .replace(/\s+/g, "")}/${item._id}`
                                  )
                                }
                                role="button"
                              >
                                <div className="edit-icon d-flex align-items-right justify-content-end px-3 "></div>
                                <Card.Body>
                                  {console.log(item, "I222")}
                                  <div className="d-flex align-items-center gap-4 mb-3">
                                    <div className="album_folder_section">
                                      <img
                                        src="/assets/folder.png"
                                        className="album_folder_img"
                                        alt=""
                                      />
                                      {item.thumbnailUrl && (
                                        <img
                                          src={item?.thumbnailUrl}
                                          className="album_img"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    <div className="invite-user-info">
                                      <div className="invite-user-detail">
                                        <h5 className="text-capitalize">
                                          <b>{item?.folderName}</b>
                                        </h5>
                                      
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                 
                                  </div>
                                  <div className="enabledisableInvite d-flex justify-content-center">
                                    <img
                                      className="w-30"
                                      src="assets/message.svg"
                                      alt=""
                                    />
                                    <div
                                      className="invite-you"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        className="w-30"
                                        src="assets/message.svg"
                                        alt=""
                                      />{" "}
                                      Notify Loved One
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          );
                        })
                        .reverse()
                    : executorAlbumData
                        ?.map((item, index) => {
                          return (
                            <Col
                              lg={4}
                              xl={4}
                              md={6}
                              xs={12}
                              className="mb-3"
                              key={index}
                            >
                              {console.log(item, "oooooo")}
                              <Card>
                                <div className="edit-icon d-flex align-items-right justify-content-end px-3 "></div>
                                <Card.Body
                                  onClick={() =>
                                    navigate(
                                      `/executor/${item?.folderName
                                        ?.trim()
                                        .replace(/\s+/g, "")}/${item._id}`
                                    )
                                  }
                                  role="button"
                                >
                                  <div className="d-flex align-items-center gap-4 mb-3">
                                    <div className="album_folder_section">
                                      <img
                                        src="/assets/folder.png"
                                        className="album_folder_img"
                                        alt=""
                                      />
                                      {item.thumbnailUrl && (
                                        <img
                                          src={item?.thumbnailUrl}
                                          className="album_img"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    <div className="invite-user-info">
                                      <div className="invite-user-detail">
                                        <h5 className="text-capitalize">
                                          <b>{item?.folderName}</b>
                                        </h5>
                                 
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                   
                                  </div>
                                  <div className="enabledisableInvite d-flex justify-content-center">
                                    <img
                                      className="w-30"
                                      src="assets/message.svg"
                                      alt=""
                                    />
                                    <div
                                      className="invite-you"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        className="w-30"
                                        src="assets/message.svg"
                                        alt=""
                                      />{" "}
                                      Notify Loved One
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          );
                        })
                        .reverse()}
                </Row>
              </div>
            ) : (
              <div className="w-100">
                <div className="pb-3 mb-3">
                  <div className="table-container p-1">
                    <Table className="executer-table">
                      <thead>
                        <tr>
                          <th className="table-head-color">Album</th>
                          <th className="table-head-color">
                            {/* Loved ones */}
                          </th>
                          <th className="table-head-color">Publish</th>
                        </tr>
                      </thead>
                      <tbody>
                        {executorAlbumData && executorAlbumData?.length >= 5
                          ? executorAlbumData
                              ?.slice(0, 5)
                              ?.map((item, index) => {
                                return (
                                  <tr
                                    key={index}
                                    onClick={() =>
                                      navigate(
                                        `/executor/${item?.folderName
                                          ?.trim()
                                          .replace(/\s+/g, "")}/${item._id}`
                                      )
                                    }
                                    role="button"
                                  >
                                    <td>
                                      {console.log(item, "pppp")}

                                      <div className="album_folder_wrapper mb-0">
                                        <div className="album_folder_section">
                                          <img
                                            src="/assets/folder.png"
                                            className="album_folder_img"
                                            alt=""
                                          />
                                          {item.thumbnailUrl && (
                                            <img
                                              src={item?.thumbnailUrl}
                                              className="album_img"
                                              alt=""
                                            />
                                          )}
                                        </div>
                                        <div className="album_folder_detail">
                                          <div className="album_folder_detail">
                                            <h5 className="text-capitalize">
                                              <b>{item?.folderName}</b>
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                              
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center gap-2">
                                        <svg
                                          width="24"
                                          height="14"
                                          viewBox="0 0 24 14"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M21.2041 0H5.17174C4.03396 0 3.10304 0.958008 3.10304 2.12891V2.92725C3.10304 3.21997 3.33577 3.45947 3.62022 3.45947C3.90466 3.45947 4.13739 3.21997 4.13739 2.92725V2.12891C4.13739 2.02246 4.16325 1.91602 4.18911 1.80957L9.98145 6.91895L4.18911 12.0283C4.16325 11.9219 4.13739 11.8154 4.13739 11.709V10.9106C4.13739 10.6179 3.90466 10.3784 3.62022 10.3784C3.33577 10.3784 3.10304 10.6179 3.10304 10.9106V11.709C3.10304 12.8799 4.03396 13.8379 5.17174 13.8379H21.2041C22.3419 13.8379 23.2728 12.8799 23.2728 11.709V2.12891C23.2728 0.958008 22.3419 0 21.2041 0ZM4.96487 1.09106C5.01659 1.06445 5.09416 1.06445 5.17174 1.06445H21.2041C21.2817 1.06445 21.3593 1.06445 21.411 1.09106L13.5241 8.03662C13.3172 8.19629 13.0586 8.19629 12.8518 8.03662L4.96487 1.09106ZM21.2041 12.7734H5.17174C5.09416 12.7734 5.01659 12.7734 4.96487 12.7468L10.7831 7.61084L12.1794 8.86157C12.4639 9.12769 12.8259 9.26074 13.1879 9.26074C13.55 9.26074 13.912 9.12769 14.1964 8.86157L15.5928 7.61084L21.411 12.7468C21.3593 12.7734 21.2817 12.7734 21.2041 12.7734ZM22.2385 11.709C22.2385 11.8154 22.2126 11.9219 22.1868 12.0283L16.3944 6.91895L22.1868 1.80957C22.2126 1.91602 22.2385 2.02246 22.2385 2.12891V11.709ZM1.55152 5.58838C1.55152 5.29565 1.78425 5.05615 2.06869 5.05615H5.17174C5.45618 5.05615 5.68891 5.29565 5.68891 5.58838C5.68891 5.8811 5.45618 6.12061 5.17174 6.12061H2.06869C1.78425 6.12061 1.55152 5.8811 1.55152 5.58838ZM5.17174 8.78174H0.517174C0.232728 8.78174 0 8.54224 0 8.24951C0 7.95679 0.232728 7.71729 0.517174 7.71729H5.17174C5.45618 7.71729 5.68891 7.95679 5.68891 8.24951C5.68891 8.54224 5.45618 8.78174 5.17174 8.78174Z"
                                            fill="#6C6C6C"
                                          />
                                        </svg>
                                        <span className="notify">
                                          Notify Loved One
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                              .reverse()
                          : executorAlbumData
                              ?.map((item, index) => {
                                return (
                                  <tr
                                    key={index}
                                    onClick={() =>
                                      navigate(
                                        `/executor/${item?.folderName
                                          ?.trim()
                                          .replace(/\s+/g, "")}/${item._id}`
                                      )
                                    }
                                    role="button"
                                  >
                                    <td>
                                      {console.log(item, "pppp")}

                                      <div className="album_folder_wrapper mb-0">
                                        <div className="album_folder_section">
                                          <img
                                            src="/assets/folder.png"
                                            className="album_folder_img"
                                            alt=""
                                          />
                                          {item.thumbnailUrl && (
                                            <img
                                              src={item?.thumbnailUrl}
                                              className="album_img"
                                              alt=""
                                            />
                                          )}
                                        </div>
                                        <div className="album_folder_detail">
                                          <div className="album_folder_detail">
                                            <h5 className="text-capitalize">
                                              <b>{item?.folderName}</b>
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                   
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center gap-2">
                                        <svg
                                          width="24"
                                          height="14"
                                          viewBox="0 0 24 14"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M21.2041 0H5.17174C4.03396 0 3.10304 0.958008 3.10304 2.12891V2.92725C3.10304 3.21997 3.33577 3.45947 3.62022 3.45947C3.90466 3.45947 4.13739 3.21997 4.13739 2.92725V2.12891C4.13739 2.02246 4.16325 1.91602 4.18911 1.80957L9.98145 6.91895L4.18911 12.0283C4.16325 11.9219 4.13739 11.8154 4.13739 11.709V10.9106C4.13739 10.6179 3.90466 10.3784 3.62022 10.3784C3.33577 10.3784 3.10304 10.6179 3.10304 10.9106V11.709C3.10304 12.8799 4.03396 13.8379 5.17174 13.8379H21.2041C22.3419 13.8379 23.2728 12.8799 23.2728 11.709V2.12891C23.2728 0.958008 22.3419 0 21.2041 0ZM4.96487 1.09106C5.01659 1.06445 5.09416 1.06445 5.17174 1.06445H21.2041C21.2817 1.06445 21.3593 1.06445 21.411 1.09106L13.5241 8.03662C13.3172 8.19629 13.0586 8.19629 12.8518 8.03662L4.96487 1.09106ZM21.2041 12.7734H5.17174C5.09416 12.7734 5.01659 12.7734 4.96487 12.7468L10.7831 7.61084L12.1794 8.86157C12.4639 9.12769 12.8259 9.26074 13.1879 9.26074C13.55 9.26074 13.912 9.12769 14.1964 8.86157L15.5928 7.61084L21.411 12.7468C21.3593 12.7734 21.2817 12.7734 21.2041 12.7734ZM22.2385 11.709C22.2385 11.8154 22.2126 11.9219 22.1868 12.0283L16.3944 6.91895L22.1868 1.80957C22.2126 1.91602 22.2385 2.02246 22.2385 2.12891V11.709ZM1.55152 5.58838C1.55152 5.29565 1.78425 5.05615 2.06869 5.05615H5.17174C5.45618 5.05615 5.68891 5.29565 5.68891 5.58838C5.68891 5.8811 5.45618 6.12061 5.17174 6.12061H2.06869C1.78425 6.12061 1.55152 5.8811 1.55152 5.58838ZM5.17174 8.78174H0.517174C0.232728 8.78174 0 8.54224 0 8.24951C0 7.95679 0.232728 7.71729 0.517174 7.71729H5.17174C5.45618 7.71729 5.68891 7.95679 5.68891 8.24951C5.68891 8.54224 5.45618 8.78174 5.17174 8.78174Z"
                                            fill="#6C6C6C"
                                          />
                                        </svg>
                                        <span className="notify">
                                          Notify Loved One
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                              .reverse()}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ExecutorHome;
