import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  _id: "",
  ownerId: "",
  folderName: "",
  folderType: "",
  path: "",
  thumbnailFilename: "",
  thumbnailUrl: "",
  updatedAt: "",
  createdAt: "",
  folderDataForAlbum: {},
  privacyStatus: null
};

const folderSlice = createSlice({
  name: "folder",
  initialState: initialState,
  reducers: {
    setCurrentFolder: (state, action) => {
      state._id = action?.payload?._id;
      state.ownerId = action?.payload?.ownerId;
      state.folderName = action?.payload?.folderName;
      state.folderType = action?.payload?.folderType;
      state.path = action?.payload?.path;
      state.thumbnailFilename = action?.payload?.thumbnailFilename;
      state.thumbnailUrl = action?.payload?.thumbnailUrl;
      state.updatedAt = action?.payload?.updatedAt;
      state.createdAt = action?.payload?.createdAt;
      state.privacyStatus = action?.payload?.privacyStatus;

    },
    setFolderDataForAlbum: (state, action) => {
      state.folderDataForAlbum = action.payload;
    },
    setPrivacyStatus: (state, action) =>{
      state.privacyStatus = action?.payload?.privacyStatus;

    }
  },
});

export default folderSlice.reducer;
export const { setCurrentFolder ,setFolderDataForAlbum, setPrivacyStatus} = folderSlice.actions;
export const getFolderData = (state) => state.folderData;
export const getFolderDataForAlbum = (state) => state.folderData.folderDataForAlbum;