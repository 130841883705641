import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../components/Loader";
import { Spinner } from "react-bootstrap";
const DeleteOwnerConfirmationPopup = ({
  showDeleteAcPopUp,
  setShowDeleteAcPopUp,
  handleCloseDeleteAcPopUp,
  handleShowDeleteAcPopUp,
  handleDeleteAc,
  isActiveLoading,
}) => {
  return (
    <div>
      {isActiveLoading && <Loader />}{" "}
      {
        <Modal
          show={showDeleteAcPopUp}
          onHide={handleCloseDeleteAcPopUp}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Owner Account!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure, you want to permanently delete this account?
          </Modal.Body>
          <Modal.Footer>
            {
              <Button variant="secondary" onClick={handleDeleteAc}>
                {!isActiveLoading ? "Yes" : <Spinner />}
              </Button>
            }
            <Button variant="primary" onClick={handleCloseDeleteAcPopUp}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </div>
  );
};

export default DeleteOwnerConfirmationPopup;
