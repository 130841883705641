import React from "react";
import Swal from "sweetalert2";
import { folderService } from "../../_services/folder";
import { useAuth } from "../../_hooks/useAuth";
import toast from "react-hot-toast";

const DeleteAlert = ({ album,handleRemoveFile }) => {
  const user = useAuth("user");

  const handleConfirm = () => {
    if ( user?.isProfileCompleted == true) {
    Swal.fire({
      title: "Move to trash",
      text: "You have 30 days to restore it before it is permanently deleted",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Done",
      cancelButtonText: "Undo",
    }).then((result) => {
      if (result.isConfirmed) {
       handleRemoveFile(album)
      }
    });}
    else{
      toast.error("Upgrade Required: Unlock this feature by completing your payment. Questions? Contact support.");

    }
  };

  return (
    <span onClick={handleConfirm}>
      <i className="ri-delete-bin-line"></i>Move to trash
    </span>
  );
};

export default DeleteAlert;
