import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { Suspense } from "react";
import { lazy } from "react";
import ProtectedRoutes from "./ProtectedRoutes";
import Spinner from "react-bootstrap/Spinner";
// Layouts
import SiteLayout from "../components/Layouts/SiteLayout";
import { useAuth } from "../_hooks/useAuth";
import { EXECUTOR, OWNER, PUBLIC_OWNER, VIEWER } from "../_helpers";
import Vieweralbum from "../pages/forevermems/viewer-album";
import Viewermemories from "../pages/forevermems/viewer-memories";
import Executoralbum from "../pages/forevermems/executor-album";
import ExecutorHome from "../pages/forevermems/executor-home";
import MyOwnerAccount from "../pages/forevermems/ownerAccount";
import ProtectedWithSiteLayout from "./ProtectedWithSiteLayout";
import Estimator from "../pages/estimator";
import ExecutorLovedPage from "../pages/forevermems/executor-loved";
import EmailVerifed from "../pages/email-verified";
import ExecutorOwnerAlbums from "../pages/forevermems/executor-owner-albums";
import CheckoutForm from "../components/Checkout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
const NotFound = lazy(() => import("../pages/not-found"));
const SignAsPage = lazy(() => import("../pages/sign-as"));
const SignInPage = lazy(() => import("../pages/sign-in"));
const SignUpPage = lazy(() => import("../pages/sign-up"));
const ForgotPassword = lazy(() => import("../pages/forgot-password"));
const ResetPassword = lazy(() => import("../pages/reset-password"));
const DashboardPage = lazy(() => import("../pages/forevermems/dashboard"));
const MomentPage = lazy(() => import("../pages/forevermems/moment"));
const LovedPage = lazy(() => import("../pages/forevermems/loved"));
const StepWizard = lazy(() => import("../pages/step-wizard"));
const TestPage = lazy(() => import("../pages/test-page"));
const CommonSignInPage = lazy(() => import("../pages/common-signin"));
const AlbumPage = lazy(() => import("../pages/forevermems/album"));
const MemoryPage = lazy(() => import("../pages/forevermems/memory"));
const ExecutorVerificationPage = lazy(() =>
  import("../pages/executor-verification")
);
const ViewerVerificationPage = lazy(() =>
  import("../pages/viewer-verification")
);
const ExecutersPage = lazy(() => import("../pages/forevermems/executers"));
const PlansPage = lazy(() => import("../pages/plans"));
const EmailVerificationPage = lazy(() => import("../pages/email-verification"));
const TrashPage = lazy(() => import("../pages/forevermems/trash"));
const ServiceInstruction = lazy(() =>
  import("../pages/forevermems/serviceInstruction")
);
const KeepMemories = lazy(() => import("../pages/keep-memories"));
const MyAccount = lazy(() => import("../pages/forevermems/myaccount"));
const BillingPlans = lazy(() => import("../pages/forevermems/billingPlans"));
const SettingsPage = lazy(() => import("../pages/forevermems/settings"));
const PrivacyPolicy = lazy(() => import("../pages/privacy-policy"));
const TermsConditions = lazy(() => import("../pages/terms-and-conditions"));
const PaymentSuccess = lazy(() => import("../pages/payment-success"));
const EmailVerifiedScreen = lazy(() => import("../pages/email-verified"));
const PublicOwnerFolders = lazy(() =>
  import("../pages/forevermems/public-owner-folder")
);
const PublicOwnerMemories = lazy(() =>
  import("../pages/forevermems/public-onwer-memories")
);
const AdminView = lazy(() => import("../pages/admin-view"));
const ExectorMedia = lazy(() => import("../pages/forevermems/executor-media"));
const ViewerOwnerAlbums = lazy(() =>
  import("../pages/forevermems/viewer-owner-albums")
);
const AppRouter = () => {
  const role = useAuth("role");
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <SignAsPage />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/keep-memories"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <KeepMemories />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/common-signin"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <CommonSignInPage />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/sign-as"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <SignAsPage />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/sign-in"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <SignInPage />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/sign-up"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <SignUpPage />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/email-verification"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <EmailVerificationPage />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/email-verified"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <EmailVerifiedScreen />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/executor-verification"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <ExecutorVerificationPage />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/viewer-verification"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <ViewerVerificationPage />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <ForgotPassword />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/reset-password"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <ResetPassword />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/admin-view"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <AdminView />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/checkout"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                {" "}
                {/* <Elements stripe={stripePromise}> */}
                <CheckoutForm /> {/* </Elements> */}
              </Suspense>
            </SiteLayout>
          }
        />
        {/* <Route
          path='/estimator'
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className='loading-loader-section'>
                    <Spinner animation='border' variant='primary' />
                  </div>
                }
              >
                <Estimator />
              </Suspense>
            </SiteLayout>
          }
        /> */}
        <Route
          path="/privacy-policy"
          element={
            <Suspense
              fallback={
                <div className="loading-loader-section">
                  <Spinner animation="border" variant="primary" />
                </div>
              }
            >
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <Suspense
              fallback={
                <div className="loading-loader-section">
                  <Spinner animation="border" variant="primary" />
                </div>
              }
            >
              <TermsConditions />
            </Suspense>
          }
        />
        <Route
          path={`/public-owner-folder/:id`}
          exact
          element={
            <Suspense
              fallback={
                <div className="loading-loader-section">
                  <Spinner animation="border" variant="primary" />
                </div>
              }
            >
              <PublicOwnerFolders />
            </Suspense>
          }
        />
        <Route
          path={`/public-owner-memories/:id`}
          exact
          element={
            <Suspense
              fallback={
                <div className="loading-loader-section">
                  <Spinner animation="border" variant="primary" />
                </div>
              }
            >
              <PublicOwnerMemories />
            </Suspense>
          }
        />
        <Route element={<ProtectedWithSiteLayout />}>
          <React.Fragment>
            {role === OWNER && (
              <>
                <Route
                  path={`${OWNER}/step-wizard`}
                  exact
                  element={<StepWizard />}
                />
                <Route
                  path={`${OWNER}/payment-success`}
                  exact
                  element={<PaymentSuccess />}
                />
                <Route
                  path={`${OWNER}/estimator`}
                  exact
                  element={<Estimator />}
                />
              </>
            )}
            {role === VIEWER && (
              <>
                <Route
                  path={`${VIEWER}/payment-success`}
                  exact
                  element={<PaymentSuccess />}
                />
                <Route
                  path={`${VIEWER}/estimator`}
                  exact
                  element={<Estimator />}
                />
              </>
            )}
            {role === EXECUTOR && (
              <>
                <Route
                  path={`${EXECUTOR}/payment-success`}
                  exact
                  element={<PaymentSuccess />}
                />
                <Route
                  path={`${EXECUTOR}/estimator`}
                  exact
                  element={<Estimator />}
                />
              </>
            )}
            <Route path={`${OWNER}/plans`} exact element={<PlansPage />} />
            <Route path={`${VIEWER}/plans`} exact element={<PlansPage />} />
            <Route path={`${EXECUTOR}/plans`} exact element={<PlansPage />} />
          </React.Fragment>
        </Route>
        <Route element={<ProtectedRoutes />}>
          <React.Fragment>
            {role === OWNER && (
              <>
                <Route
                  path={`${OWNER}/dashboard`}
                  exact
                  element={<DashboardPage />}
                />
                <Route path={`${OWNER}/albums`} exact element={<AlbumPage />} />
                <Route
                  path={`${OWNER}/serviceInstruction`}
                  exact
                  element={<ServiceInstruction />}
                />
                <Route
                  path={`${OWNER}/memories`}
                  exact
                  element={<MemoryPage />}
                />
                <Route
                  path={`${OWNER}/loved-ones`}
                  exact
                  element={<LovedPage />}
                />
                <Route
                  path={`${OWNER}/executors`}
                  exact
                  element={<ExecutersPage />}
                />
                <Route path={`${OWNER}/trash`} exact element={<TrashPage />} />
                <Route
                  path={`${OWNER}/myaccount`}
                  exact
                  element={<MyOwnerAccount />}
                />
                <Route
                  path={`${OWNER}/billingPlans`}
                  exact
                  element={<BillingPlans />}
                />
                <Route
                  path={`${OWNER}/settings`}
                  exact
                  element={<SettingsPage />}
                />
              </>
            )}
          </React.Fragment>
        </Route>
        <Route element={<ProtectedRoutes />}>
          <React.Fragment>
            {/* Viewer routes */}
            {role === VIEWER && (
              <>
                <Route
                  path={`${VIEWER}/dashboard`}
                  element={
                    // <DashboardPage />
                    <Vieweralbum />
                  }
                />
                <Route path={`${VIEWER}/albums`} element={<Vieweralbum />} />
                <Route
                  path={`${VIEWER}/memories`}
                  element={<Viewermemories />}
                />
                <Route
                  path={`${VIEWER}/myaccount`}
                  exact
                  element={<MyAccount />}
                />
                <Route
                  path={`${VIEWER}/billingPlans`}
                  exact
                  element={<BillingPlans />}
                />
                {/* <Route path={`${VIEWER}/plans`} exact element={<PlansPage />} /> */}
                <Route
                  path={`/:ownerName/:id`}
                  element={<ViewerOwnerAlbums />}
                />
              </>
            )}
          </React.Fragment>
        </Route>
        <Route element={<ProtectedRoutes />}>
          <React.Fragment>
            {role === EXECUTOR && (
              <>
                <Route
                  path={`${EXECUTOR}/dashboard`}
                  element={<ExecutorHome />}
                />
                <Route
                  path={`${EXECUTOR}/albums`}
                  element={<Executoralbum />}
                />
                <Route
                  path={`${EXECUTOR}/loved-ones`}
                  element={<ExecutorLovedPage />}
                />
                <Route
                  path={`${EXECUTOR}/myaccount`}
                  exact
                  element={<MyAccount />}
                />
                <Route
                  path={`${EXECUTOR}/billingPlans`}
                  exact
                  element={<BillingPlans />}
                />
                {/* <Route
                  path={`${EXECUTOR}/plans`}
                  exact
                  element={<PlansPage />}
                /> */}
                <Route
                  path={`/:ownerName/:id`}
                  element={<ExecutorOwnerAlbums />}
                />
                <Route
                  path={`${EXECUTOR}/:album/:id`}
                  exact
                  element={<ExectorMedia />}
                />
              </>
            )}
          </React.Fragment>
        </Route>
        ;
        <Route
          path="*"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <NotFound />
              </Suspense>
            </SiteLayout>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
