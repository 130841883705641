import React from "react";
import { useLocation } from "react-router-dom";

export default function DataNotFound({ isAlbum }) {
  const location = useLocation();
  const { pathname } = location;

  return (
    <>
      {isAlbum ? (
        <div className="album-not-found">
          <img src="/assets/no_album.png" alt="No album found" />
          <h5>There’s nothing here</h5>
          {!(pathname === "/executor/albums" || pathname === "/viewer/albums" || pathname === "/executor/dashboard") && (
            <p>
              Click 'Create new album' to organize and relive your cherished
              moments in a personalized collection.
            </p>
          )}
        </div>
      ) : (
        <div className="fm-not-found-box">
          <img src="/assets/data_not_found.png" alt="Data not found" />
          <span>There are no memories yet</span>
        </div>
      )}
    </>
  );
}
