import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useAuth } from "../../../_hooks/useAuth";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const VerifyProfileAndManagePaymentPopUp = ({
  showPaymentVerificationPopup,
  setShowPaymentVerificationPopup,
  handleClosePaymentVerificationPopup,
  handleShowPaymentVerificationPopup,
}) => {
  const user = useAuth("user");
  console.log(user, "Verification");
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        show={showPaymentVerificationPopup}
        onHide={handleClosePaymentVerificationPopup}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Alert !</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {/* <Col md={12} className="text-center">
              {user?.isProfileCompleted != true && user?.isVerified != true && (
                <h5 className="my-2">
                  Hi {user?.userName}, please complete email verification and
                  payment in order to enjoy our services..
                </h5>
              )}
            </Col> */}
            {/* {user?.isVerified == false && (
              <Col md={12} className="mb-2 text-center">
                <h3 className="text-danger my-2">
                  Please verify your email address
                  
                </h3>
                <Button
                  variant="primary"
                  onClick={() => navigate("/email-verification")}
                >
                  Verify Your Email
                </Button>
              </Col>
            )} */}
            {user?.isProfileCompleted == false && (
              <Col md={12} className="text-center">
                <h3 className="text-alert-modal my-2">
                  Hi {user?.userName}, please select a plan in order to enjoy
                  our services
                </h3>
                <Button
                  variant="primary"
                  onClick={() => navigate("/owner/plans")}
                >
                  Go To Plans
                </Button>
              </Col>
            )}
          </Row>
        </Modal.Body>
        {/* <Modal.Footer>
          <Row>
            <Col md={12} className="mb-2 text-center">
              <Button
                variant="primary"
                onClick={handleClosePaymentVerificationPopup}
              >
                Close
              </Button>
            </Col>
          </Row>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default VerifyProfileAndManagePaymentPopUp;
