import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Spinner } from "react-bootstrap";
import moment from "moment";
import UpdateMemory from "../EditMemory";
import { mediaServices } from "../../_services";
import { useAuth } from "../../_hooks/useAuth";

const PreviewMedia = ({
  showMedia,
  setShowMedia,
  handleCloseMedia,
  handleShowMedia,
  previewElements,
  videoUrl,
  loading,
  setLoading,
  updateFileUrl,
  // user,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const [show, setShow] = useState(false);
  const [loadingUpdateMdiaForm, setLoadingUpdateMediaForm] = useState(false);
  const handleClose = () => {
    setShow(false);
    setShowMedia(false);
  };
  const user = useAuth("user");
  function bytesToHumanReadable(fileSizeInBytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (fileSizeInBytes === 0) return "0 Byte";
    const i = parseInt(Math.floor(Math.log(fileSizeInBytes) / Math.log(1024)));
    return Math.round(fileSizeInBytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }
  const fileSizeInBytes = previewElements?.fileSize;
  const fileSizeReadable = bytesToHumanReadable(fileSizeInBytes);
  console.log("File size:", fileSizeReadable);

  let cratedAtDate = moment(previewElements?.createdAt).format(
    "DD-MMM-YYYY hh:mm A"
  );
  let updatedAtDate = moment(previewElements?.updatedAt).format(
    "DD-MMM-YYYY hh:mm A"
  );

  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = updateFileUrl?.data?.data;
    link.download = previewElements.fileName; // Provide the desired file name here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleOpenMedia = () => {
    setShow(true);
  };
  const [mediaDetails, setMediaDetails] = useState("");
  // Media details
  const fetchMediaDetails = async (mediaID) => {
    setLoadingUpdateMediaForm(true);
    try {
      const response = await mediaServices.getMediaDetails(
        mediaID,
        user?.token
      );
      if (response.status == 200) {
        setMediaDetails(response.data.data);
        setLoadingUpdateMediaForm(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoadingUpdateMediaForm(false);
    }
  };
  return (
    <>
      {!show && (
        <Modal
          show={showMedia}
          onHide={handleCloseMedia}
          animation={false}
          centered
          className="preview-media"
        >
          <Modal.Header closeButton>
            <Modal.Title>Preview Media</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <div className="img-container">
              {previewElements.fileType === "image" && (
                <img src={previewElements.fileUrl} className="img-fluid" />
              )}
              {previewElements.fileType === "video" && (
                <>
                  {videoUrl && (
                    <video
                      src={videoUrl}
                      controls
                      autoPlay
                      height={`400px`}
                      width={`400px`}
                    />
                  )}
                  {!videoUrl && <Spinner />}
                </>
              )}
              {previewElements.fileType === "document" && (
                <img src={"/doc-placeholder.png"} className="img-fluid" />
              )}
            </div>
            <div className="preview-media">
              {previewElements.description != "N/A" && (
                <div className="d-flex flex-column">
                  <p className="">
                    <b> Description</b>
                  </p>
                  <p className="">
                    {expanded
                      ? previewElements?.description
                      : previewElements?.description.slice(0, 100)}
                    {previewElements?.description.length > 100 && (
                      <span
                        onClick={toggleExpand}
                        style={{ cursor: "pointer", color: "#296Cf5" }}
                      >
                        {expanded ? " View less" : " ... View more"}
                      </span>
                    )}
                  </p>
                </div>
              )}
              {previewElements?.scheduleTime && (
                <Row>
                  <Col className="text-end">
                    <div>
                      <span>
                        <b>Scheduled For: </b>
                      </span>
                    </div>
                  </Col>
                  <Col className="text-start">
                    <div>
                      <span>
                        {moment(previewElements?.scheduleTime).format(
                          "DD-MMM-YYYY"
                        )}
                      </span>
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col className="text-end">
                  <div>
                    <span>
                      <b>File Size:</b>
                    </span>
                  </div>
                </Col>
                <Col className="text-start">
                  <div>
                    <span>{fileSizeReadable}</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-end">
                  <div>
                    <span>
                      <b>Created At:</b>
                    </span>
                  </div>
                </Col>
                <Col className="text-start">
                  <div>
                    <span> {cratedAtDate}</span>
                  </div>
                </Col>
              </Row>
              {cratedAtDate != updatedAtDate && (
                <Row>
                  <Col className="text-end">
                    <div>
                      <span>
                        <b>Updated At:</b>
                      </span>
                    </div>
                  </Col>
                  <Col className="text-start">
                    <div>
                      <span> {updatedAtDate}</span>
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col className="py-2">
                  {previewElements?.fileType === "document" &&
                    (loading ? (
                      <Spinner />
                    ) : (
                      <Button variant="primary" onClick={downloadFile}>
                        Download
                      </Button>
                    ))}
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseMedia}>
              Close
            </Button>
            {user.role == "owner" && (
              <Button
                variant="secondary"
                onClick={() => {
                  handleOpenMedia();
                  fetchMediaDetails(previewElements._id);
                }}
              >
                Edit
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
      {/* Update Memory */}
      {show && (
        <Modal
          className=" create-folder-modal"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Body className="py-5 px-4">
            <UpdateMemory
              show={show}
              setShow={setShow}
              handleClose={handleClose}
              handleShow={handleOpenMedia}
              mediaDetails={mediaDetails}
              user={user}
              loadingUpdateMdiaForm={loadingUpdateMdiaForm}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default PreviewMedia;
