import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  viewerFolders: [],
  viewerOwners: [],
  viewerIdArr: [],
  viewerAsOwnerData: {},
};
const viewerFolderSlice = createSlice({
  name: "viewerFolder",
  initialState: initialState,
  reducers: {
    setViewerFolders: (state, action) => {
      state.viewerFolders = action.payload;
    },
    setViewerFoldersLogout: (state, action) => {
      state.viewerFolders = "";
    },
    setViewerOwner: (state, action) => {
      state.viewerOwners = action.payload;
    },
    removeViewerOwner: (state, action) => {
      state.viewerOwners = "";
    },
    setViewerIdArr: (state, action) => {
      state.viewerIdArr = action.payload;
    },
    removeViewerIdArr: (state, action) => {
      state.viewerIdArr = "";
    },
    setViewerAsOwnerData: (state, action) => {
      state.viewerAsOwnerData = action.payload;
    },
    removeViewerAsOwnerData: (state, action) => {
      state.viewerAsOwnerData = "";
    },
  },
});
export default viewerFolderSlice.reducer;
export const {
  setViewerFolders,
  setViewerFoldersLogout,
  setViewerOwner,
  removeViewerOwner,
  setViewerIdArr,
  removeViewerIdArr,
  setViewerAsOwnerData,
  removeViewerAsOwnerData,
} = viewerFolderSlice.actions;
export const getViewerFolder = (state) => state.viewerFolder;
export const getViewerOwner = (state) => state.viewerFolder.viewerOwners;
export const getViewerIdArr = (state) => state.viewerFolder.viewerIdArr;
export const getViwerAsOwnerData = (state) =>
  state.viewerFolder.viewerAsOwnerData;
