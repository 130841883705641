import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  _id: "",
  ownerName: "",
  ownerEmail: "",
  email: "",
  countryCode: "",
  relationShip: "",
  status: "",
  role: "",
  token: "",
  avatar: "",
  isViewVideos: "",
  firstName: "",
  lastName: "",
  loginCode: "",
  mobileNumber: "",
  ownerId: "",
  ownerPassingDateStatus: false,
  fileName: "",
  isPlanPurchased: false,
  executorLoginDataForOwner: {},
};
const executorSlice = createSlice({
  name: "executor",
  initialState: initialState,
  reducers: {
    executorLoginAuth: (state, action) => {
      state._id = action?.payload?._id;
      state.ownerName = action?.payload?.ownerName;
      state.isViewVideos = action?.payload?.isViewVideos;
      state.ownerEmail = action?.payload?.ownerEmail;
      state.email = action?.payload?.email;
      state.countryCode = action?.payload?.countryCode;
      state.relationShip = action?.payload?.relationShip;
      state.role = action?.payload?.role;
      state.mobileNumber = action?.payload?.mobileNumber;
      state.token = action?.payload?.token;
      state.firstName = action?.payload?.firstName;
      state.lastName = action?.payload?.lastName;
      state.loginCode = action?.payload?.loginCode;
      state.status = action?.payload?.status;
      state.ownerId = action?.payload?.owner;
      state.avatar = action?.payload?.avatar;
      state.ownerPassingDateStatus = action?.payload?.ownerPassingDateStatus;
      state.fileName = action?.payload?.fileName;
      state.isPlanPurchased = action?.payload?.isPlanPurchased || false;
    },
    executorLogoutAuth: (state, action) => {
      state._id = "";
      state.ownerName = "";
      state.ownerEmail = "";
      state.email = "";
      state.countryCode = "";
      state.firstName = "";
      state.lastName = "";
      state.role = "";
      state.mobileNumber = "";
      state.avatar = "";
      state.isViewVideos = "";
      state.token = "";
      state.status = "";
      state.ownerId = "";
      state.relationShip = "";
      state.loginCode = "";
      state.ownerPassingDateStatus = false;
      state.fileName = "";
      state.isPlanPurchased = false;
    },
    executorUpdateValue: (state, action) => {
      state.firstName = action?.payload?.firstName;
      state.lastName = action?.payload?.lastName;
    },
    executorAvatarUpdate: (state, action) => {
      state.avatar = action?.payload?.avatar;
    },
    executorPassingDateStatus: (state, action) => {
      state.ownerPassingDateStatus = action?.payload?.ownerPassingDateStatus;
    },
    executorPlanUgradeStatus: (state, action) => {
      state.isPlanPurchased = action?.payload?.isPlanPurchased;
    },

    setExecutorLoginAsOwner: (state, action) => {
      state.executorLoginDataForOwner = action.payload;
    },
  },
});

export default executorSlice.reducer;
export const {
  executorLoginAuth,
  executorLogoutAuth,
  executorUpdateValue,
  executorAvatarUpdate,
  executorPassingDateStatus,
  executorPlanUgradeStatus,
  setExecutorLoginAsOwner,
} = executorSlice.actions;
export const getExecutorAuth = (state) => state.executorAuth;
export const getExecutorAsOwnerData = (state) =>
  state.executorAuth.executorLoginDataForOwner;

// export const getExecutorAuth = (state) => state.executorAuth;
