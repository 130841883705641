import React, { useState } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import { folderService } from "../../_services";
import toast from "react-hot-toast";
import DataNotFound from "../DataNotFound";
import RenameAlert from "../RenameAlert";
import DeleteAlert from "../DeleteAlert";
import Loader from "../Loader";

const RecentViewerAlbum = ({
  key,
  isGridViewActive,
  album,
  navigateAlbumPage,
  setIsDeletes,
  setIsActiveLoading,
}) => {
  // const [isActionLoading, setIsActionLoading] = useState(false);

  console.log(album);

  return (
    <>
      {isGridViewActive ? (
        <Col xl={12} lg={12} md={12}>
          <div className="dogller-container" role="button">
            <div
              className="list-item"
              onClick={() => navigateAlbumPage(album)}
              key={key}
            >
              <div className="list-item-image">
                {album.thumbnailUrl ? (
                  <img src={album?.thumbnailUrl} className="album_img" alt="" />
                ) : (
                  <img
                    src="/assets/folder.png"
                    className="album_folder_img"
                    alt=""
                  />
                )}
              </div>
              <div className="list-item-content align-self-center">
                <h5>
                  <b className="text-capitalize">{album?.folderName} </b>
                </h5>
                {/* <span>{album?.filesCount} Item</span> */}
              </div>
              <div className="album_folder_detail_views align-self-center">
                <div className="avatars">
                  <span className="avatar">
                    <img
                      src="https://picsum.photos/1920?1080?random=21"
                      alt=""
                    />
                  </span>
                  <span className="avatar">
                    <img
                      src="https://picsum.photos/1920?1080?random=22"
                      alt=""
                    />
                  </span>
                  <span className="avatar">
                    <img
                      src="https://picsum.photos/1920?1080?random=23"
                      alt=""
                    />
                  </span>
                  <span className="avatar">
                    <img
                      src="https://picsum.photos/1920?1080?random=24"
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Col>
      ) : (
        <Col key={key} xl={4} lg={4} md={12}>
          <div className="grid-toggler p-2">
            <div
              className="album_folder_wrapper mb-0 mt-0"
              onClick={() => navigateAlbumPage(album)}
              role="button"
            >
              <div className="album_folder_section">
                <img
                  src="/assets/folder.png"
                  className="album_folder_img"
                  alt=""
                />
                {album.thumbnailUrl && (
                  <img src={album?.thumbnailUrl} className="album_img" alt="" />
                )}
              </div>
              <div className="album_folder_detail">
                <div className="album_folder_detail">
                  <h5>
                    <b className="text-capitalize">{album?.folderName} </b>
                  </h5>
                  <div className="album_folder_detail_views">
                    {/* <div className="avatars">
                      <span className="avatar">
                        <img
                          src="https://picsum.photos/1920?1080?random=21"
                          alt=""
                        />
                      </span>
                      <span className="avatar">
                        <img
                          src="https://picsum.photos/1920?1080?random=22"
                          alt=""
                        />
                      </span>
                      <span className="avatar">
                        <img
                          src="https://picsum.photos/1920?1080?random=23"
                          alt=""
                        />
                      </span>
                      <span className="avatar">
                        <img
                          src="https://picsum.photos/1920?1080?random=24"
                          alt=""
                        />
                      </span>
                    </div> */}
                    {/* <span>{album?.filesCount} Item</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      )}
    </>
  );
};

export default RecentViewerAlbum;
