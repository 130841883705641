// const { createSlice } = require("@reduxjs/toolkit");
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  _id: "",
  ownerId: "",
  ownerName: "",
  ownerEmail: "",
  email: "",
  countryCode: "",
  firstName: "",
  avatar: "",
  lastName: "",
  mobileNumber: "",
  role: "",
  token: "",
  status: "",
  fileName: "",
  isPlanPurchased: false,
  existingFreePlan: "",
};
const viewerSlice = createSlice({
  name: "viewer",
  initialState: initialState,
  reducers: {
    viewerLoginAuth: (state, action) => {
      state._id = action?.payload?._id;
      state.ownerId = action?.payload?.owner;
      state.ownerName = action?.payload?.ownerName;
      state.ownerEmail = action?.payload?.ownerEmail;
      state.email = action?.payload?.email;
      state.countryCode = action?.payload?.countryCode;
      state.firstName = action?.payload?.firstName;
      state.lastName = action?.payload?.lastName;
      state.role = action?.payload?.role;
      state.mobileNumber = action?.payload?.mobileNumber;
      state.token = action?.payload?.token;
      state.avatar = action?.payload?.avatar;
      state.status = action.payload.status;
      state.fileName = action?.payload?.fileName;
      state.existingFreePlan = action?.payload?.existingFreePlan;
    },
    viewerLogoutAuth: (state, action) => {
      state._id = "";
      state.ownerId = "";
      state.ownerName = "";
      state.ownerEmail = "";
      state.email = "";
      state.countryCode = "";
      state.firstName = "";
      state.lastName = "";
      state.role = "";
      state.mobileNumber = "";
      state.token = "";
      state.avatar = "";
      state.status = "";
      state.fileName = "";
      state.existingFreePlan = "";
    },
    viewerUpdateValue: (state, action) => {
      state.firstName = action?.payload?.firstName;
      state.lastName = action?.payload?.lastName;
    },
    viewerAvatarUpdate: (state, action) => {
      state.avatar = action?.payload?.avatar;
    },
    viewerPlanUgradeStatus: (state, action) => {
      state.isPlanPurchased = action?.payload?.isPlanPurchased;
    },
  },
});

export default viewerSlice.reducer;
export const {
  viewerLoginAuth,
  viewerLogoutAuth,
  viewerUpdateValue,
  viewerAvatarUpdate,
  viewerPlanUgradeStatus,
} = viewerSlice.actions;
export const getViewerAuth = (state) => state.viewerAuth;
