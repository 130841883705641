import React, { useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'

const SortBy = ({ album, setAlbums, isExecutor = false, isViewer = false,executors }) => {
  const [selectedOption, setSelectedOption] = useState('Sort by')
  const handleOptionChange = e => {
    const option = e.target.value
    if (selectedOption === option) {
      return
    }

    setSelectedOption(option)

    let sortedAlbums = [...album] // Create a copy of the original album array
    switch (option) {
      case 'Newest First':
        sortedAlbums.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
        break
      case 'Oldest First':
        sortedAlbums.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
        break
      case 'Public After Passing':
        sortedAlbums = sortedAlbums.filter(
          // album => album.isPublic && new Date(album.scheduleTime) < new Date()
          album =>  album.scheduleTime !== null
        )
        break
      case 'Public Before Passing':
        sortedAlbums = sortedAlbums.filter(
          // album => album.isPublic && new Date() > new Date()
          album => album.isPublic && album.scheduleTime === null
        )
        break
      case 'Private':
        sortedAlbums = sortedAlbums.filter(album => !album?.isPublic)
        break
      default:
        sortedAlbums = album
        break
    }

    // Update state with sorted albums
    setAlbums(sortedAlbums)
  }

  const handleOptionChangeViewer = e => {
    const option = e.target.value
    if (selectedOption === option) {
      return
    }
    setSelectedOption(option)
    let sortedAlbums = [...album]

    switch (option) {
      case 'AtoZ':
        sortedAlbums.sort((a, b) => a.firstName.localeCompare(b.firstName))
        break
      case 'Unverified':
        sortedAlbums.sort((a, b) => (a._id > b._id ? -1 : 1))
        // use this if get create this ;(a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        break
      default:
        sortedAlbums = album
        break
    }

    // Update state with sorted albums
    setAlbums(sortedAlbums)
  }
  const handleOptionChangeExecutor = e => {
    const option = e.target.value
    if (selectedOption === option) {
        return
    }
    setSelectedOption(option)
    let sortedAlbums = [...album]
    switch (option) {
        case 'Verified':
            sortedAlbums = sortedAlbums.filter(user => user.status === "Verified")
            break
        case 'Unverified':
            sortedAlbums = sortedAlbums.filter(user => user.status === "Unverified")
            break
        default:
            sortedAlbums = album
            break
    }

    // Update state with sorted albums
    setAlbums(sortedAlbums)
}

  return (
    <Dropdown className='sort_by-dropdown'>
      <Dropdown.Toggle>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='16'
          viewBox='0 0 18 16'
          fill='none'
        >
          <path
            d='M7.45083 11.8958C7.41417 12.125 7.39583 12.3542 7.39583 12.5833C7.39583 12.8125 7.41417 13.0417 7.45083 13.2708H0.75C0.374167 13.2708 0.0625 12.9592 0.0625 12.5833C0.0625 12.2075 0.374167 11.8958 0.75 11.8958H7.45083ZM17.25 11.8958H16.0492C16.0858 12.125 16.1042 12.3542 16.1042 12.5833C16.1042 12.8125 16.0858 13.0417 16.0492 13.2708H17.25C17.6258 13.2708 17.9375 12.9592 17.9375 12.5833C17.9375 12.2075 17.6258 11.8958 17.25 11.8958ZM11.75 9.60417C10.1073 9.60417 8.77083 10.9407 8.77083 12.5833C8.77083 14.226 10.1073 15.5625 11.75 15.5625C13.3927 15.5625 14.7292 14.226 14.7292 12.5833C14.7292 10.9407 13.3927 9.60417 11.75 9.60417ZM6.25 0.4375C4.60733 0.4375 3.27083 1.774 3.27083 3.41667C3.27083 5.05933 4.60733 6.39583 6.25 6.39583C7.89267 6.39583 9.22917 5.05933 9.22917 3.41667C9.22917 1.774 7.89267 0.4375 6.25 0.4375ZM17.25 4.10417C17.6258 4.10417 17.9375 3.7925 17.9375 3.41667C17.9375 3.04083 17.6258 2.72917 17.25 2.72917H10.5492C10.5858 2.95833 10.6042 3.1875 10.6042 3.41667C10.6042 3.64583 10.5858 3.875 10.5492 4.10417H17.25ZM0.75 4.10417H1.95083C1.91417 3.875 1.89583 3.64583 1.89583 3.41667C1.89583 3.1875 1.91417 2.95833 1.95083 2.72917H0.75C0.374167 2.72917 0.0625 3.04083 0.0625 3.41667C0.0625 3.7925 0.374167 4.10417 0.75 4.10417Z'
            fill='#5C5C5C'
          />
        </svg>
        {selectedOption}
      </Dropdown.Toggle>
      {isExecutor && (
        <Dropdown.Menu>
          <Form.Check
            onChange={handleOptionChangeExecutor}
            name='radio'
            id='radio-2'
            type='radio'
            value='Verified'
            label='Verified'
          />
          <Form.Check
            onChange={handleOptionChangeExecutor}
            name='radio'
            id='radio-3'
            type='radio'
            value='Unverified'
            label='Unverified'
          />
        </Dropdown.Menu>
      )}
      {isViewer && (
        <Dropdown.Menu>
          <Form.Check
            onChange={handleOptionChangeViewer}
            name='radio'
            id='radio-2'
            type='radio'
            value='AtoZ'
            label='A to Z'
          />
          <Form.Check
            onChange={handleOptionChangeViewer}
            name='radio'
            id='radio-3'
            type='radio'
            value='DateAdded'
            label='Date Added'
          />
        </Dropdown.Menu>
      )}

      {!isExecutor && !isViewer && (
        <Dropdown.Menu>
          <Form.Check
            onChange={handleOptionChange}
            name='radio'
            id='radio-1'
            type='radio'
            value='All'
            label='All'
          />
          <Form.Check
            onChange={handleOptionChange}
            name='radio'
            id='radio-2'
            type='radio'
            value='Newest First'
            label='Newest First'
          />
          <Form.Check
            onChange={handleOptionChange}
            name='radio'
            id='radio-3'
            type='radio'
            value='Oldest First'
            label='Oldest First'
          />
          <Form.Check
            onChange={handleOptionChange}
            name='radio'
            id='radio-4'
            type='radio'
            value='Public After Passing'
            label='Public After Passing'
          />
          <Form.Check
            onChange={handleOptionChange}
            name='radio'
            id='radio-5'
            type='radio'
            value='Public Before Passing'
            label='Public Before Passing'
          />
          <Form.Check
            name='radio'
            onChange={handleOptionChange}
            id='radio-6'
            type='radio'
            value='Private'
            label='Private'
          />
        </Dropdown.Menu>
      )}
    </Dropdown>
  )
}

export default SortBy
