import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getOwnerAuth } from "../../store/slices/ownerSlice";
import { folderService } from "../../_services/folder";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import SkeletonLoader from "../SkeletonLoader";
import { mediaServices } from "../../_services";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../Loader";

const UpdateMemory = ({
  show,
  setShow,
  handleClose,
  handleShow,
  user,
  mediaDetails,
  loadingUpdateMdiaForm,
}) => {
  const location = useLocation();
  const { pathname, search, state } = location;
  const loggedInOwnerData = useSelector(getOwnerAuth);
  const [loading, setLoading] = useState(true);
  const [folderList, setFolderList] = useState([]);
  const [isLoadingFolderList, setIsLoadingFolderList] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState();
  const [submissionLoader, setSubmissionLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (loggedInOwnerData?._id) {
      fetchData(loggedInOwnerData._id);
    }
  }, [loggedInOwnerData]);

  const fetchData = async (ownerId) => {
    setIsLoadingFolderList(true);
    try {
      const response = await folderService.getAlbumList(ownerId);
      if (response?.data?.status === true) {
        setFolderList(response.data.data || []);
      }
    } catch (error) {
      console.log("Error fetching folder list:", error);
    } finally {
      setIsLoadingFolderList(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      title: mediaDetails.title || "",
      description: mediaDetails.description || "",
      folderName: mediaDetails.folderName || "",
      scheduleTime: mediaDetails.scheduleTime
        ? moment(mediaDetails.scheduleTime).format("YYYY-MM-DDTHH:mm")
        : "", 
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      description: Yup.string(),
      folderName: Yup.string().required("Folder is required"),
      scheduleTime: Yup.string(),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      console.log(values, "values");
      setSubmissionLoader(true);
      try {
        const updatedMediaDetails = {
          id: mediaDetails?._id,
          folderId: selectedFolder?.value?._id,
          folderPath: selectedFolder?.value?.path,
          folderName: selectedFolder?.value?.folderName,
          title: values.title,
          description: values.description,
          scheduleTime: values.scheduleTime,
        };
        const response = await mediaServices.updateMemory(
          updatedMediaDetails,
          user?.token
        );
        console.log(response.data.status, "response updated mems");
        if (response.data.status === true) {
          toast.success("Media is updated successfully", {});
          if (pathname == "/owner/memories") {
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
          navigate("/owner/memories");
          resetForm();
          setSubmissionLoader(false);
        }
      } catch (error) {
        console.error("Error updating memory:", error);
        toast.error("Failed to update memory");
        setSubmissionLoader(false);
      } finally {
        setSubmitting(false);
        setSubmissionLoader(false);
      }
    },
  });

  return (
    <>
      <div className="form-container">
        <Row>
          <Col xl={12} lg={12} xs={12} className="text-center">
            <h3 className="modal-heading">Update Memory</h3>
          </Col>
        </Row>
        {submissionLoader && <Loader />}{" "}
        <>
          {!isLoadingFolderList && !loadingUpdateMdiaForm ? (
            <Form onSubmit={formik.handleSubmit}>
              {folderList.length > 0 ? (
                <>
                  <Row className="mb-3">
                    <Col xl={8} lg={8} md={6} xs={12}>
                      <InputGroup className="gap-2 login-input-section justify-content-center ">
                        <Form.Control
                          className="w-100 min-height-60"
                          placeholder="Memory ..."
                          type="text"
                          name="title"
                          value={formik.values.title}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.title && formik.errors.title && (
                          <div className="text-danger">
                            {formik.errors.title}
                          </div>
                        )}
                      </InputGroup>
                    </Col>
                    <Col
                      xl={4}
                      lg={4}
                      md={6}
                      xs={12}
                      className="select-folder-test"
                    >
                      <Select
                        value={formik.values.folderName}
                        onChange={(selectedOption) => {
                          formik.setFieldValue(
                            "folderName",
                            selectedOption?.value?.folderName
                          );
                          setSelectedFolder(selectedOption);
                        }}
                        options={folderList.map((folder) => ({
                          label: folder.folderName,
                          value: folder,
                        }))}
                        placeholder={formik.values.folderName}
                        className="invite_access-dropdown"
                        isSearchable={false}
                      />
                      {formik.touched.folderName &&
                        formik.errors.folderName && (
                          <div className="text-danger">
                            {formik.errors.folderName}
                          </div>
                        )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xl={12} lg={12} md={12} xs={12}>
                      <InputGroup className=" login-input-section justify-content-center">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          className="w-100 min-height-60"
                          placeholder="Use this space to capture the essence of the memory.Share any details that make this moment special or meaningful to you."
                          name="description"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.description &&
                          formik.errors.description && (
                            <div className="text-danger">
                              {formik.errors.description}
                            </div>
                          )}
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6} className="py-3">
                      <Form.Group>
                        <Form.Label>Scheduled Time:</Form.Label>
                        <Form.Control
                          type="datetime-local"
                          value={formik.values.scheduleTime}
                          onChange={(e) =>
                            formik.setFieldValue("scheduleTime", e.target.value)
                          }
                          min={
                            formik.values.scheduleTime
                              ? moment(formik.values.scheduleTime).format(
                                  "YYYY-MM-DDTHH:mm"
                                )
                              : moment().format("YYYY-MM-DDTHH:mm")
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12} className="py-3">
                      <div className="video-settings-upload w-100 text-center">
                        <img
                          alt="thumbnail"
                          className="img-fluid"
                          src={
                            mediaDetails.fileType === "video"
                              ? mediaDetails.thumnailUrl
                              : mediaDetails.fileType === "image"
                              ? mediaDetails.fileUrl
                              : mediaDetails.fileType === "document"
                              ? "/doc-placeholder.png"
                              : ""
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="border-0 text-center pt-5">
                    {submissionLoader ? (
                      <Button
                        variant="primary"
                        className="create-btn mb-3"
                        type="submit"
                      >
                        Updating
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        className="create-btn mb-3"
                        type="submit"
                      >
                        Update Memory
                      </Button>
                    )}
                    <span
                      className="w-100 text-muted d-flex justify-content-center align-items-center"
                      onClick={handleClose}
                    >
                      <b>Cancel</b>
                    </span>
                  </div>
                </>
              ) : (
                <div className="text-center">
                  <p className="my-2">Please add a folder to proceed</p>
                  <Button
                    className="mt-3"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Go to Album
                  </Button>
                </div>
              )}
            </Form>
          ) : (
            <SkeletonLoader height={100} />
          )}
        </>
      </div>
    </>
  );
};

export default UpdateMemory;
