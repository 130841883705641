import { Outlet, Navigate } from 'react-router-dom'
import useAuthToken from '../_hooks/useAuthToken'
import { useAuth } from '../_hooks/useAuth'
import AuthLayout from '../components/Layouts/AuthLayout'
import Spinner from 'react-bootstrap/Spinner'
import React from 'react'
import { EXECUTOR, OWNER, VIEWER } from '../_helpers'

const ProtectedRoutes = () => {
  const token = useAuthToken()
  const user = useAuth('user')
  const role = useAuth('role')
  const isAuthenticated = token
  if (isAuthenticated && role === OWNER) {
    // if (user.isVerified == false) {
    //   return <Navigate to='/sign-as' replace />
    // } else if (user.isProfileCompleted === false) {
    //   return <Navigate to='/owner/plans' />
    // }
  }

  return isAuthenticated ? (
    <AuthLayout>
      <React.Suspense
        fallback={
          <div className='loading-loader-section'>
            <Spinner animation='border' variant='primary' />
          </div>
        }
      >
        <Outlet />
      </React.Suspense>
    </AuthLayout>
  ) : (
    <Navigate to='/sign-as' replace />
  )
}

export default ProtectedRoutes
