import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { useDispatch } from "react-redux";
import { useAuth } from "../../../../_hooks/useAuth";
import { folderService } from "../../../../_services";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import {
  ownerAvatar,
  ownerLoginAuth,
} from "../../../../store/slices/ownerSlice";
import Loader from "../../../../components/Loader";

const UploadProfilePic = () => {
  const user = useAuth("user");
  const role = useAuth("role");
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [base64String, setBase64String] = useState("");
  const [fileError, setFileError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const imageExtensions = ["jpg", "jpeg", "png", "webp", "svg"];

  const handleUploadClick = () => {
    if (user?.isProfileCompleted == true) {
      setShowModal(true);
    } else {
      toast.error(
        "Upgrade Required: Unlock this feature by completing your payment. Questions? Contact support."
      );
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();
      if (!imageExtensions.includes(extension)) {
        toast.error(
          "Only images with extension jpg,jpeg,png,webp,svg are supported"
        );
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setSelectedFile(file);
        setBase64String(reader.result);
        setFileError("");
      };
    }
  };

  const handleUploadViewer = () => {
    if (!selectedFile) {
      setFileError("Please select profile image");
      return;
    }
    setLoading(true);
    setIsActiveLoading(true);
    const data = {
      role: role,
      fileName: selectedFile.name,
      file: base64String,
    };
    folderService
      .uploadProfileViewer(user._id, data)
      .then((response) => {
        toast.success("Profile Picture is updated successfully");
        setIsActiveLoading(false);
        dispatch(
          ownerAvatar({
            avatar: response.data.data.avatar,
            fileName: response.data.data.fileName,
          })
        );

        setShowModal(false);
        setSelectedFile("");
        setBase64String("");
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to upload profile photo.");
        setLoading(false);
        setIsActiveLoading(false);
      });
  };

  return (
    <>
      {isActiveLoading && <Loader />}
      <div className="py-4 border-bottom d-flex account-user justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-3">
          <div className="user-icon">
            {user.avatar ? (
              <img
                src={user.avatar}
                alt="avatar"
                className="img-fluid user-icon"
              />
            ) : (
              <>{user?.userName?.charAt(0).toUpperCase()}</>
            )}
          </div>
          <div className="user-info">
            <h3 className="user-label">Upload your profile photo</h3>
            <p className="user-txt">
              This helps people recognize you in Forevermems.
            </p>
          </div>
        </div>
        <>
          {loading ? (
            <Spinner />
          ) : (
            <Button className="outline-btn" onClick={handleUploadClick}>
              Upload photo
            </Button>
          )}

          <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Upload Profile Photo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formFile" className="mb-3">
                  {fileError ? (
                    <Form.Text className="text-danger">{fileError}</Form.Text>
                  ) : (
                    <Form.Label>Choose a photo to upload:</Form.Label>
                  )}

                  <div>
                    <div className="img-container-profile">
                      <img
                        src={
                          base64String ||
                          user.avatar ||
                          "/assets/placeholder-img.png"
                        }
                        alt=""
                      />
                      <Form.Control
                        type="file"
                        onChange={handleChange}
                        name="file"
                      />
                    </div>
                  </div>
                </Form.Group>

                {loading ? (
                  <Spinner />
                ) : (
                  <Button variant="primary" onClick={handleUploadViewer}>
                    {user.avatar ? "Change" : "Upload"}
                  </Button>
                )}
              </Form>
            </Modal.Body>
          </Modal>
        </>
      </div>
    </>
  );
};

export default UploadProfilePic;
