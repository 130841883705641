import { useSelector } from 'react-redux';
import { getOwnerAuth } from "../store/slices/ownerSlice";
import { getExecutorAuth } from "../store/slices/executerSlice";
import { getViewerAuth } from "../store/slices/viewerSlice";
import { EXECUTOR, VIEWER, OWNER } from "../_helpers"

export const useAuth = (key) => {
  const ownerAuth = useSelector(getOwnerAuth);
  const viewerAuth = useSelector(getViewerAuth);
  const executorAuth = useSelector(getExecutorAuth);

  if (key === 'role') {
    if (ownerAuth.token) { return OWNER; }
    if (viewerAuth.token) { return VIEWER; }
    if (executorAuth.token) { return EXECUTOR; }
    return '';
  }

  if (key === 'user') {
    if (ownerAuth.token) { return ownerAuth; }
    if (viewerAuth.token) { return viewerAuth; }
    if (executorAuth.token) { return executorAuth; }
    return '';
  }

  return null;
};
