import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  executorFolders: [],
};
const executorFolderSlice = createSlice({
  name: "executorFolder",
  initialState: initialState,
  reducers: {
    setExecutorFolders: (state, action) => {
      state.executorFolders = action.payload;
    },
    setExecutorFoldersLogout: (state, action) => {
      state.executorFolders = "";
    },
  },
});
export default executorFolderSlice.reducer;
export const { setExecutorFolders,setExecutorFoldersLogout } = executorFolderSlice.actions;
export const getExecutorFolder = (state) => state.executorFolder;
