import React from "react";
import { useNavigate } from "react-router-dom";
const BackButton = ({goToPreviousStep, currentStep,StepWizard}) => {
  const navigate = useNavigate();
  return (
    <div className="back-button" onClick={()=>StepWizard?goToPreviousStep():navigate(-1)} style={{cursor:"pointer"}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
      </svg>
    </div>
  );
};

export default BackButton;
