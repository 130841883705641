import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  role: "",
 
};
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    loggedInUserRole: (state, action) => {
   console.log(action, "action");
      state.role = action?.payload?.role;
      state.token = action?.payload?.token
    },
    removeloggedInUserRole : (state, action) => {
      state.role = "";
      state.token = ""
    }
  },
});

export default dashboardSlice.reducer;
export const { loggedInUserRole,removeloggedInUserRole } = dashboardSlice.actions;
export const getLoggedInUserDashBoardData = (state) => state.dashboard;
